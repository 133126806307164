import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import {
  Add as AddIcon,
  Clear as ClearIcon,
  Search as SearchIcon
} from "@material-ui/icons";

import { ContactType } from "@cargoticcom/model";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",

    "& > div:not(:first-child)": {
      marginLeft: spacing(2)
    }
  },
  wide: {
    flex: 1
  },
  field: {
    padding: "14px 14px"
  },
  button: {
    // height: "100%"
  },
  controls: {
    "& > *:not(:last-child)": {
      marginRight: spacing(1)
    }
  }
}));

const ContactEmployeeOverviewSwitchboard = ({
  search,
  onSearchChange,
  onAddEmployeeClick,
  onAddDriverClick,
  contactType,
  isSubcontractor
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSearchTextFieldChange = ({ target: { value } }) => {
    if (onSearchChange) {
      onSearchChange(value);
    }
  };

  const handleSearchClear = () => {
    if (onSearchChange) {
      onSearchChange("");
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wide}>
        <TextField
          inputProps={{ className: classes.field }}
          variant="outlined"
          value={search}
          onChange={handleSearchTextFieldChange}
          label={t("webapp:contact.title.search-employee")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  disabled={search === ""}
                  onClick={handleSearchClear}
                >
                  {
                    search === ""
                      ? (
                        <SearchIcon color="action" />
                      ) : (
                        <ClearIcon />
                      )
                  }
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
        />
      </div>
      <div className={classes.controls}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="medium"
          startIcon={(
            <AddIcon />
          )}
          onClick={onAddEmployeeClick}
        >
          {t("webapp:contact.title.add-employee")}
        </Button>

        {isSubcontractor ? (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="medium"
            startIcon={(
              <AddIcon />
            )}
            onClick={onAddDriverClick}
          >
            {t("webapp:contact.title.add-user")}
          </Button>
        ) : <></>}
      </div>
    </div>
  );
};

export default ContactEmployeeOverviewSwitchboard;
