import React, {useState} from "react";

import {makeStyles,} from "@material-ui/core";
import {useTranslation} from "react-i18next";

import {ContactType} from "@cargoticcom/model";

import {addDays} from "date-fns";
import config from "../../../config";

import DataTable from "../../../datatable";
import ExportDialog from "../IncomingOrders/ExportDialog";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import InvoiceDialog from "../IncomingOrders/InvoiceDialog";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../incomingOrder/component/SendOrderDialog";

import PayDialog from "../../../incomingOrder/component/PayDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import PlaceFilterDropdown from "../../../../cargotic-webapp-filter/component/PlaceFilterDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import useAuth from "../../hook/useAuth";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";
import BoxFilterDropdown from "../../../../cargotic-webapp-filter/component/BoxFilterDropdown";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger,
    },
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)",
  },
}));

const FirstMileIncomingOrders = ({
  data,
  dataCount,
  selectedColumns,
  incomingOrders,
  setIncomingOrders,
  search,
  loading,
  ordering,
  direction,
  page,
  rowsPerPage,
  checkedAll,
  canCreateIncomingOrder,
  canUpdateIncomingOrder,
  canDeleteIncomingOrder,
  canCreateInvoice,
  canCreateOrderPdf,
  canReadCompanyIncomingOrder,
  canReadInvoice,
  additionalFilter: {
    type: additionalFilterType,
    lastWaypointDateFrom: additionalFilterLastDateFrom,
  },
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAll,
  handleSelect,
  handleDeleteRequest,
  filter,
  availableBankAccounts,
  handleDeselect,
  handleSelectLoadingsDateRange,
  handleSelectUnloadingsDateRange,
  handleSelectCreatedAtDateRange,
  handleSelectCreators,
  loadAvailableUsers,
  handleSelectCustomer,
  handleSelectCustomerPrice,
  loadAvailableContacts,
  handleFilterSettingsOpen,
  defaultFilters,
  handleSelectCargoTemplate,
  handleSelectIsDraft,
  handleLoadAvailablePlaces,
  handleSelectPlaces,
  handleChangeSelectedColumns,
  handleSelectAllOnPage,
  handleSelectIncomingOrderState,
  loadAvailableStates,
  handleLoadAvailableBoxes,
  handleSelectBoxes
}) => {
  const { history } = useRouter();
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, hasPermission } = useAuth();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
    useState(false);

    const [selectValue, setSelectValue] = useState('rinkai');
  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));


  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const incomingOrdersHeader = [
    {
      name: "indexNumber",
      label: "incomingOrders.number",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "customerContact",
      label: "incomingOrders.customer",
      isOrderable: false,
    },
    {
      name: "pickupAtStart",
      label: "incomingOrders.date",
      isOrderable: true,
      width: "10%",
    },
    {
      name: "route",
      label: "incomingOrders.route",
      isOrderable: false,
    },
    {
      name: "waypointContact",
      label: "incomingOrders.waypointContact",
      isOrderable: false,
    },
    {
      name: "state",
      label: "incomingOrders.state",
      isOrderable: false
    },
    {
      name: "externalReference",
      label: "incomingOrders.externalReference",
      isOrderable: false
    },
    {
      name: "outcomingOrder",
      label: "incomingOrders.outcomingOrderNumber",
      isOrderable: true,
    },
    {
      name: "customerPrice",
      label: "incomingOrders.customerPrice",
      isOrderable: false,
    },
    {
      name: "tags",
      label: "incomingOrders.tags",
      isOrderable: false
    },
    {
      name: "sumCargoValue",
      label: "incomingOrders.sumCargoValue",
      width: "15%",
      isOrderable: false,
    }
  ];

  const tableActions = [];

  const isAnyFilterActive = () => {
    const {
      customerPrice,
      customers,
      creators,
      cargo,
      state,
      places,
      unloadingDate,
      loadingDate,
      createdAt,
      isDraft,
    } = filter;

    return [
      customerPrice,
      customers,
      creators,
      cargo,
      state,
      places,
      unloadingDate,
      loadingDate,
      createdAt,
      isDraft,
    ].some((item) => item !== undefined);
  };

  return (
    <Page
      title={}
      actions={}
      tertiaryActions={}
    >
      <FilterContainer
        searchField={
          <SearchTextfield
            handleSearch={handleSearch}
            value={search}
            fullWidth
            placeholder={t("incomingOrders.searchTooltip")}
          />
        }
        showClearButton={isAnyFilterActive}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        defaultFilters={defaultFilters}
        loading={loading}
        filters={[
          <ContactFilterDropdown
            id="customers"
            key="customers"
            onChange={(value) => handleSelectCustomer(value.length === 0 ? undefined : value)}
            selectAll={(all) => handleSelectCustomer(all)}
            value={filter.customers ?? []}
            search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
            placeholderTitle={t("contacts.customer")}
            onClear={() => handleSelectCustomer(undefined)}
            getTitle={(item) => item.name}
          />,
          <FilterDateRangeDropdown
            id="loadingDate"
            key="loadingDate"
            onChange={(value) => handleSelectLoadingsDateRange(value)}
            value={
              filter.loadingDate
                ? [filter.loadingDate.from, filter.loadingDate.to]
                : []
            }
            onClear={() => handleSelectLoadingsDateRange(undefined)}
            placeholderTitle={t("incomingOrders.loadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="unloadingDate"
            key="unloadingDate"
            onChange={(value) => handleSelectUnloadingsDateRange(value)}
            value={
              filter.unloadingDate
                ? [filter.unloadingDate.from, filter.unloadingDate.to]
                : []
            }
            onClear={() => handleSelectUnloadingsDateRange(undefined)}
            placeholderTitle={t("incomingOrders.unloadingsDateRange")}
          />,
          <FilterDateRangeDropdown
            id="createdAt"
            key="createdAt"
            onChange={(value) => handleSelectCreatedAtDateRange(value)}
            value={
              filter.createdAt
                ? [filter.createdAt.from, filter.createdAt.to]
                : []
            }
            onClear={() => handleSelectCreatedAtDateRange(undefined)}
            placeholderTitle={t("incomingOrders.creationDate")}
          />,
          <FilterNumberRange
            id="customerPrice"
            key="customerPrice"
            onChange={(value) => handleSelectCustomerPrice(value)}
            value={filter.customerPrice}
            placeholderTitle={t("incomingOrders.customerPrice")}
            onClear={() => handleSelectCustomerPrice(undefined)}
          />,
          <UserFilterDropdown
            id="creators"
            key="creators"
            onChange={(value) =>
              handleSelectCreators(value.length === 0 ? undefined : value)
            }
            selectAll={(all) => handleSelectCreators(all)}
            value={filter.creators ?? []}
            placeholderTitle={t("incomingOrders.creator")}
            search={(text) => loadAvailableUsers(text)}
            onClear={() => handleSelectCreators(undefined)}
            getTitle={(item) => item.name}
            disabled={!canReadCompanyIncomingOrder}
          />,
          <FilterStaticDropdown
            id="cargo"
            key="cargo"
            placeholderTitle={t("incomingOrders.cargo")}
            value={filter.cargo ?? []}
            onChange={(arr) =>
              handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
            }
            selectAll={() =>
              handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
            }
            onClear={() => handleSelectCargoTemplate(undefined)}
            source={AvailableCargoItemTemplates.map(({ value, label }) => ({ title: t(label), value }))}
          />,
          <PlaceFilterDropdown
            id="places"
            key="places"
            placeholderTitle={t("shipments.places")}
            selectAll={(all) => handleSelectPlaces(all)}
            search={(text) => handleLoadAvailablePlaces(text)}
            value={filter.places ?? []}
            onChange={(value) => handleSelectPlaces(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectPlaces(undefined)}
          />,
          <BoxFilterDropdown
            id="boxes"
            key="boxes"
            placeholderTitle={t("shipments.box.title")}
            selectAll={(all) => handleSelectBoxes(all)}
            search={(text) => handleLoadAvailableBoxes(text)}
            value={filter.boxes ?? []}
            onChange={(value) => handleSelectBoxes(value.length === 0 ? undefined : value)}
            onClear={() => handleSelectBoxes(undefined)}
          />,
          <StateFilterDropdown
            id="state"
            key="state"
            placeholderTitle={t("board.state.title")}
            value={filter.state ?? []}
            onChange={(arr) =>
              handleSelectIncomingOrderState(arr.length === 0 ? undefined : arr)
            }
            search={(text) => loadAvailableStates(text)}
            selectAll={(all) =>
              handleSelectIncomingOrderState(all)
            }
            onClear={() => handleSelectIncomingOrderState(undefined)}
            getTitle={(item) => item.name}
          />,
        ]}
        onClear={handleDeselect}
      />
      <DataTable
        headers={incomingOrdersHeader}
        actions={tableActions}
        selectedColumns={selectedColumns}
        data={data}
        dataCount={dataCount}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        onSelectAllOnPage={handleSelectAllOnPage}
        enableDetail={false}
        enableDetails={false}
        enableMultipleDetails={false}
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
        onChangeSelectedColumns={handleChangeSelectedColumns}
      />
    </Page>
  );
};

export default FirstMileIncomingOrders;
