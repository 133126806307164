import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "@cargoticcom/time";

import {
  makeStyles,
  Typography,
  MenuList,
  Grow,
  Menu,
  TextField,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Popper,
  InputAdornment,
  ClickAwayListener,
  FormControl,
  IconButton,
  Select,
  InputLabel,
  Button,
  Scrollbar,
  Input,
  MenuItem,
  Checkbox,
  Divider,
  Paper
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  },
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#D6D6D6"
    }
  },
  endIcon: {
    marginLeft: 0
  },
  text: {
    padding: "4px 6px"
  },
  field: {
    padding: spacing(1)
  },
  adorned: {
    paddingRight: 0
  },
  gutters: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },
  itemTitle: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  }
}));

const FilterDynamicDropdown = ({
  source,
  placeholderTitle,
  onChange,
  value,
  suggest,
  getTitle,
  selectAll,
  getValue,
  disabled,
  onClear
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectAllChosen, setSelectAllChosen] = useState(undefined);

  useEffect(() => {
    suggest();
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const stopPropagation = e => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = (item, checked) => {
    if (checked) {
      if (!value.includes(item)) {
        onChange([...value, item]);
      }
    } else {
      onChange(value.filter(c => c !== item));
    }
  };

  const handleClearAndClose = () => {
    onClear();
    handleToggle();
  };

  const filteredSource = source.filter((item) => getTitle(item).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
  const isSelectedAll = JSON.stringify(value) === JSON.stringify(filteredSource.map(item => getValue(item)));

  return (
    <div>
      <Button
        ref={anchorRef}
        classes={{ root: value.length > 0 ? classes.buttonRootSelected : classes.buttonRoot, endIcon: classes.endIcon, text: classes.text }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        endIcon={
          <>
            <IconButton onClick={() => null} size="small">
              <ArrowDropDownIcon className={classes.icon} />
            </IconButton>
            {value.length > 0 ?
              (<IconButton onClick={handleClearAndClose} size="small">
                <CloseIcon className={classes.icon} />
              </IconButton>) : null}
          </>
        }
      >
        {placeholderTitle} {value.length > 0 ? `(${value.length})` : null}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <TextField
                    id="search"
                    fullWidth
                    value={searchText}
                    className={classes.field}
                    label={t("search")}
                    onChange={({ target: { value } }) => setSearchText(value)}
                    onKeyDown={stopPropagation}
                    variant="outlined"
                    InputProps={{
                      classes: { adornedEnd: classes.adorned },
                      endAdornment:
                        searchText === "" ? (<InputAdornment position="end"><IconButton><SearchIcon /></IconButton></InputAdornment>) : <InputAdornment position="end">
                          <IconButton onClick={() => setSearchText("")}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  <ListItem button key="selectAll" onClick={() => selectAll(filteredSource)}>
                    <ListItemText primary={t("selectAll")} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        color="primary"
                        checked={selectAllChosen || isSelectedAll}
                        edge="end"
                        onChange={({ target: { checked } }) => {
                          if (checked) {
                            selectAll(filteredSource);
                          } else if (checked === false) {
                            onClear();
                          }
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  {filteredSource.map((item) =>
                      (
                        <ListItem button key={getValue(item)} onClick={() => handleClick(getValue(item), !value.includes(getValue(item)))}>
                          <ListItemText primary={getTitle(item)} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              color="primary"
                              checked={value.includes(getValue(item))}
                              edge="end"
                              onChange={({ target: { checked } }) => handleClick(getValue(item), checked)
                              }
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FilterDynamicDropdown;
