import React from "react";

import { firstElement } from "@cargoticcom/common";
import { useTranslation } from "react-i18next";
import {
  MenuItem,
  Select,
  Typography,
  TextField,
  makeStyles,
  Button
} from "@material-ui/core";

import { DesktopDateRangePicker, DateRange } from "@material-ui/pickers";

import { formatDate } from "../../../cargotic-webapp/utility/common";

const useStyles = makeStyles(() => ({
  root: {
    "&::before": {
      borderBottom: "0px"
    },

    "&:hover::before": {
      borderBottom: "0px !important"
    }
  },
  customRange:{
    display: "flex",
    justifyContent: "flex-start"
  },
  rootInput: {
    borderRadius: "40px",
    marginLeft: "10px"
  }
}));

const StatisticCardSelect = ({
  value,
  items,
  range,
  onChange,
  onRangeChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasSingleItem = items.length === 1;
  const { label: firstLabel } = firstElement(items);

  if (hasSingleItem) {
    return (
      <Typography>
        {firstLabel}
      </Typography>
    );
  }

  return (
    <div className={classes.customRange}>
    <Select
      className={classes.root}
      value={value}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event);
      }}
      onClose={(event) => {
        event.stopPropagation();
      }}
    >
      {items.map(({ label, value: itemValue }) => (
        <MenuItem key={itemValue} value={itemValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
    {
      range !== undefined && range.length !== 0
        ? (
          <DesktopDateRangePicker
            value={range}
            disableMaskedInput
            calendars={1}
            onChange={onRangeChange}
            renderInput={(startProps, endProps) => {
              return (
                <div className={classes.rootInput} >
                  <Button
                    {...startProps.inputProps}
                    variant="outlined"
                    size="small"
                    ref={startProps.inputRef}
                  >
                    {`${formatDate(range[0])} ${range[1] ? ' - ' + formatDate(range[1]) : ''}`}
                  </Button>
                </div>
              );
            }}
          />
        )
        : null
    }
    </div>
  );
};

export default StatisticCardSelect;
