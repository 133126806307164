import React from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "@cargoticcom/common";
import {
  Chip,
  Fade,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  Add as AddIcon,
  Edit as EditIcon
} from "@material-ui/icons";

import {
  ChipTagGroup,
  TextTable,
  TextTableRow,
  UserAvatar
} from "../../../cargotic-webapp-component";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),

    "& > :not(:first-child)": {
      marginTop: spacing(3)
    }
  },
  creator: {
    display: "flex",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  }
}));

const ContactProfileDetailContentBody = ({
  ico,
  dic,
  email,
  website,
  phoneNumber,
  mailingAddress,
  billingAddress,
  paymentDueDays,
  isBilledOnline,
  billingContact,
  insuranceExpiresAt,
  createdAt,
  createdBy,
  tags,
  isExpanded,
  onTagEditClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const generalInformation = [
    [t("webapp:common.title.ico"), ico],
    [t("webapp:common.title.dic"), dic],
    [t("webapp:common.title.email"), email],
    [t("webapp:common.title.phone"), phoneNumber],
    [t("webapp:common.title.billing-address"), billingAddress],
    [t("webapp:common.title.createdAt"), formatDate(createdAt, true)]
  ];

  const additionalInformation = [
    [t("webapp:common.title.website"), website],
    [
      t("webapp:contact.title.billingContact"),
      billingContact ? billingContact : undefined
    ],
    [t("webapp:common.title.payment-due-days"), paymentDueDays],
    [
      t("webapp:common.title.mailing-address"),
      mailingAddress ? mailingAddress : undefined
    ],
    [
      t("webapp:contact.title.isBilledOnline"),
      isBilledOnline ? t("yes") : t("no")
    ],
    [
      t("webapp:common.title.insurance-expires-at"),
      insuranceExpiresAt ? formatDate(insuranceExpiresAt, true) : undefined
    ]
  ];

  return (
    <div className={classes.root}>
      <div>
        <Typography gutterBottom>
          <b>
            {t("webapp:common.title.general-information")}
          </b>
        </Typography>
        <TextTable>
          {generalInformation.map(([label, value]) => (
            <TextTableRow key={label} label={label} value={value ?? "-"} />
          ))}
        </TextTable>
      </div>
      <Fade
        in={isExpanded}
        timeout={{ enter: 1000, appear: 1000, exit: 0 }}
        unmountOnExit
      >
        <div>
          <Typography gutterBottom>
            <b>
              {t("webapp:common.title.additional-information")}
            </b>
          </Typography>
          <TextTable>
            {additionalInformation.map(([label, value]) => (
              <TextTableRow key={label} label={label} value={value ?? "-"} />
            ))}
          </TextTable>
        </div>
      </Fade>
      <div>
        <Typography gutterBottom>
          <b>
            {t("webapp:common.title.created-by")}
          </b>
        </Typography>
        <div className={classes.creator}>
          <UserAvatar size="small" user={createdBy} />
          <Typography>
            {createdBy.name}
          </Typography>
        </div>
      </div>
      <div>
        <Typography gutterBottom>
          <b>
            {t("webapp:common.title.tags")}
          </b>
        </Typography>
        <ChipTagGroup tags={tags} onEditButtonClick={onTagEditClick} />
      </div>
    </div>
  );
};

export default ContactProfileDetailContentBody;
