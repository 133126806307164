import { endOfMonth, startOfMonth, } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { Link, makeStyles, Typography, } from "@material-ui/core";

import { ContactType } from "@cargoticcom/model";

import ShipmentOverview from "@cargotic/webapp-shipment-overview";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import useAuth from "../../hook/useAuth";
import { addUrlParam, getTableUrlParams } from "../../../utility/window";

import { loadFilters, storeFilters } from "../../../storage";

import { createMatchQueryWarehouseOrders, createStateMatchQuery, exportOrdersRinkai, postExportWarehouseOrdersToCSV } from "../../../resource";


import { HrefInNewTabIcon, useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import WarehouseOrders from "./WarehouseOrders";
import { WarehouseOrdersModeEnum } from "../../enums/enums";
import WarehouseOrderState from "./components/WarehouseOrderState";
import WarehouseOrdersPackagesFulfilled from "./components/WarehouseOrdersPackagesFulfilled";
import WarehouseOrdersRoute from "./components/WarehouseOrdersRoute";
import WarehouseOrdersDateTime from "./components/WarehouseOrdersDateTime";
import { uniq } from "lodash";
import { formatDateTime } from "../../../utility/common";

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)",
  },
  inlineContent: {
    display: "inline-flex",
  },
  warning: {
    fill: "silver",
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main,
  },
  success: {
    fill: "#009688",
  },
}));

const WarehouseOrdersContainer = ({
  mode,
  warehouseId
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();

  const [warehouseOrders, setWarehouseOrders] = useState([]);

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { searchText: initSearchText, filter: initFilter } =
    getTableUrlParams(routerSearch);

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);

  const [rinkaiDepo, setRinkaiDepo] = useState(null);
  const handleRinkaiDepoInputChange = (e) => {
    setRinkaiDepoId(e.target.value);
  };

  const [
    isWarehouseOrdersExportDialogOpen,
    setIsWarehouseOrdersExportDialogOpen,
  ] = useState(false);
  const [
    isWarehouseOrdersExportProcessing,
    setIsWarehouseOrdersExportProcessing,
  ] = useState(false);
  const [isWarehouseOrdersExportDone, setIsWarehouseOrdersExportDone] =
    useState(false);
  const [warehouseOrdersExportLink, setWarehouseOrdersExportLink] =
    useState(null);

  const closeWarehouseOrdersExportDialog = () => {
    setIsWarehouseOrdersExportDialogOpen(false);

    if (isWarehouseOrdersExportDone) {
      setIsWarehouseOrdersExportDone(false);
      setWarehouseOrdersExportLink(null);
    }
  };

  const getSelectedIncomingOrderIds = () => {
    let selectedWarehouseOrderIds = selectedWarehouseOrders.map(({ id }) => id)
    let selectedIncomingOrders = warehouseOrders.filter(wo => selectedWarehouseOrderIds.includes(wo.warehouseOrder.id))
    return selectedIncomingOrders.map(({ incomingOrderId }) => incomingOrderId);
  }

  const exportWarehouseOrders = async () => {
    setIsWarehouseOrdersExportProcessing(true);
    try {
      const data = await postExportWarehouseOrdersToCSV(
        selectedWarehouseOrders.map(({ id }) => id)
      );
      const element = document.createElement('a');
      element.setAttribute('href', `data:application/octet-stream;base64,${data}`);
      element.setAttribute('download', 'warehouse-orders.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setWarehouseOrdersExportLink(data);
      setIsWarehouseOrdersExportDone(true);
    } catch (error) {
      console.log(error);


      enqueueSnackbar(t("orders.export.error"), {
        variant: "error",
      });
    } finally {
      setIsWarehouseOrdersExportProcessing(false);
    }
  };

  const exportWarehouseOrdersToRinkai = async (ids) => {
    setIsWarehouseOrdersExportProcessing(true);
    try {
      const { id, alias } = rinkaiDepo;
      const response = await exportOrdersRinkai(getSelectedIncomingOrderIds(), id, alias);
      if (response.status === 'error') {
        response.errors.map(({ t: translate, indexNumber }) => {
          enqueueSnackbar(t(translate, { indexNumber }), {
            variant: "error",
          });
        });
      } else if (response.result === 'webapp:rinkai:dataError') {
        enqueueSnackbar(t(response.result), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t(response.result), {
          variant: "success"
        });
        setIsWarehouseOrdersExportDialogOpen(false);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsWarehouseOrdersExportProcessing(false);
    }
  };

  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const handleWarehouseOrdersExportRequest = () => {
    setIsWarehouseOrdersExportDialogOpen(true);
  };

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("warehouse-orders", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const defaultFilterValues = [
    "customers",
    "loadingDate",
    "unloadingDate",
    "creators",
    "customerPrice",
  ];

  const availableFilters = [
    {
      label: t("contacts.customer"),
      value: "customers",
    },
    {
      label: t("incomingOrders.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("incomingOrders.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.creationDate"),
      value: "createdAt",
    },
    {
      label: t("incomingOrders.customerPrice"),
      value: "customerPrice",
    },
    {
      label: t("incomingOrders.creator"),
      value: "creators",
    },
    {
      label: t("incomingOrders.warehouseReceiver"),
      value: "receivedBy",
    },
    {
      label: t("incomingOrders.warehouseReceivedDate"),
      value: "receivedDate",
    },
    {
      label: t("incomingOrders.warehouseDeliveryDate"),
      value: "deliveryDate",
    },
    {
      label: t("incomingOrders.cargo"),
      value: "cargo",
    },
  ];

  if (mode === WarehouseOrdersModeEnum.INCOMING_ORDERS) {
    availableFilters.splice(
      4,
      0,
      {
        label: t("incomingOrders.warehouseState"),
        value: "warehouseState",
      }
    )
    availableFilters.push(
      {
        label: t("incomingOrders.warehouseDispatcher"),
        value: "dispatchers",
      },
      {
        label: t("incomingOrders.dispatchedDate"),
        value: "dispatchedDate",
      },
    )
  }

  const getUniqueReceivedDates = (item) => {
    return uniq(item.warehouseOrder.cargoItemPackages.map(cip => cip.receivedDate).filter(rd => !!rd)).map(rd => formatDateTime(new Date(rd)))
  }

  const getUniqueDispatchedDates = (item) => {
    return uniq(item.warehouseOrder.cargoItemPackages.map(cip => cip.dispatchedDate).filter(rd => !!rd)).map(rd => formatDateTime(new Date(rd)))
  }

  let reloadDelay;
  let storeSearchDelay;

  const reloadWarehouseOrders = useCallback(
    async (offset, limit, ordering) => {
      const filter = transformFilter(additionalFilter);
      try {
        const warehouseStates = (await createStateMatchQuery({
          resources: ["warehouse_order"],
          offset: 0,
          limit: 25,
          orderBy: []
        })).matches;
        const dispatchedStateId = warehouseStates.find(state => state.name === "Vydáno").stateId
        const receivedStateIds = warehouseStates.filter(state => state.name !== "Vydáno").map(state => state.stateId)
        let warehouseOrdersResponse;
        switch (mode) {
          case WarehouseOrdersModeEnum.INCOMING_ORDERS: {
            warehouseOrdersResponse = await createMatchQueryWarehouseOrders({
              match: {
                search,
                warehouseId,
                warehouseState: receivedStateIds,
                ...filter,
              },
              orderBy: ordering,
              offset,
              limit
            })
          } break;
          case WarehouseOrdersModeEnum.ORDERS_HISTORY: {
            warehouseOrdersResponse = await createMatchQueryWarehouseOrders({
              match: {
                search,
                warehouseId,
                warehouseState: dispatchedStateId,
                ...filter,
              },
              orderBy: ordering,
              offset,
              limit
            })
          }
        }

        setWarehouseOrders(warehouseOrdersResponse.matches)

        if (warehouseOrdersResponse.total === 0 && offset !== 0) {
          handleChangePage(undefined, 0);
        }

        const updatedWarehouseOrders = warehouseOrdersResponse.matches.map(
          (item) => {
            item.isDisabled = item.isDisabled === 1;
            item.selected = false;

            item.tableCells = [
              {
                render: !item.isDraft ? (
                  <Typography variant="body2"> #
                    {item.indexNumber}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    <i>{t("incomingOrders.draft")}</i>
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    <Link
                      component={RouterLink}
                      to={`/contacts/${item.customerContact.id}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {item.customerContact.name ||
                        item.customerContact.email ||
                        item.customerContact.phoneNumber}
                        <HrefInNewTabIcon />
                    </Link>
                  </Typography>
                ),
              },
              {
                render: <WarehouseOrdersDateTime order={item} />,
              },
              {
                render: <WarehouseOrdersRoute order={item} />,
              },
              {
                render: <Typography variant="body2">{item.externalReference}</Typography>,
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.customerPrice != null
                      ? `${item.customerPrice.toFixed(0)} ${item.customerPriceCurrency
                      }`
                      : null}
                  </Typography>
                ),
              },
              {
                render: (
                  <WarehouseOrdersPackagesFulfilled order={item} />
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {getUniqueReceivedDates(item)}
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {getReceiversNames(item.warehouseOrder.cargoItemPackages)
                      .map((receiverName, index) => (
                        <>
                          {receiverName}
                          {(index + 1) < getReceiversNames(item.warehouseOrder.cargoItemPackages).length && <>,&nbsp;</>}
                        </>
                      ))
                    }
                  </Typography>
                ),
              },
            ];

            if (mode === WarehouseOrdersModeEnum.INCOMING_ORDERS) {
              item.tableCells.splice(
                4,
                0,
                {
                  render: <WarehouseOrderState warehouseOrder={item} state={warehouseStates} reload={reloadData} />
                }
              )
            }

            if (mode === WarehouseOrdersModeEnum.ORDERS_HISTORY) {
              item.tableCells.push(
                {
                  render: <Typography variant="body2">
                    {getDispatchersNames(item.warehouseOrder.cargoItemPackages)
                      .map((receiverName, index) => (
                        <>
                          {receiverName}
                          {(index + 1) < getDispatchersNames(item.warehouseOrder.cargoItemPackages).length && <>,&nbsp;</>}
                        </>
                      ))
                    }
                  </Typography>
                },
                {
                  render: <Typography variant="body2">
                    {getUniqueDispatchedDates(item)}
                  </Typography>
                }
              )
            }

            return item;
          }
        );
        const warehouseOrderData = updatedWarehouseOrders.reduce(
          (acc, curr) => [
            ...acc,
            {
              type: curr.type,
              id: curr.id,
              row: curr.tableCells,
              selected: false,
              detail: ({ setDetailed }) => (
                <ShipmentOverview
                  incomingOrder={curr}
                  id={curr.id}
                  warehouseOrderId={curr.warehouseOrder.id}
                  warehouseOrder={curr.warehouseOrder}
                  type="WAREHOUSE_ORDER"
                  shipments={updatedWarehouseOrders}
                  setShipments={() => {
                  }}
                  onSave={() => {
                  }}
                  open
                  setOpen={() => setDetailed(null)}
                />
              ),
            },
          ],
          []
        );

        setWarehouseOrders(updatedWarehouseOrders);
        return {
          data: warehouseOrderData,
          totalCnt: warehouseOrdersResponse.total,
        };
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("incomingOrders.error.get"), {
          variant: "error",
        });
      }
    },
    [search, additionalFilter, warehouseId]
  );

  const handleSelectLoadingsDateRange = (loadingDate) =>
    setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) =>
    setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) =>
    setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectReceivedDateDateRange = (receivedDate) =>
    setAdditionalFilter({ ...additionalFilter, receivedDate });
  const handleSelectDispatchedDateDateRange = (dispatchedDate) =>
    setAdditionalFilter({ ...additionalFilter, dispatchedDate });
  const handleSelectDeliveryDateDateRange = (deliveryDate) =>
    setAdditionalFilter({ ...additionalFilter, deliveryDate });
  const handleSelectIncomingOrderState = (state) =>
    setAdditionalFilter({ ...additionalFilter, state });
  const handleSelectWarehouseOrderState = (warehouseState) =>
    setAdditionalFilter({ ...additionalFilter, state: warehouseState });
  const handleSelectCustomer = (customers) =>
    setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarrier = (carriers) =>
    setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectVehicles = (vehicles) =>
    setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCustomerPrice = (customerPrice) =>
    setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) =>
    setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) =>
    setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) =>
    setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectReceivedBy = (receivedBy) =>
    setAdditionalFilter({ ...additionalFilter, receivedBy });
  const handleSelectDispatchedBy = (dispatchedBy) =>
    setAdditionalFilter({ ...additionalFilter, dispatchedBy });
  const handleSelectDrivers = (drivers) =>
    setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) =>
    setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) =>
    setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, receivedPaymentState });

  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now),
    });
  };

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId,
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle],
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitContact = async (contactId) => {
    try {
      const contact = await apiClient.contact.getContact({
        contactId,
      });

      if (contact.type === ContactType.CUSTOMER) {
        setAdditionalFilter({
          ...additionalFilter,
          customers: [contact],
        });
      } else {
        setAdditionalFilter({
          ...additionalFilter,
          carriers: [contact],
        });
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleSendOrder = async (
    shipmentId,
    { email, message, language, isSendCopyToMeChecked }
  ) => {
    try {
      await apiClient.email.postSendOrderByEmail({
        shipmentId,
        data: {
          email,
          message,
          language,
          hasSendCopyToMe: isSendCopyToMeChecked,
        },
      });
      enqueueSnackbar(t("webapp:shipment.send-order.success.title"), {
        variant: "success",
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:shipment.send-order.error.submit"), {
        variant: "error",
      });
    }
  };

  const loadAvailableUsers = async (search, roles = undefined) => {
    try {
      const { matches } = await apiClient.user.postUserMatchQuery({
        query: {
          match: { search, roles },
          limit: 15,
        },
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search,
          types: ["BOTH", type],
        },
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search,
        },
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers
      ? filter.customers.map(({ id }) => id)
      : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
  });

  const getReceiversNames = (cargoItems) => {
    let receiverNames = [];
    cargoItems.map(cargoItem => {
      if (!receiverNames.some(receiverName => receiverName === cargoItem.receivedBy.name)) {
        receiverNames.push(cargoItem.receivedBy.name)
      }
    })
    return receiverNames;
  }

  const getDispatchersNames = (cargoItems) => {
    let dispatchersName = [];
    cargoItems.map(cargoItem => {
      if (!dispatchersName.some(dispatcherName => dispatcherName === cargoItem.dispatchedBy.name)) {
        dispatchersName.push(cargoItem.dispatchedBy.name)
      }
    })
    return dispatchersName;
  }

  useEffect(() => {
    const { filterVehicleId, filterContactId } =
      queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
    if (filterContactId) {
      loadInitContact(filterContactId);
    }
  }, []);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData,
  } = useTable(reloadWarehouseOrders, "warehouse-orders");

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  useEffect(() => {
    const loadedFilters = loadFilters("warehouse-orders");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const selectedWarehouseOrders = data.filter(({ selected }) => selected);

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  const { hasPermission, user } = useAuth();
  const canUpdateIncomingOrder =
    hasPermission("resource.incomingOrder.company.update") ||
    hasPermission("resource.incomingOrder.user.update");
  const canCreateInvoice = hasPermission(
    "resource.incomingOrder.invoice.create"
  );
  const canCreateIncomingOrder = hasPermission(
    "resource.incomingOrder.user.create"
  );
  const canCreateOrderPdf = hasPermission(
    "resource.incomingOrder.summary.read"
  );
  const canDeleteIncomingOrder =
    hasPermission("resource.incomingOrder.user.delete") ||
    hasPermission("resource.incomingOrder.company.delete");
  const canReadInvoice = hasPermission("resource.incomingOrder.invoice.read");
  const canReadCompanyIncomingOrder = hasPermission(
    "resource.incomingOrder.company.read"
  );

  return (
    <>
      <WarehouseOrders
        data={data}
        mode={mode}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        incomingOrders={warehouseOrders}
        setIncomingOrders={setWarehouseOrders}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        canCreateOrderPdf={canCreateOrderPdf}
        canUpdateIncomingOrder={canUpdateIncomingOrder}
        canCreateInvoice={canCreateInvoice}
        canCreateIncomingOrder={canCreateIncomingOrder}
        canDeleteIncomingOrder={canDeleteIncomingOrder}
        canReadInvoice={canReadInvoice}
        canReadCompanyIncomingOrder={canReadCompanyIncomingOrder}
        additionalFilter={additionalFilter}
        handleSort={handleSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
        handleSelectLastMonth={handleSelectLastMonth}
        filter={additionalFilter}
        handleDeselect={handleDeselect}
        loadAvailableUsers={loadAvailableUsers}
        defaultFilters={defaultFilters}
        handleSelectIncomingOrderState={handleSelectIncomingOrderState}
        handleSelectWarehouseOrderState={handleSelectWarehouseOrderState}
        handleSelectCustomer={handleSelectCustomer}
        handleSelectCustomerPrice={handleSelectCustomerPrice}
        loadAvailableContacts={loadAvailableContacts}
        handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
        handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
        handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
        handleSelectReceivedDateDateRange={handleSelectReceivedDateDateRange}
        handleSelectDispatchedDateDateRange={handleSelectDispatchedDateDateRange}
        handleSelectDeliveryDateDateRange={handleSelectDeliveryDateDateRange}
        handleSelectCreators={handleSelectCreators}
        handleSelectReceivedBy={handleSelectReceivedBy}
        handleSelectDispatchedBy={handleSelectDispatchedBy}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
        handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
        handleSendOrder={handleSendOrder}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        handleDialogClosed={reloadData}
        handleWarehouseOrdersExportRequest={handleWarehouseOrdersExportRequest}
        isWarehouseOrdersExportDialogOpen={isWarehouseOrdersExportDialogOpen}
        isWarehouseOrdersExportDone={isWarehouseOrdersExportDone}
        isWarehouseOrdersExportProcessing={isWarehouseOrdersExportProcessing}
        warehouseOrdersExportLink={warehouseOrdersExportLink}
        closeWarehouseOrdersExportDialog={closeWarehouseOrdersExportDialog}
        exportWarehouseOrdersToRinkai={exportWarehouseOrdersToRinkai}
        rinkaiDepo={rinkaiDepo}
        setRinkaiDepo={setRinkaiDepo}
        handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
        exportOrdersRinkai={exportWarehouseOrdersToRinkai}
        exportWarehouseOrders={exportWarehouseOrders}
        warehouseId={warehouseId}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default WarehouseOrdersContainer;
