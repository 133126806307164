import {
  endOfMonth,
  startOfMonth,
  parseISO,
  isBefore,
  differenceInDays,
  endOfToday,
  set,
} from "date-fns";
import React, { useCallback, useState, useEffect, useRef } from "react";
import queryString from "query-string";
import {
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  IconButton,
  Link,
  Chip,
} from "@material-ui/core";

import { Error, Cancel, CheckCircle, CallMade } from "@material-ui/icons";

import { ContactType } from "@cargoticcom/model";
import { formatDate } from "@cargoticcom/common";

import ShipmentOverview from "@cargotic/webapp-shipment-overview";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useRouter from "../../hook/useRouter";

import IncomingOrders from "./IncomingOrders";
import useTable from "../../../datatable/useTable";
import IncomingOrdersDateTime from "./IncomingOrdersDateTime";
import IncomingOrdersRoute from "./IncomingOrdersRoute";
import IncomingOrdersRouteContact from "./IncomingOrdersRouteContact";
import IncomingOrdersState from "./IncomingOrderState";

import useAuth from "../../hook/useAuth";
import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";
import {
  addUrlParam,
  getTableUrlParams,
  getUrlWithParams,
} from "../../../utility/window";

import {
  storeFilters,
  loadFilters,
  storeActiveFilters,
} from "../../../storage";

import {
  exportOrdersRinkai,
  createShipmentInvoice,
  updateIncomingOrderState,
  putIncomingOrderById,
  createPlacesQuery,
  createStateSuggestQuery,
  deleteIncomingOrders,
  postExportIncomingOrdersToCSV,
  updateIncomingOrdersStatuses,
  suggestUsers
} from "../../../resource";
import IncomingOrdersDeleteDialog from "./IncomingOrdersDeleteDialog";
import DetailedIncomingOrderTableRow from "./DetailedIncomingOrdersTableRow";
import CSVImport from "./import/CSVImport";
import useBoltClient from "../../hook/useBoltClient";

import {
  HrefInNewTabIcon,
  useApiClient,
} from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import StatusesDialog from "./StatusesDialog";
import IncomingOrdersOutcomingOrderReference from "./IncomingOrdersOutcomingOrderReference";
import EditArriveDateTimeRange from "./EditArriveDateTimeRange";

const { printLabels, createGetBoxes } = require("./../../../resource");

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)",
  },
  inlineContent: {
    display: "inline-flex",
  },
  warning: {
    fill: "silver",
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main,
  },
  success: {
    fill: "#009688",
  },
}));

const IncomingOrdersContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const pathname = location.pathname.slice(1);

  const [incomingOrders, setIncomingOrders] = useState([]);
  const { isReady: isBoltClientReady, client: boltClient } = useBoltClient();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLabelsDownloadingLoading, setIsLabelsDownloadingLoading] =
    useState(false);

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { searchText: initSearchText, filter: initFilter } =
    getTableUrlParams(routerSearch);

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);
  const [
    isIncomingOrdersExportDialogOpen,
    setIsIncomingOrdersExportDialogOpen,
  ] = useState(false);
  const [
    isIncomingOrdersExportProcessing,
    setIsIncomingOrdersExportProcessing,
  ] = useState(false);
  const [
    isIncomingOrdersDeleteProcessing,
    setIsIncomingOrdersDeleteProcessing,
  ] = useState(false);
  const [isIncomingOrdersExportDone, setIsIncomingOrdersExportDone] =
    useState(false);
  const [incomingOrdersExportLink, setIncomingOrdersExportLink] =
    useState(null);
  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isEditArriveDateTimeRangeDialogOpen, setIsEditArriveDateTimeRangeDialogOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [editedOrder, setEditedOrder] = useState({});
  const [editedWaypoint, setEditedWaypoint] = useState({});
  const [allIds, setAllIds] = useState([]);
  const [allPagesSelected, setAllPagesSelected] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);

  const paymentAvailable = useRef(true);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("incoming-orders", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const { token } = useAuth().user;

  const removeKeysFilter = ["customers", "state", "creators", "places"]
  // save index for IncomingOrder Headers
  const removeKeysHeader = [
    { name: "customerContact" },
    { name: "createdBy" },
    { name: "tags" },
  ];

  const removeItemsByToken = (arrObj) => {
    // not token, not change data
    if (!token) {
      return arrObj
    }

    // array
    if (Array.isArray(arrObj)) {
      // string ['string']
      if (typeof arrObj[0] === 'string') {
        return arrObj.filter(e => !removeKeysFilter.includes(e));
        // object [{}]
      } if (typeof arrObj[0] === 'object') {
        // value in object [{value:'string'}]
        if (arrObj[0].value) {
          return arrObj.filter(e => !removeKeysFilter.includes(e.value));
        }

        // name in object [{name:'string'}]
        if (arrObj[0].name) {
          // set index for IncomingOrder Headers
          arrObj.forEach((e, index) => {
            const item = removeKeysHeader.find(i => i.name === e.name);
            if (item && e.name === item.name)
              item.index = index;
          });
          return arrObj.filter((e) => !removeKeysHeader.map(i => i.name).includes(e.name));
        }
        // render in object [{render:'string'}]
        if (arrObj[0].render) {
          return arrObj;
          // .filter((e, index) => !removeKeysHeader.map(i => i.index).includes(index));
        }

        // render in object [{key:'string'}]
        if (arrObj[0].key) {
          return arrObj.filter(e => !removeKeysFilter.includes(e.key));
        }
      }
    }

    // object
    if (typeof arrObj === 'object' && !Array.isArray(arrObj)) {
      // delete find items
      removeKeysFilter.forEach(e => e in arrObj && delete arrObj[e])
      return arrObj;
    }

    // not exist condition ,not change data
    return arrObj;
  };

  const defaultFilterValues = removeItemsByToken([
    "customers",
    "loadingDate",
    "unloadingDate",
    "creators",
    "state",
    "customerPrice",
    "places",
    "cargo",
    "createdAt",
    "isDraft",
  ]);
  const availableFilters = removeItemsByToken([
    {
      label: t("contacts.customer"),
      value: "customers",
    },
    {
      label: t("incomingOrders.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("incomingOrders.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.creationDate"),
      value: "createdAt",
    },
    {
      label: t("incomingOrders.state"),
      value: "state",
    },
    {
      label: t("incomingOrders.customerPrice"),
      value: "customerPrice",
    },
    {
      label: t("incomingOrders.creator"),
      value: "creators",
    },
    {
      label: t("incomingOrders.cargo"),
      value: "cargo",
    },
    {
      label: t("incomingOrders.draft"),
      value: "isDraft",
    },
    {
      label: t("shipments.places"),
      value: "places",
    },
    {
      label: t("shipments.box.title"),
      value: "boxes",
    },
  ]);

  const classes = useStyles();

  const apiClient = useApiClient();

  let reloadDelay;
  let storeSearchDelay;

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers
      ? filter.customers.map(({ id }) => id)
      : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
    places: filter.places ? filter.places.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined,
    boxes: filter.boxes ? filter.boxes.map(({ box }) => box) : undefined,
  });

  const isDue = (dateString) =>
    dateString && isBefore(parseISO(dateString), new Date());
  const getOverdueDays = (dateString) =>
    dateString ? differenceInDays(new Date(), parseISO(dateString)) : undefined;

  const IncomingOrdersInvoices = ({ incomingOrder }) => (
    <Grid key={incomingOrder.id} container>
      <Grid
        key="issuedInvoiceNumber"
        item
        xs={10}
        className={classes.inlineContent}
      >
        <Tooltip title={t("incomingOrders.issuedInvoice")}>
          <CallMade className={classes.success} style={{ marginRight: 5 }} />
        </Tooltip>
        <Typography
          variant="body2"
          className={
            isDue(incomingOrder.issuedInvoiceDueDate) &&
              !incomingOrder.issuedInvoicePaidAt
              ? classes.error
              : null
          }
        >
          {incomingOrder.issuedInvoiceNumber
            ? `#${incomingOrder.issuedInvoiceNumber}`
            : "-"}
        </Typography>
      </Grid>
      <Grid key="issuedInvoicePaidAt" item xs={2}>
        {incomingOrder.issuedInvoicePaidAt ? (
          <Tooltip
            title={t("incomingOrders.beenPaid", {
              paidAt: formatDate(
                parseISO(incomingOrder.issuedInvoicePaidAt),
                true
              ),
            })}
          >
            <IconButton
              size="small"
              onClick={() =>
                paymentAvailable.current &&
                handlePayIncomingOrder(incomingOrder.id, {
                  ...incomingOrder,
                  issuedInvoicePaidAt: null,
                })
              }
            >
              <CheckCircle className={classes.success} />
            </IconButton>
          </Tooltip>
        ) : isDue(incomingOrder.issuedInvoiceDueDate) ? (
          <Tooltip
            title={t("incomingOrders.overdue", {
              days: getOverdueDays(incomingOrder.issuedInvoiceDueDate),
            })}
          >
            <IconButton
              size="small"
              onClick={() =>
                paymentAvailable.current &&
                handlePayIncomingOrder(incomingOrder.id, {
                  ...incomingOrder,
                  issuedInvoicePaidAt: endOfToday(),
                })
              }
            >
              <Error className={classes.error} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("incomingOrders.unpaid")}>
            <IconButton
              size="small"
              onClick={() =>
                paymentAvailable.current &&
                handlePayIncomingOrder(incomingOrder.id, {
                  ...incomingOrder,
                  issuedInvoicePaidAt: endOfToday(),
                })
              }
            >
              <Cancel className={classes.warning} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );

  const handleClickEditDateTime = (order, waypointId) => {
    setEditedOrder({ ...order, waypointId });
    const waypoint = order.journey
      ? order.journey.waypoints.find((item) => item.id === waypointId)
      : {};
    const parsedWaypoint = {
      ...waypoint,
      arriveAtFrom:
        waypoint.arriveAtFrom || set(new Date(), { hours: 8, minutes: 0 }),
      arriveAtTo:
        waypoint.arriveAtTo || set(new Date(), { hours: 20, minutes: 0 }),
    };

    setEditedWaypoint(parsedWaypoint);
    setIsEditArriveDateTimeRangeDialogOpen(true);
  };

  const reloadIncomingOrders = useCallback(
    async (offset, limit, ordering) => {
      const filter = removeItemsByToken(transformFilter(additionalFilter));
      try {
        const state = await createStateSuggestQuery({
          resources: ["incoming_order"],
        });
        const incomingOrdersResponse =
          await apiClient.incomingOrder.postIncomingOrderMatchQuery({
            query: {
              match: {
                search,
                ...filter,
              },
              orderBy: ordering,
              offset,
              limit,
            },
          });

        setAllIds(incomingOrdersResponse.allIds || []);

        if (incomingOrdersResponse.total === 0 && offset !== 0) {
          handleChangePage(undefined, 0);
        }
        const updatedIncomingOrders = incomingOrdersResponse.matches.map(
          (item) => {
            const isDisabled = item.isDisabled === 1;
            const selected = false;
            const { id } = item;

            let row = removeItemsByToken([
              {
                render: !item.isDraft ? (
                  <Typography variant="body2">
                    {' '}
                    #
                    {item.indexNumber}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    <i>{t("incomingOrders.draft")}</i>
                  </Typography>
                ),
              },
              {
                render: item.customerContact ? (
                  <Typography variant="body2">
                    <Link
                      component={RouterLink}
                      to={`/contacts/${item.customerContact.id}`}
                      rel="noopener"
                      target="_blank"
                    >
                      {item.customerContact.name ||
                        item.customerContact.email ||
                        item.customerContact.phoneNumber}
                      <HrefInNewTabIcon />
                    </Link>
                  </Typography>
                ) : undefined,
              },
              {
                render: (
                  <IncomingOrdersDateTime
                    incomingOrder={item}
                    isEditable={!token}
                    handleClickEditDateTime={handleClickEditDateTime}
                  />
                ),
              },
              {
                render: <IncomingOrdersRoute incomingOrder={item} />,
              },
              {
                render: <IncomingOrdersRouteContact incomingOrder={item} />,
              },
              {
                render: token ? (
                  <Typography variant="body2">{item.stateName}</Typography>
                ) : (
                  <IncomingOrdersState
                    incomingOrder={item}
                    updateIncomingOrderState={updateIncomingOrderState}
                    reload={reload}
                    state={state}
                    isDisabled={user.companyId ===2}
                  />
                ),
              },
              {
                render: <Typography variant="body2">{item.box}</Typography>,
              },
              {
                render: (
                  <IncomingOrdersOutcomingOrderReference incomingOrder={item} />
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.externalReference}
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.customerPrice != null
                      ? `${item.customerPrice.toFixed(0)} ${item.customerPriceCurrency
                      }`
                      : null}
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">{item.sumCargoValue}</Typography>
                ),
              },
              {
                render: (
                  <UserAvatar
                    size="large"
                    user={{
                      ...item.createdBy,
                      name: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
                    }}
                  />
                ),
              },
              {
                render: item.tags
                  ? item.tags.map((tag) => <Chip label={tag} />)
                  : null,
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.cashOndeliveryValue != null
                      ? item.cashOndeliveryValue.toFixed(0)
                      : null}
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.driver ? `${item.driver.firstName} ${item.driver.lastName}` : null}
                  </Typography>
                ),
              },
            ]);
            // TODO
            if (token) {
              row = row.filter((e, i) => i !== 1 && i !== 10 && i !== 11);
            }

            return { ...item, id, row, selected, isDisabled };
          });
        const incomingOrderData = updatedIncomingOrders.reduce(
          (acc, curr) => [
            ...acc,
            {
              type: curr.type,
              id: curr.id,
              row: curr.row,
              selected: false,
              detail: ({ setDetailed }) => (
                <ShipmentOverview
                  id={curr.id}
                  incomingOrder={curr}
                  type={ShipmentOverview.TYPES.INCOMING_ORDER}
                  shipments={updatedIncomingOrders}
                  setShipments={() => { }}
                  onSave={(updatedOrder) => {
                    console.log("o", updatedOrder);
                  }}
                  onDraftSave={(updatedOrder) => {
                    console.log("oDraft", updatedOrder)
                  }}
                  open
                  setOpen={() => setDetailed(null)}
                />
              ),
            },
          ],
          []
        );

        setIncomingOrders(updatedIncomingOrders);
        return {
          data: incomingOrderData,
          totalCnt: incomingOrdersResponse.total,
        };
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("incomingOrders.error.get"), {
          variant: "error",
        });
      }
    },
    [search, additionalFilter]
  );

  const handleSelectLoadingsDateRange = (loadingDate) =>
    setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) =>
    setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) =>
    setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectIncomingOrderState = (state) => {
    setAdditionalFilter({ ...additionalFilter, state });
  };
  const handleSelectCustomer = (customers) =>
    setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarrier = (carriers) =>
    setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectVehicles = (vehicles) =>
    setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCustomerPrice = (customerPrice) =>
    setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) =>
    setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) =>
    setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) =>
    setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) =>
    setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) =>
    setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) =>
    setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, receivedPaymentState });
  const handleSelectPlaces = (places) =>
    setAdditionalFilter({ ...additionalFilter, places });
  const handleSelectBoxes = (boxes) =>
    setAdditionalFilter({ ...additionalFilter, boxes });
  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now),
    });
  };

  const loadAvailableUsers = async (search, roles = undefined) => {
    try {
      const matches = await suggestUsers({
        search,
        permissions: roles || ["resource.incomingOrder.user.create"]
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search,
          types: ["BOTH", type],
        },
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search,
        },
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };
  const loadAvailableStates = async (search) => {
    try {
      const states = await createStateSuggestQuery({
        resources: ["incoming_order"],
      });
      return states;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId,
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle],
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitContact = async (contactId) => {
    try {
      const contact = await apiClient.contact.getContact({
        contactId,
      });

      if (contact.type === ContactType.CUSTOMER) {
        setAdditionalFilter({
          ...additionalFilter,
          customers: [contact],
        });
      } else {
        setAdditionalFilter({
          ...additionalFilter,
          carriers: [contact],
        });
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleSelectPlace = (placeId) => {
    setSelectedPlaceId(placeId);
  };

  const handleDownloadLabelsFile = (data) => {
    try {
      if (typeof data.errors !== "undefined") {
        console.error(data);
        data.errors.map((error) => {
          enqueueSnackbar(t(error), {
            variant: "error",
          });
        });
        setIsLabelsDownloadingLoading(false);
        return;
      }

      const element = document.createElement("a");
      element.setAttribute("href", `data:application/octet-stream;base64,${data}`);
      element.setAttribute("download", "labels.pdf");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setIsLabelsDownloadingLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const { filterVehicleId, filterContactId } =
      queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
    if (filterContactId) {
      loadInitContact(filterContactId);
    }
  }, []);

  useEffect(() => {
    const listener = ({ data }) => {
      const { type, content } = JSON.parse(data);
      if (type === "INCOMING_ORDER_LABEL") {
        handleDownloadLabelsFile(content);
      }
    };

    boltClient.addEventListener("message", listener);

    return () => {
      boltClient.removeEventListener("message", listener);
    };
  }, []);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData,
  } = useTable(reloadIncomingOrders, "incoming-orders");

  const generateIncomingOrderInvoice = async (idIncomingOrder) => {
    await createShipmentInvoice(idIncomingOrder);
    reload();
  };

  const handlePayIncomingOrder = async (
    id,
    {
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      issuedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      receivedInvoicePaidAt,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate,
    }
  ) => {
    try {
      paymentAvailable.current = false;
      await apiClient.incomingOrder.postIncomingOrderPayment({
        incomingOrderId: id,
        payment: {
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccountId,
          taxableSupplyDate,
          invoiceNote,
          issueDate,
        },
      });

      setIncomingOrders((cur) =>
        cur.map((incomingOrder) =>
          incomingOrder.id === id
            ? {
              ...incomingOrder,
              issuedInvoiceNumber,
              issuedInvoiceDueDate,
              issuedInvoicePaidAt,
              receivedInvoiceDueDate,
              receivedInvoiceNumber,
              receivedInvoicePaidAt,
              bankAccountId,
              taxableSupplyDate,
              invoiceNote,
              issueDate,
            }
            : incomingOrder
        )
      );

      setData((current) =>
        current.map((incomingOrder) => {
          if (incomingOrder.id === id) {
            const row = [...incomingOrder.row];

            row[5] = {
              render: (
                <IncomingOrdersInvoices
                  shipment={{
                    id,
                    type: incomingOrder.type,
                    issuedInvoiceNumber,
                    issuedInvoiceDueDate:
                      typeof issuedInvoiceDueDate === "object"
                        ? issuedInvoiceDueDate.toISOString()
                        : issuedInvoiceDueDate,
                    issuedInvoicePaidAt:
                      typeof issuedInvoicePaidAt === "object"
                        ? issuedInvoicePaidAt?.toISOString()
                        : issuedInvoicePaidAt,
                    receivedInvoiceDueDate:
                      typeof receivedInvoiceDueDate === "object"
                        ? receivedInvoiceDueDate.toISOString()
                        : receivedInvoiceDueDate,
                    receivedInvoiceNumber,
                    receivedInvoicePaidAt:
                      typeof receivedInvoicePaidAt === "object"
                        ? receivedInvoicePaidAt?.toISOString()
                        : receivedInvoicePaidAt,
                    bankAccountId,
                    taxableSupplyDate:
                      typeof taxableSupplyDate === "object"
                        ? taxableSupplyDate?.toISOString()
                        : taxableSupplyDate,
                    invoiceNote,
                    issueDate,
                  }}
                />
              ),
            };

            return {
              ...incomingOrder,
              row,
            };
          }

          return incomingOrder;
        })
      );
      // await reload();
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("incomingOrders.error.paidAt"), {
        variant: "error",
      });
    } finally {
      paymentAvailable.current = true;
    }
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const loadedFilters = loadFilters("incoming-orders");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const selectedIncomingOrders = data.filter(({ selected }) => selected);

  const handleSelectAllOnPage = (bool) => {
    setAllPagesSelected(bool);
  };

  const handleDeleteSubmit = async () => {
    setDeleteDialogOpen(false);
    setIsIncomingOrdersDeleteProcessing(true);

    try {
      const ids = allPagesSelected
        ? allIds
        : selectedIncomingOrders.map(({ id }) => id);
      await deleteIncomingOrders(ids);
      await reload();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("orders.error.delete"), {
        variant: "error",
      });
    } finally {
      setIsIncomingOrdersDeleteProcessing(false);
    }
  };

  const handleIncomingOrdersExportRequest = () => {
    setIsIncomingOrdersExportDialogOpen(true);
  };

  const handlePrintLabels = async () => {
    try {
      setIsLabelsDownloadingLoading(true);
      const ids = allPagesSelected
        ? allIds
        : selectedIncomingOrders.map(({ id }) => id);
      const isAnyConcept =
        incomingOrders.filter(({ id, isDraft }) => ids.includes(id) && isDraft)
          .length > 0;
      if (isAnyConcept) {
        enqueueSnackbar("Chyba během generování štítků. Některá z objednávek je koncept.", {
          variant: "error",
          persist: true,
        }
        );
        setIsLabelsDownloadingLoading(false);
        return;
      }
      await printLabels(ids);
    } catch (error) {
      console.log(error);
      setIsLabelsDownloadingLoading(false);
    }
  };

  const handleOpenSetStatuses = () => setIsStatusDialogOpen(true);
  const handleOpenCSVImport = () => {
    setIsIncomingOrdersExportDialogOpen(true);
  };

  const closeIncomingOrdersExportDialog = () => {
    setIsIncomingOrdersExportDialogOpen(false);

    if (isIncomingOrdersExportDone) {
      setIsIncomingOrdersExportDone(false);
      setIncomingOrdersExportLink(null);
    }
  };

  const handleLoadAvailablePlaces = async (search) => {
    try {
      const { places } = await createPlacesQuery({
        filter: { search, id: null },
      });
      return places;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleLoadAvailableBoxes = async (search) => {
    try {
      const boxes = await createGetBoxes({
        filter: { search },
      });
      return boxes;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const exportIncomingOrdersToRinkai = async (ids) => {
    setIsIncomingOrdersExportProcessing(true);
    try {
      const ids = allPagesSelected
        ? allIds
        : selectedIncomingOrders.map(({ id }) => id);
      const { id: placeId, alias } = rinkaiDepo;
      const response = await exportOrdersRinkai(ids, placeId, alias);
      if (response.status === "error") {
        response.errors.map(({ t: translate, indexNumber }) => {
          enqueueSnackbar(t(translate, { indexNumber }), {
            variant: "error",
            persist: true,
          });
        });
      } else if (response.result === "webapp:rinkai:dataError") {
        enqueueSnackbar(t(response.result), {
          variant: "error",
          persist: true,
        });
      } else {
        enqueueSnackbar(t(response.result), {
          variant: "success",
        });
        setIsIncomingOrdersExportDialogOpen(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsIncomingOrdersExportProcessing(false);
    }
  };

  const exportIncomingOrders = async (language) => {
    setIsIncomingOrdersExportProcessing(true);
    try {
      const ids = allPagesSelected
        ? allIds
        : selectedIncomingOrders.map(({ id }) => id);
      const data = await postExportIncomingOrdersToCSV(ids);
      const element = document.createElement("a");
      element.setAttribute("href", `data:application/octet-stream;base64,${data}`);
      element.setAttribute("download", "incoming-orders.csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      setIsIncomingOrdersExportDone(true);
      setIsIncomingOrdersExportDialogOpen(false);
    } catch (error) {
      console.log(error);

      const type = error.response.data.error;

      if (type === "ShipmentExportLimitExceededError") {
        enqueueSnackbar(t("orders.export.errorLimit"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("orders.export.error"), {
          variant: "error",
        });
      }
    } finally {
      setIsIncomingOrdersExportProcessing(false);
    }
  };

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
    const f = getUrlWithParams("filter", additionalFilter);
    storeActiveFilters("incoming-orders", f);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  const { hasPermission, user } = useAuth();
  console.log(user, 'user');

  const canUpdateIncomingOrder =
    hasPermission("resource.incomingOrder.company.update") ||
    hasPermission("resource.incomingOrder.user.update");
  const canCreateInvoice = hasPermission(
    "resource.incomingOrder.invoice.create"
  );
  const canCreateIncomingOrder = hasPermission(
    "resource.incomingOrder.user.create"
  );
  const canDeleteIncomingOrder =
    hasPermission("resource.incomingOrder.user.delete") ||
    hasPermission("resource.incomingOrder.company.delete");
  const canReadCompanyIncomingOrder = hasPermission(
    "resource.incomingOrder.company.read"
  );
  const hasImportPermission = hasPermission("resource.incomingOrder.import");

  const [rinkaiDepo, setRinkaiDepo] = useState(null);
  const handleRinkaiDepoInputChange = (e) => {
    setRinkaiDepo(e.target.value);
  };

  const handleSetStatusesSubmit = async (stateId) => {
    try {
      const ids =
        allPagesSelected ?
          allIds :
          selectedIncomingOrders.map(({ id }) => id);
      await updateIncomingOrdersStatuses(ids, stateId);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("incomingOrders.error.setStatus"), {
        variant: "error",
      });
    } finally {
      setIsStatusDialogOpen(false);
      reload();
    }
  };

  const handleSaveDateTimeRange = async (values) => {
    setIsEditArriveDateTimeRangeDialogOpen(false);
    try {
      const { waypointId, journey, ...rest } = editedOrder;
      const updatedWaypoint = {
        ...journey.waypoints.find(({ id }) => id === waypointId),
        ...values,
      };
      const newOrder = {
        ...rest,
        journey: {
          ...journey,
          waypoints: journey.waypoints.map((item) =>
            item.id === waypointId ? updatedWaypoint : item
          ),
        },
      };

      await putIncomingOrderById(editedOrder.id, newOrder);
      await reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("incomingOrders.error.changeDateTimeRange"), {
        variant: "error",
      });
    }
  };

  return (
    <>
      {pathname === "incoming-order/csv-import" ? (
        <CSVImport />
      ) : (
        <IncomingOrders
          data={data}
          onSelectAllOnPage={handleSelectAllOnPage}
          setRinkaiDepo={setRinkaiDepo}
          rinkaiDepo={rinkaiDepo}
          handleRinkaiDepoInputChange={handleRinkaiDepoInputChange}
          dataCount={dataCount}
          selectedColumns={selectedColumns}
          incomingOrders={incomingOrders}
          setIncomingOrders={setIncomingOrders}
          loading={loading}
          search={search}
          ordering={ordering}
          direction={direction}
          rowsPerPage={rowsPerPage}
          page={page}
          checkedAll={checkedAll}
          exportIncomingOrders={exportIncomingOrders}
          canUpdateIncomingOrder={canUpdateIncomingOrder}
          canCreateInvoice={canCreateInvoice}
          canCreateIncomingOrder={canCreateIncomingOrder}
          canDeleteIncomingOrder={canDeleteIncomingOrder}
          canReadCompanyIncomingOrder={canReadCompanyIncomingOrder}
          hasImportPermission={hasImportPermission}
          isIncomingOrdersExportDialogOpen={isIncomingOrdersExportDialogOpen}
          isIncomingOrdersExportProcessing={isIncomingOrdersExportProcessing}
          isIncomingOrdersExportDone={isIncomingOrdersExportDone}
          isIncomingOrdersDeleteProcessing={isIncomingOrdersDeleteProcessing}
          isLabelsDownloadingLoading={isLabelsDownloadingLoading}
          incomingOrdersExportLink={incomingOrdersExportLink}
          additionalFilter={additionalFilter}
          closeIncomingOrdersExportDialog={closeIncomingOrdersExportDialog}
          handleSort={handleSort}
          handleChangePage={handleChangePage}
          handleIncomingOrdersExportRequest={handleIncomingOrdersExportRequest}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSearch={handleSearch}
          handleSelectAll={handleSelectAll}
          handleSelect={handleSelect}
          handleSelectPlace={handleSelectPlace}
          handleDeleteRequest={() => setDeleteDialogOpen(true)}
          handleGenerateIncomingOrderInvoice={generateIncomingOrderInvoice}
          handlePayIncomingOrder={handlePayIncomingOrder}
          handleSelectLastMonth={handleSelectLastMonth}
          filter={additionalFilter}
          handleDeselect={handleDeselect}
          loadAvailableUsers={loadAvailableUsers}
          defaultFilters={defaultFilters}
          handleSelectIncomingOrderState={handleSelectIncomingOrderState}
          handleSelectCustomer={handleSelectCustomer}
          handleSelectCustomerPrice={handleSelectCustomerPrice}
          loadAvailableContacts={loadAvailableContacts}
          handleLoadAvailablePlaces={handleLoadAvailablePlaces}
          handleLoadAvailableBoxes={handleLoadAvailableBoxes}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
          handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
          handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
          handleSelectCreators={handleSelectCreators}
          handleSelectCargoTemplate={handleSelectCargoTemplate}
          handleSelectIsDraft={handleSelectIsDraft}
          handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
          handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
          handleSelectPlaces={handleSelectPlaces}
          handleSelectDrivers={handleSelectDrivers}
          handleSelectBoxes={handleSelectBoxes}
          handlePrintLabels={handlePrintLabels}
          exportOrdersRinkai={exportIncomingOrdersToRinkai}
          handleChangeSelectedColumns={handleChangeSelectedColumns}
          loadAvailableStates={loadAvailableStates}
          removeItemsByToken={removeItemsByToken}
          handleOpenSetStatuses={handleOpenSetStatuses}
        />
      )}
      <IncomingOrdersDeleteDialog
        open={deleteDialogOpen}
        selected={
          allPagesSelected && typeof allIds !== "undefined"
            ? allIds.length
            : selectedIncomingOrders.length
        }
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <EditArriveDateTimeRange
        isOpen={isEditArriveDateTimeRangeDialogOpen}
        onSave={handleSaveDateTimeRange}
        onClose={() => setIsEditArriveDateTimeRangeDialogOpen(false)}
        waypoint={editedWaypoint}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
      <StatusesDialog
        isOpen={isStatusDialogOpen}
        handleClose={() => setIsStatusDialogOpen(false)}
        handleSubmit={handleSetStatusesSubmit}
      />
    </>
  );
};

export default IncomingOrdersContainer;
