const AvailableCargoItemTemplates = [
    { label: "core:cargo.item.template.EUR_PALLET", value: "EUR_PALLET" },
    { label: "core:cargo.item.template.RETURNABLE_PALLET", value: "RETURNABLE_PALLET" },
    { label: "core:cargo.item.template.US_PALLET", value: "US_PALLET" },
    {
        label: "core:cargo.item.template.HALF_EUR_PALLET",
        value: "HALF_EUR_PALLET",
    },
    {
        label: "core:cargo.item.template.BOX",
        value: "BOX",
    },
    { label: "core:cargo.item.template.LDM", value: "LDM" },
    { label: "core:cargo.item.template.LKW", value: "LKW" },
    { label: "core:cargo.item.template.TANDEM", value: "TANDEM" },
    { label: "core:cargo.item.template.MEGA", value: "MEGA" },
    { label: "core:cargo.item.template.SOLO", value: "SOLO" },
    { label: "core:cargo.item.template.VAN", value: "VAN" },
    { label: "core:cargo.item.template.undefined", value: undefined },
    {
        label: "core:cargo.item.template.FRIGO_SEMI_TRAILER",
        value: "FRIGO_SEMI_TRAILER",
    },
    {
        label: "core:cargo.item.template.BOX_SEMI_TRAILER",
        value: "BOX_SEMI_TRAILER",
    },
];

export default AvailableCargoItemTemplates;