import React, { useState, useRef, useEffect } from "react";
import MapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { useTheme } from "@material-ui/core";
import { calculateRoute } from "../../../../../multiload/cargotic-map";
import { teal, blue, red, green, orange } from "@material-ui/core/colors";
import { Circle } from "react-google-maps";
// import renderToJson from "react-render-to-json";
import { Stop, AddCircle } from "@material-ui/icons";
import { format } from 'date-fns';

const CZECH_REPUBLIC_GEOLOCATION = {
  lat: 49.8175,
  lng: 15.4730
};

const TrackingMap = ({ googleMapsApi, tracking, driverPath, driverIds, plannedPath, selectedDriverId, routes }) => {
  const theme = useTheme();
  const mapRef = useRef();
  const [map, setMap] = useState();
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const directionsService = useRef();
  const directionsRenderer = useRef();

  const prevStartStopMarkers = useRef([]);
  const prevMarkersRef = useRef([]);
  const prevPolyline = useRef([]);

  const currentPath = useRef([]);
  const infoWindow = useRef(undefined);

  const addMarker = (location, map, label, color) => {
    const marker = new google.maps.Marker({
      position: location,
      label,
      map: map,
    });
    prevStartStopMarkers.current.push(marker);
  }

  useEffect(() => {
    const newMap = new googleMapsApi.Map(
      mapRef.current,
      {
        center: CZECH_REPUBLIC_GEOLOCATION,
        zoom: 8,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: MapTheme
      }
    );

    setMap(newMap);
    directionsService.current = new googleMapsApi.DirectionsService();
    directionsRenderer.current = new googleMapsApi.DirectionsRenderer({
      polylineOptions: {
        strokeColor: theme.palette.primary.main,
        strokeOpacity: 0.7,
        strokeWeight: 10
      }
    });

    googleMapsApi.event.addListenerOnce(
      newMap,
      "idle",
      () => setIsMapLoaded(true)
    );
  }, []);

  // useEffect(() => {
  //   markers.forEach(({ id: markerId, marker }) => {
  //     marker.setIcon({
  //       path: google.maps.SymbolPath.CIRCLE,
  //       scale: 8,
  //       fillOpacity: 1,
  //       strokeWeight: 0,
  //       fillColor: markerId === selectedDriverId ? blue[700] : red[700],
  //     });
  //   });
  // }, [markers, driverPath]);

  // useEffect(() => {
  //   let newMarkers = [];
  //   tracking?.forEach(({ id, vehicle, driver, tracking: { latitude, longitude } }, index) => {
  //     const existingMarker = markers.find(({ id: markerId }) => markerId === id);
  //     if (existingMarker) {
  //       existingMarker.marker.setPosition({
  //         lat: latitude,
  //         lng: longitude
  //       });
  //       newMarkers = [...newMarkers, { id, marker: existingMarker.marker }];
  //       return;
  //     }
  //     const marker = new google.maps.Marker({
  //       icon: {
  //         path: google.maps.SymbolPath.CIRCLE,
  //         scale: 8,
  //         strokeWeight: 0,
  //         fillColor: red[700]
  //       },
  //       position: {
  //         lat: latitude,
  //         lng: longitude
  //       },
  //       map,
  //     });

  //     const markerInfoWindow = new google.maps.InfoWindow({
  //       content: `
  //           <b>${driver?.name}</b></br>
  //           ${vehicle?.manufacturer} ${vehicle?.model}, ${vehicle?.licensePlate}
  //         `
  //     });
  //     markerInfoWindow.addListener("closeclick", () => infoWindow.current = null);

  //     marker.addListener("click", () => {
  //       const currentInfoWindow = infoWindow.current;
  //       if (currentInfoWindow) {
  //         currentInfoWindow.close();
  //         if (currentInfoWindow === markerInfoWindow) {
  //           infoWindow.current = null;
  //           return;
  //         }
  //       }
  //       infoWindow.current = markerInfoWindow;
  //       markerInfoWindow.open(map, marker)
  //     });

  //     newMarkers = [...newMarkers, { id, marker }];
  //   });
  //   setMarkers(newMarkers);
  // }, [map, tracking]);

  useEffect(() => {
    prevMarkersRef.current.forEach((marker) => {
      marker.setMap(null);
    });
    for (const route of routes) {
      let i = 0;
      for (const waypoint of route.waypoints) {
        const infowindow = new google.maps.InfoWindow({
          content: `<div>
            <p>${waypoint.placeName}</p>
            <p>${waypoint.placeFormattedAddress}</p>
            <p>${format(new Date(waypoint.arriveAtFrom), "dd.MM.yyyy HH:mm")}</p>
          </div>`,
          ariaLabel: `${i}-${waypoint.placeName}`,
        });

        const marker = new google.maps.Marker({
          position: {
            lat: waypoint.placeLatitude,
            lng: waypoint.placeLongitude,
        },
          label: `${i}`,
          map: map,
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
        console.log('marker', marker);
        prevMarkersRef.current.push(marker);
        i++;
      }
    }
  }, [
    routes
  ]);

  useEffect(() => {
    const colors = [teal, blue, red, green, orange];
    const colorsStr = ["teal", "blue","red","green","orange"];
    if (driverPath && driverPath.length > 0) {
      prevPolyline.current.forEach((line) => {
        line.setMap(null);
      });
      prevStartStopMarkers.current.forEach((marker) => {
        marker.setMap(null);
      });

      driverIds.forEach((driver, index) => {
        const points = driverPath.filter((item) => item.userId === driver.id);
        const parsedPoints = points.map((item) => ({ lat: item.coordinates.latitude, lng: item.coordinates.longitude }));
        addMarker(parsedPoints[0], map, 'Start', colorsStr[index]);
        addMarker(parsedPoints[parsedPoints.length - 1], map, 'Konec', colorsStr[index]);

        var lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        };
        const driverPolyline = new google.maps.Polyline({
          path: parsedPoints,
          icons: [{
            icon: lineSymbol,
            offset: '100%'
          }],
          geodesic: true,
          strokeColor: index >= colors.length ? colors[0][500] : colors[index][500],
          strokeOpacity: 1.0,
          strokeWeight: 3
        });
    
        driverPolyline.setMap(map);
        prevPolyline.current.push(driverPolyline);

        google.maps.event.addListener(driverPolyline, 'click', (h) => {
          var latlng = h.latLng;
          var needle = {
            minDistance: 999999,
            index: -1,
            latlng: null
          };
          driverPolyline.getPath().forEach((routePoint, index) => {
            var dist = google.maps.geometry.spherical.computeDistanceBetween(latlng, routePoint);
            if (dist < needle.minDistance) {
              needle.minDistance = dist;
              needle.index = index;
              needle.latlng = routePoint;
            }
          });
          const infowindow = new google.maps.InfoWindow({
            content: `
            <div>
            <p>${driver.firstName} ${driver.lastName}</p>
            <p>Lat: ${driverPath[needle.index].coordinates.latitude}, Lng: ${driverPath[needle.index].coordinates.longitude}</p>
            <p>Speed: ${(driverPath[needle.index].speed * 3.6).toFixed(2)} km/h</p>
            <p>Inserted At: ${format(new Date(driverPath[needle.index].insertedAt.seconds * 1000), "HH:mm")}</p>
            </div>
            `,
          });
          infowindow.setPosition(h.latLng);
          infowindow.open(map);
        });

      });
    } else {
      console.log('else');
    }
  }, [driverPath]);

  // useEffect(() => {
  //   if (JSON.stringify(currentPath.current) !== JSON.stringify(plannedPath)) {
  //     currentPath.current = plannedPath;
  //     drawDirectionRoute(plannedPath);
  //   }
  // }, [plannedPath]);

  // const drawDirectionRoute = async (waypoints) => {
  //   directionsRenderer.current.setMap(null);
  //   const { distance, duration, directions } = await calculateRoute(
  //     directionsService.current,
  //     waypoints,
  //     googleMapsApi.TravelMode.DRIVING
  //   );

  //   if (!directions) {
  //     directionsRenderer.current.setMap(null);
  //   } else {
  //     directionsRenderer.current.setMap(map);
  //     directionsRenderer.current.setDirections(directions);
  //   }
  // }

  return (
    <>
      <div
        style={{ height: "100%", width: "100%" }}
        ref={mapRef} />
    </>
  );
}

export default TrackingMap;