const CargoItemTemplateDimensions = {
  EUR: { width: 0.8, length: 1.2, height: undefined },
  RETURNABLE_PALLET: { width: 0.8, length: 1.2, height: undefined },
  US: { width: 1, length: 1.2, height: undefined },
  HALF_EUR: { width: 0.6, length: 0.8, height: undefined },
  LDM: { width: 2.4, length: 1, height: Infinity },
  LKW: { width: 2.4, length: 13.6, height: 2.7 },
  MEGA: { width: 2.4, length: 13.6, height: 3.0 }
};

type CargoItemTemplate = keyof typeof CargoItemTemplateDimensions;

interface CargoItem {
  description?: string;
  weight?: number;
  quantity: number;
  width?: number;
  length?: number;
  height?: number;
  volume?: number;
  template?: CargoItemTemplate;
}

enum CargoItemAction {
  IDLE = "IDLE",
  LOAD = "LOAD",
  UNLOAD = "UNLOAD",
}

interface CargoItemWithAction extends CargoItem {
  action: CargoItemAction;
}

interface Cargo {
  [id: string]: CargoItemWithAction;
}

export {
  Cargo,
  CargoItem,
  CargoItemAction,
  CargoItemWithAction,
  CargoItemTemplate,
  CargoItemTemplateDimensions
};
