import { parseISO } from "date-fns";

import { deserializeContactSnapshot } from "@cargoticcom/api-client";
import axios from "axios";
import {
  createResource,
  deleteResource,
  patchResource,
  readResource,
  updateResource,
} from "./http";

import { findOrThrow } from "./utility/functional";

import {
  formatDate,
  formatDateWithoutYear,
  formatHours,
  parseDuration,
} from "./utility/common";

import config from "./config";

const {
  api: { urlV2 },
} = config;

const ORDER_INDEX_YEAR_LENGTH = 2;
const ORDER_INDEX_USER_LENGTH = 2;
const ORDER_INDEX_ORDER_LENGTH = 4;

const deserializeUser = (data) => ({
  id: data.id,
  firstName: data.first_name,
  companyId: data.company_id,
  lastName: data.last_name,
  email: data.email,
  phoneNumber: data.phoneNumber,
  role: data.role,
  avatarUrl: data.avatar_url,
  permissions: data.permissions,
  company_created_at: data.company_creation,
  subscription: data.subscription
    ? {
      ...data.subscription,
      expiresAt: new Date(data.subscription.expiresAt),
    }
    : undefined,
  token: data.token,
});

const deserializeOrderStatistics = (data, period) => [
  {
    id: "Orders",
    period,
    data: data.orders.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      y: y.value,
    })),
  },
];

const deserializeShipmentsStatistics = (data, period) => [
  {
    id: "Shipments",
    period,
    data: data.shipments.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeInvoiceStatistics = (data, period, invoiceType) => [
  {
    id: invoiceType === "received" ? "Received invoices" : "Issued invoices",
    period,
    data: (invoiceType === "received"
      ? data.receivedInvoices
      : data.issuedInvoices
    ).map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeInvoiceSumStatistics = (data, period, invoiceType) => [
  {
    id:
      invoiceType === "received"
        ? "Received invoice sum"
        : "Issued invoice sum",
    period,
    data: (invoiceType === "received"
      ? data.receivedInvoiceSum
      : data.issuedInvoiceSum
    ).map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeOutcomingOrderSumStatistics = (data, period) => [
  {
    id: "Outcoming order sum",
    period,
    data: data.outcomingOrderSum.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeIncomingOrdersStatistics = (data, period) => [
  {
    id: "Incoming orders",
    period,
    data: data.incomingOrders.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeOutcomingOrdersStatistics = (data, period) => [
  {
    id: "Outcoming orders",
    period,
    data: data.outcomingOrders.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeCommissionStatistics = (data, period) => [
  {
    id: "Commission",
    period,
    data: data.commission.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeRevenueStatistics = (data, period) => [
  {
    id: "Revenue",
    period,
    data: data.revenue.map((y) => ({
      x:
        period === "hour"
          ? `${formatHours(Date.parse(y.date))}h`
          : period === undefined
            ? formatDate(new Date(y.date))
            : formatDateWithoutYear(new Date(y.date)),
      date: new Date(y.date),
      y: y.value,
    })),
  },
];

const deserializeCompanyStatistics = (data) => {
  const arr = [];

  const computeCommission = (commission, revenue) => {
    const result = commission / revenue;
    return !Number.isNaN(result)
      ? Number.isFinite(result)
        ? (result * 100).toFixed(2)
        : 100
      : 0;
  };

  if (data.totalShipments !== undefined) {
    arr.push({
      title: "statistics.caption.totalShipments",
      data: data.totalShipments,
      unit: null,
    });
  }
  if (data.incomingOrder.totalClients !== undefined) {
    arr.push({
      title: "statistics.caption.totalClients",
      data: data.incomingOrder.totalClients,
      unit: null,
    });
  }
  if (data.incomingOrder.totalRevenue !== undefined) {
    arr.push({
      title: "statistics.caption.totalRevenue",
      data: data.incomingOrder.totalRevenue,
      unit: "CZK",
    });
  }
  if (data.totalCommission !== undefined) {
    arr.push({
      title: "statistics.caption.totalCommission",
      data: data.totalCommission,
      alternative: `${computeCommission(
        data.totalCommission,
        data.incomingOrder.totalRevenue
      )}%`,
      unit: "CZK",
    });
  }
  if (data.incomingOrder.totalOrders !== undefined) {
    arr.push({
      title: "statistics.caption.totalIncomingOrders",
      data: data.incomingOrder.totalOrders,
      unit: null,
    });
  }
  if (data.outcomingOrder.totalOrders !== undefined) {
    arr.push({
      title: "statistics.caption.totalOutcomingOrders",
      data: data.outcomingOrder.totalOrders,
      unit: null,
    });
  }
  if (data.outcomingOrder.totalCarriers !== undefined) {
    arr.push({
      title: "statistics.caption.totalCarriers",
      data: data.outcomingOrder.totalCarriers,
      unit: null,
    });
  }
  if (data.outcomingOrder.totalRevenue !== undefined) {
    arr.push({
      title: "statistics.caption.totalOutcomingOrdersSum",
      data: data.outcomingOrder.totalRevenue,
      unit: "CZK",
    });
  }
  if (data.receivedInvoice.totalInvoices !== undefined) {
    arr.push({
      title: "statistics.caption.totalReceivedInvoices",
      data: data.receivedInvoice.totalInvoices,
      unit: null,
    });
  }

  if (data.receivedInvoice.totalSum !== undefined) {
    arr.push({
      title: "statistics.caption.totalReceivedInvoicesSum",
      data: data.receivedInvoice.totalSum,
      unit: "CZK",
    });
  }
  if (data.issuedInvoice.totalInvoices !== undefined) {
    arr.push({
      title: "statistics.caption.totalIssuedInvoices",
      data: data.issuedInvoice.totalInvoices,
      unit: null,
    });
  }

  if (data.issuedInvoice.totalSum !== undefined) {
    arr.push({
      title: "statistics.caption.totalIssuedInvoicesSum",
      data: data.issuedInvoice.totalSum,
      unit: "CZK",
    });
  }
  return arr;
};

const deserializeUserStatistics = (data) => ({
  totalShipments:
    data.totalShipments !== undefined
      ? {
        title: "statistics.caption.totalShipments",
        data: data.totalShipments,
        unit: null,
      }
      : undefined,
  totalClients:
    data.totalClients !== undefined
      ? {
        title: "statistics.caption.totalClients",
        data: data.totalClients,
        unit: null,
      }
      : undefined,
  totalDuration: data.totalDuration
    ? {
      title: "statistics.caption.totalDuration",
      data: data.totalDuration / 3600,
      unit: "h",
    }
    : undefined,
  totalDistance: data.totalDistance
    ? {
      title: "statistics.caption.totalDistance",
      data: data.totalDistance / 1000,
      unit: "km",
    }
    : undefined,
  totalRevenue:
    data.totalRevenue !== undefined
      ? {
        title: "statistics.caption.totalRevenue",
        data: data.totalRevenue,
        unit: "CZK",
      }
      : undefined,
  totalCommission:
    data.totalCommission !== undefined
      ? {
        title: "statistics.caption.totalCommission",
        alternative: `${!Number.isNaN(data.totalCommission / data.totalRevenue)
          ? ((data.totalCommission / data.totalRevenue) * 100).toFixed(2)
          : 0
          }%`,
        data: data.totalCommission,
        unit: "CZK",
      }
      : undefined,
});

const deserializeDriverStatistics = (data) => [
  {
    title: "statistics.caption.totalShipments",
    data: data.total_shipments,
    unit: null,
  },
  {
    title: "statistics.caption.totalDistance",
    data: data.total_distance,
    unit: "km",
  },
  {
    title: "statistics.caption.totalVolume",
    data: data.total_volume_item_m3,
    unit: "m3",
  },
];

const deserializeCustomerProfile = (data) => ({
  firstName: data.first_name,
  lastName: data.last_name,
  phoneNumber: data.phoneNumber,
  profileType: data.profile_type,
  avatarUrl: data.avatar_url,
});

const deserializeCompanyProfile = (data) => ({
  ic: data.ic,
  dic: data.dic,
  url: data.website,
  responsiblePerson: data.responsiblePerson,
  avatarUrl: data.avatarFileUrl,
  stampSignature: data.stampSignatureFileId
    ? {
      url: data.stampSignatureFileUrl,
      id: data.stampSignatureFileId,
      name: data.stampSignatureFileName,
      salt: data.stampSignatureFileSalt,
    }
    : undefined,
  phoneNumber: data.phoneNumber,
  email: data.email,
  companyName: data.name,
  address: data.headquarters?.formattedAddress,
  placeId: data.headquarters?.googleId,
  price: data.pricePerKm,
  bankAccountIBAN: data.bankAccountIban,
  bankAccountBBAN: data.bankAccountBban,
  tags: data.tags?.map(({ label, ...rest }) => ({
    ...rest,
    value: label,
  })),
  statisticsDueTo: data.statisticsDueTo,
  exchangeRateMode: data.exchangeRateMode,
  isPersonalEmailHidden: data.isPersonalEmailHidden,
});

const deserializeUserWithId = (data) => ({
  ...deserializeUser(data),
  id: data.id,
});

const deserializeUsersWithIds = (data) => data.map(deserializeUserWithId);

const deserializeContact = (data) => ({
  id: data.id,
  name: data.name,
  ic: data.ic,
  dic: data.dic,
  email: data.email,
  phoneNumber: data.phoneNumber,
  web: data.web,
  notes: data.notes,
  employees: data.employees,
  type: data.type,
  billingFormattedAddress: data.billingAddr,
  billingPlaceId: data.billingPlaceId,
  deliveryFormattedAddress: data.deliveryAddr,
  deliveryPlaceId: data.deliveryPlaceId,
  isAssigned: data.isAssigned,
  assignedCount: data.assignedCount,
  tags: data.tags,
  insurance: data.insurance ? new Date(data.insurance) : undefined,
  dueDays: data.dueDays,
  createdAt: new Date(data.createdAt),
  creator: data.creator,
  billingContact: data.billingContact,
  electronicalBilling: data.electronicalBilling,
});

const deserializeOverviewOrder = (data) => ({
  indexNumber: data.indexNumber,
  id: data.id,
  isDisabled: data.isDisabled,
  creatorName: data.createdBy,
  description: data.description,
  priceCustomer: data.customerLocalPrice,
  priceCarrier: data.carrierLocalPrice,
  priceCommission: data.commissionNativePrice,
  customerPriceCurrency: data.customerLocalCurrency,
  carrierPriceCurrency: data.carrierLocalCurrency,
  commissionPriceCurrency: data.commissionLocalCurrency,
  customerName: data.customerName,
  carrierName: data.carrierName,
  deliveryFrom: parseISO(data.deliveryAtStart),
  deliveryTo: parseISO(data.deliveryAtStop),
  pickupFrom: parseISO(data.pickupAtStart),
  pickupTo: parseISO(data.pickupAtStop),
  fromPlaceId: data.originPlaceId,
  toPlaceId: data.destPlaceId,
  addressFrom: data.originAddr,
  addressTo: data.destAddr,
  items: data.items,
  dateCreated: parseISO(data.createdAt),
  dateUpdated: data.updatedAt ? parseISO(data.updatedAt) : null,
  hasInvoices: data.hasInvoice,
});

const deserializeShipment = (data) => ({
  name: data.name,
  id: data.id,
  idJourney: data.id_journey,
  origin: data.origin,
  destinationLat: data.destination_lat,
  destinationLong: data.destination_long,
  originLat: data.origin_lat,
  originLong: data.origin_long,
  destination: data.destination,
  duration: data.duration,
  distance: data.distance,
  pickupTime: data.pickup_time,
  deliveryTime: data.estimated_delivery_time,
  estDeliveryTime: data.estimated_delivery_time,
  price: data.price,
  customerId: data.customer_contact_id,
  driverId: data.id_driver,
  vehicleId: data.id_vehicle,
  phoneSender: data.sender_phone,
  emailSender: data.sender_email,
  phoneReceiver: data.receiver_phone,
  emailReceiver: data.receiver_email,
  dateCreated: Date.parse(data.date_created),
  dateUpdated: Date.parse(data.date_updated),
  shipmentDescription: data.description,
});

const deserializeCar = (data) => ({
  ...data,
  id: data.id,
  manufacturer: data.manufacturer,
  model: data.model,
  isAssigned: data.isAssigned,
  licensePlate: data.licensePlate,
  emissionClass: data.emissionClass,
  vin: data.vin,
  avatarUrl: data.avatarUrl,
});

const deserializeDriver = (data) => ({
  id: data.id,
  firstName: data.first_name,
  lastName: data.last_name,
  name: data.name,
  email: data.email,
  avatarUrl: data.avatar_url,
  isAssigned: data.isAssigned,
});

const deserializeCustomer = (data) => ({
  id: data.id,
  name: data.name,
  type: data.type,
});

const deserializeDispatcher = (data) => ({
  id: data.id,
  email: data.email,
  name: data.name,
  phoneNumber: data.phoneNumber,
  createdAt: data.createdAt,
  avatarUrl: data.avatarUrl,
});

const deserializeContacts = (data) => data.map(deserializeContact);

const deserializeOrder = (data) => ({
  id: data.id,
  departureAddress: data.addr_from,
  departureAddressId: data.place_id_from,
  departureDateTimeFrom: parseISO(data.dt_pickup_from),
  departureDateTimeTo: data.dt_pickup_to
    ? parseISO(data.dt_pickup_to)
    : parseISO(data.dt_pickup_from),
  departureDateTimeToFixed: data.dt_pickup_to == null,
  deliveryAddress: data.addr_to,
  deliveryAddressId: data.place_id_to,
  deliveryDateTimeFrom: parseISO(data.dt_delivery_from),
  deliveryDateTimeTo: data.dt_delivery_to
    ? parseISO(data.dt_delivery_to)
    : parseISO(data.dt_delivery_from),
  deliveryDateTimeToFixed: data.dt_delivery_to == null,
  carrierId: data.carrier_contact_id,
  customerId: data.customer_contact_id,
  carrierPrice: data.carrier_price_local_value,
  driverContact: data.contact_driver,
  carrierPriceCurrency: data.carrier_price_local_currency,
  customerPrice: data.customer_price_local_value,
  customerPriceCurrency: data.customer_price_local_currency,
  loadingContact: data.contact_pickup,
  unloadingContact: data.contact_delivery,
  note: data.description,
  indexNumber: data.index_number,
  dueDate: data.payment_duedate,
  withDPH: data.with_dph,
  dateCreated: data.date_created,
  dateUpdated: data.date_updated,
});

const deserializeOrderItem = (data) => ({
  id: data.id,
  description: data.description || "",
  quantity: data.amount,
  length: data.length_cm,
  width: data.width_cm,
  height: data.height_cm,
  weight: data.weight_kg,
  stackability: data.stackability === 1,
  unit: data.unit,
});

const deserializeOrderIndex = (index) => ({
  year: parseInt(index.slice(0, ORDER_INDEX_YEAR_LENGTH)),
  user: parseInt(
    index.slice(
      ORDER_INDEX_YEAR_LENGTH,
      ORDER_INDEX_YEAR_LENGTH + ORDER_INDEX_USER_LENGTH
    )
  ),
  order: parseInt(index.slice(-ORDER_INDEX_ORDER_LENGTH)),
});

const stringifyOrderIndex = ({ year, user, order }) => ({
  year: year.toString().padStart(ORDER_INDEX_YEAR_LENGTH, "0"),
  user: user.toString().padStart(ORDER_INDEX_USER_LENGTH, "0"),
  order: order.toString().padStart(ORDER_INDEX_ORDER_LENGTH, "0"),
});

const shipmentParseDates = ({
  journey: { waypoints, ...restJourney },
  paidAt,
  ...rest
}) => {
  const updatedPoints = waypoints.map((point) => ({
    ...point,
    arriveAtFrom: new Date(point.arriveAtFrom),
    arriveAtTo: point.arriveAtTo ? new Date(point.arriveAtTo) : undefined,
  }));

  return {
    ...rest,
    journey: {
      ...restJourney,
      waypoints: updatedPoints,
    },
    paidAt: paidAt ? new Date(paidAt) : undefined,
  };
};

const createOrder = (
  from,
  to,
  departureDateTimeFrom,
  departureDateTimeTo,
  deliveryDateTimeFrom,
  deliveryDateTimeTo,
  carrierId,
  customerId,
  carrierPrice,
  carrierPriceCurrency,
  customerPrice,
  customerPriceCurrency,
  commission,
  commissionCurrency,
  loadingContact,
  unloadingContact,
  dueDate,
  note,
  distance,
  duration,
  placeIdFrom,
  placeIdTo,
  index,
  name,
  withDPH,
  driverContact
) =>
  createResource("orders", {
    carrierPriceCurrency,
    customerPriceCurrency,
    addr_from: from,
    addr_to: to,
    dt_pickup_from: departureDateTimeFrom,
    dt_pickup_to: departureDateTimeTo,
    dt_delivery_from: deliveryDateTimeFrom,
    dt_delivery_to: deliveryDateTimeTo,
    carrier_contact_id: carrierId,
    customer_contact_id: customerId,
    price_carrier: carrierPrice,
    price_customer: customerPrice,
    contact_pickup: loadingContact,
    contact_delivery: unloadingContact,
    description: note,
    place_id_from: placeIdFrom,
    place_id_to: placeIdTo,
    index_number: index,
    distance,
    duration,
    payment_duedate: dueDate,
    with_dph: withDPH,
    contact_driver: driverContact,
    commissionCurrency,
    price_commission: commission,
  }).then(({ data }) => data.id);

const createShipment = ({
  name,
  customerId,
  driverId,
  vehicleId,
  phoneSender,
  emailSender,
  phoneReceiver,
  emailReceiver,
  origin,
  destination,
  originLat,
  originLng,
  originPlaceId,
  destinationLat,
  destinationLng,
  destinationPlaceId,
  pickupTime,
  deliveryTime,
  duration,
  distance,
  price,
  shipmentDescription,
}) =>
  createResource("shipments", {
    name,
    customer_contact_id: customerId,
    id_driver: driverId,
    id_vehicle: vehicleId,
    sender_phone: phoneSender,
    sender_email: emailSender,
    receiver_phone: phoneReceiver,
    receiver_email: emailReceiver,
    origin,
    destination,
    origin_lat: originLat,
    origin_lng: originLng,
    origin_place_id: originPlaceId,
    destination_lat: destinationLat,
    destination_lng: destinationLng,
    destination_place_id: destinationPlaceId,
    pickup_time: pickupTime,
    estimated_delivery_time: deliveryTime,
    duration,
    distance,
    price,
    description: shipmentDescription,
  }).then(({ data }) => data);

const createShipmentItem = (
  id,
  amount,
  description,
  length,
  height,
  width,
  weight
) => {
  createResource(`shipments/${id}/items`, {
    amount,
    width_cm: width,
    height_cm: height,
    length_cm: length,
    weight_kg: weight,
  });
};

const createOrderItems = (orderId, items) =>
  createResource(
    `orders/${orderId}/items`,
    items.map(
      ({
        quantity,
        width,
        height,
        length,
        weight,
        description,
        stackability,
        unit,
      }) => ({
        amount: quantity,
        width_cm: width,
        height_cm: height,
        length_cm: length,
        weight_kg: weight,
        description,
        stackability,
        unit,
      })
    )
  );

const createOrdersQuery = (args) =>
  createResource("orders/query", args).then((res) => ({
    count: res.data.count,
    orders: res.data.orders.map(deserializeOverviewOrder),
  }));

// const createShipmentsQuery = (args) => createResource("newShipment", args).then(res => ({ count: res.data.count, shipments: res.data.deserializedShipments }));
const createShipmentsQuery = (args) =>
  createResource("new-shipments/query", args).then((res) => ({
    count: res.data.count,
    shipments: res.data.shipments.map((shipment) =>
      shipmentParseDates(shipment)
    ),
  }));

const createShipmentBoardQuery = (args) =>
  createResource("new-shipments/board", args).then(({ data }) => ({
    count: data.count,
    matches: {
      QUEUE: data.QUEUE
        ? {
          count: data.QUEUE.count,
          totalWarnings: data.QUEUE.totalWarnings,
          totalErrors: data.QUEUE.totalErrors,
          shipments: data.QUEUE.shipments.map((shipment) =>
            shipmentParseDates(shipment)
          ),
        }
        : {},
      READY: data.READY
        ? {
          count: data.READY.count,
          totalWarnings: data.READY.totalWarnings,
          totalErrors: data.READY.totalErrors,
          shipments: data.READY.shipments.map((shipment) =>
            shipmentParseDates(shipment)
          ),
        }
        : {},
      IN_PROGRESS: data.IN_PROGRESS
        ? {
          count: data.IN_PROGRESS.count,
          totalWarnings: data.IN_PROGRESS.totalWarnings,
          totalErrors: data.IN_PROGRESS.totalErrors,
          shipments: data.IN_PROGRESS.shipments.map((shipment) =>
            shipmentParseDates(shipment)
          ),
        }
        : {},
      DONE: data.DONE
        ? {
          count: data.DONE.count,
          totalWarnings: data.DONE.totalWarnings,
          totalErrors: data.DONE.totalErrors,
          shipments: data.DONE.shipments.map((shipment) =>
            shipmentParseDates(shipment)
          ),
        }
        : {},
    },
  }));

const createIncomingOrderBoardQuery = (args) =>
  createResource("incoming-order/board", args).then(({ data }) => {
    const object = {};
    Object.keys(data).forEach(
      (e) =>
      (object[e] = {
        ...data[e],
        matches: data[e].matches.map((shipment) =>
          shipmentParseDates(shipment)
        ),
      })
    );
    return object;
  });

const updateShipmentState = (shipmentId, newState) =>
  updateResource(`new-shipments/${shipmentId}/state`, {
    state: newState,
  }).then(({ data }) => data);

const updateIncomingOrderState = (orderId, state) =>
  patchResource(`incoming-order/${orderId}/state`, { state }).then(
    ({ data }) => data
  );

const updateOutcomingOrderState = (orderId, state) =>
  patchResource(`outcoming-order/${orderId}/state`, { state }).then(
    ({ data }) => data
  );

const createVehiclesQuery = (args) =>
  createResource("vehicles/query", args).then((res) => ({
    count: res.data.count,
    vehicles: res.data.vehicles.map(deserializeCar),
  }));

const readVehicles = () =>
  readResource("vehicles").then((vehicles) =>
    vehicles.data.map(deserializeCar)
  );

const readDrivers = () =>
  readResource("users/drivers").then((drivers) =>
    drivers.data.map(deserializeDriver)
  );

const createDriversQuery = (args) =>
  createResource("users/drivers/query", args).then((res) => ({
    count: res.data.count,
    drivers: res.data.users.map(deserializeDriver),
  }));

const readCustomers = () =>
  readResource("contacts").then((customers) =>
    customers.data
      .map(deserializeCustomer)
      .filter(({ type }) => type === "customer")
  );

const readCarriers = () =>
  readResource("contacts").then((carriers) =>
    carriers.data
      .map(deserializeCustomer)
      .filter(({ type }) => type === "carrier")
  );

const createDispatchersQuery = (args) =>
  createResource("users/dispatchers/query", args).then((res) => ({
    count: res.data.count,
    dispatchers: res.data.users.map(deserializeDispatcher),
  }));

const deleteOrder = (id) => deleteResource(`orders/${id}`);

const deleteShipment = (id) => deleteResource(`new-shipments/${id}`);

const deleteIncomingOrder = (id) => deleteResource(`incoming-order/${id}`);

const deleteVehicle = (id) => deleteResource(`vehicles/${id}`);

const deleteShipmentItem = (idShipment, idItem) =>
  deleteResource(`shipments/${idShipment}/items/${idItem}`);

const deleteUser = (id) => deleteResource(`users/delete/${id}`);

const readShipment = (id) =>
  readResource(`new-shipments/${id}`).then(
    ({ data: { shipment } }) => shipment
  );

const readShipments = () =>
  readResource("shipments").then((shipments) =>
    shipments.data.map(deserializeShipment)
  );

const createSignIn = (token, remember) =>
  createResource("auth/signin", {
    token,
    remember,
  }).then(({ data }) => deserializeUser(data));

const createResetPassword = (email) =>
  createResource("auth/reset-password", {
    email,
  }).then(({ data }) => data);

const createResendVerificationMail = (email) =>
  createResource("auth/resend-verification", {
    email,
  });

const createSignUp = ({
  firstName,
  lastName,
  email,
  companyName,
  ic,
  dic,
  tariffType,
  tariffCountry,
  countUsers,
  isSubscriber,
  token,
}) =>
  createResource("auth/signup", {
    firstName,
    lastName,
    email,
    companyName,
    ic,
    dic,
    tariffType,
    tariffCountry,
    users: countUsers,
    isSubscriber,
    token,
  });

const createDispatcher = (firstName, lastName, email, phoneNumber) =>
  createResource("users/dispatchers", {
    first_name: firstName,
    last_name: lastName,
    email,
    phoneNumber,
  }).then(({ data }) => data.idDispatcher);

const readDispatchers = () =>
  readResource("users/dispatchers").then(({ data }) =>
    deserializeUsersWithIds(data)
  );

const readDriverStatistics = (id, start, end) =>
  readResource(`statistics/driver/${id}`, {
    fromDate: start,
    toDate: end,
  }).then(({ data }) => deserializeDriverStatistics(data));

const readMe = () =>
  readResource("users/getSession").then(({ data }) => deserializeUser(data));

const updateDispatcher = (id, firstName, lastName) =>
  updateResource(`users/dispatchers/${id}`, {
    first_name: firstName,
    last_name: lastName,
  });

const deleteDispatcher = (id) => deleteResource(`users/dispatchers/${id}`);

const createDriver = (firstName, lastName, email, phoneNumber) =>
  createResource("users/drivers", {
    first_name: firstName,
    last_name: lastName,
    email,
    phoneNumber,
  }).then((response) => response.data.idDriver);

const updateDriver = (id, email, firstName, lastName) =>
  updateResource(`users/drivers/${id}`, {
    email,
    first_name: firstName,
    last_name: lastName,
  });

const deleteDriver = (id) =>
  updateResource(`users/drivers/${id}`, {
    deleted: 1,
  });

const createPlace = (alias, address) =>
  createResource("places", {
    ...address,
    alias,
  });

const createContact = ({
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  employees,
  type,
  billingPlace,
  tags,
  insuranceExpiresAt,
  paymentDueDays,
  mailingPlace,
  billingContact,
  isBilledOnline,
  isPrivate,
}) =>
  createResource("contacts", {
    companyName,
    ic,
    dic,
    email,
    phoneNumber,
    website,
    notes,
    employees,
    type,
    insuranceExpiresAt,
    paymentDueDays,
    billingPlace,
    mailingPlace,
    tags,
    isBilledOnline,
    billingContact,
    isPrivate,
  }).then(({ data: { id } }) => id);

const readCustomerProfile = () =>
  readResource("customer-profile").then(({ data }) =>
    deserializeCustomerProfile(data)
  );

const createSignOut = () =>
  createResource("auth/signout").then(({ data }) => data);

const updateRole = (args) => updateResource("users/role", args);

const updateOrdersByImportingFromRinkai = (planId, alias) =>
  updateResource("rinkai/import-from-rinkai", {
    planId,
    alias,
  });

const deleteContact = (id) => deleteResource(`contacts/${id}`);

const deletePlace = (id) => deleteResource(`places/${id}`);

const deleteMe = () => deleteResource("users/deleteAccount");

const deleteCompany = () => deleteResource("company-profile/delete");

const readContacts = () =>
  readResource("contacts").then(({ data }) => deserializeContacts(data));
const createContactsQuery = (args) =>
  createResource("contacts/query", args).then((res) => ({
    count: res.data.total,
    contacts: res.data.matches.map(deserializeContactSnapshot),
  }));

const createPlacesQuery = (args) =>
  createResource("places/query", args).then((res) => ({
    count: res.data.count,
    places: res.data.places.map(deserializePlace),
  }));

const createGetBoxes = (args) =>
  createResource("incoming-order/box", args).then(({ data }) => data);

const downloadPDFOrder = (id) =>
  readResource(`pdf/order/${id}`).then(({ data }) => console.log(data));

const readUserHighlights = (from, to) =>
  readResource("statistics/me/highlights", {
    fromDate: from,
    toDate: to,
  }).then(({ data }) => data);

const readCompanyHighlights = (from, to) =>
  readResource("statistics/company/highlights", {
    fromDate: from,
    toDate: to,
  }).then(({ data }) => data);

const readCompanyIncomingOrderHighlights = (
  from,
  to,
  fromPrevious,
  toPrevious
) =>
  readResource("statistics/company/highlights/incoming-order", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readCompanyOutcomingOrderHighlights = (
  from,
  to,
  fromPrevious,
  toPrevious
) =>
  readResource("statistics/company/highlights/outcoming-order", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);
const readUserOutcomingOrderHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/user/highlights/outcoming-order", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readUserIncomingOrderHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/user/highlights/incoming-order", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readCompanyShipmentHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/company/highlights/shipment", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readUserShipmentHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/user/highlights/shipment", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readCompanyRevenueHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/company/highlights/revenue", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readUserRevenueHighlights = (from, to, fromPrevious, toPrevious) =>
  readResource("statistics/user/highlights/revenue", {
    fromDate: from,
    toDate: to,
    fromPreviousDate: fromPrevious,
    toPreviousDate: toPrevious,
  }).then(({ data }) => data);

const readNews = () => readResource("blog").then(({ data: { news } }) => news);

const readMyStatistics = (filter, from, to) =>
  createResource("statistics/me", {
    fromDate: from,
    toDate: to,
    filter,
  }).then(({ data }) => deserializeCompanyStatistics(data));

const readUserKpiStatistics = (filter, userId, from, to) =>
  createResource(`statistics/user/${userId}/kpi`, {
    fromDate: from,
    toDate: to,
    filter,
  }).then(({ data }) => deserializeUserStatistics(data));

const readUserRevenueStatistics = (filter, userId, from, to, period) =>
  createResource(`statistics/user/${userId}/revenue`, {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeRevenueStatistics(data, period));

const readUserCommissionStatistics = (filter, userId, from, to, period) =>
  createResource(`statistics/user/${userId}/commission`, {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeCommissionStatistics(data, period));

const readUserShipmentsStatistics = (filter, userId, from, to, period) =>
  createResource(`statistics/user/${userId}/shipments`, {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeShipmentsStatistics(data, period));

const readLastOrderIndex = () =>
  readResource("orders/lastOrderNumber").then(({ data: { lastNumber } }) =>
    deserializeOrderIndex(lastNumber)
  );

const readUserIndexNumber = () =>
  readResource("users/getUserIndexNumber").then(({ data }) => data.indexNumber);

const readNextOrderIndex = async () => {
  const now = new Date();
  const year = parseInt(
    now.getFullYear().toString().slice(-ORDER_INDEX_YEAR_LENGTH)
  );

  const user = await readUserIndexNumber();

  try {
    const { year: lastYear, order: lastOrder } = await readLastOrderIndex();
    const order = year === lastYear ? lastOrder + 1 : 1;

    return {
      year,
      user,
      order,
    };
  } catch (err) {
    if (err.response && err.response.status !== 400) {
      throw err;
    }

    return {
      year,
      user,
      order: 1,
    };
  }
};

const readCompanyStatistics = (filter, from, to) =>
  createResource("statistics/company", {
    fromDate: from,
    toDate: to,
    filter,
  }).then(({ data }) => deserializeCompanyStatistics(data));

const readCompanyRevenueStatistics = (filter, from, to, period) =>
  createResource("statistics/company/revenue", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeRevenueStatistics(data, period));

const readMyRevenueStatistics = (filter, from, to, period) =>
  createResource("statistics/me/revenue", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeRevenueStatistics(data, period));

const readMyCommissionStatistics = (filter, from, to, period) =>
  createResource("statistics/me/commission", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeCommissionStatistics(data, period));

const readCompanyCommissionStatistics = (filter, from, to, period) =>
  createResource("statistics/company/commission", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeCommissionStatistics(data, period));

const readCompanyShipmentStatistics = (filter, from, to, period) =>
  createResource("statistics/company/shipments", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeShipmentsStatistics(data, period));

const readMyShipmentStatistics = (filter, from, to, period) =>
  createResource("statistics/me/shipments", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeShipmentsStatistics(data, period));

const readCompanyInvoiceStatistics = (filter, from, to, period, invoiceType) =>
  createResource(`statistics/invoices/${invoiceType}`, {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) =>
    deserializeInvoiceStatistics(data, period, invoiceType)
  );

const readCompanyInvoiceSumStatistics = (
  filter,
  from,
  to,
  period,
  invoiceType
) =>
  createResource(`statistics/invoices/${invoiceType}/sum`, {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) =>
    deserializeInvoiceSumStatistics(data, period, invoiceType)
  );

const readCompanyIncomingOrdersStatistics = (filter, from, to, period) =>
  createResource("statistics/incoming-orders", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeIncomingOrdersStatistics(data, period));

const readCompanyOutcomingOrdersStatistics = (filter, from, to, period) =>
  createResource("statistics/outcoming-orders", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeOutcomingOrdersStatistics(data, period));

const readOutcomingOrderDataByToken = (token) =>
  axios
    .get(`${urlV2}/outcoming-order/documents-submission/${token}`)
    .then(({ data }) => data);

const updateOutcomingOrderDocuments = (
  token,
  { termsDocuments, additionalDocuments, email }
) =>
  axios
    .post(`${urlV2}/outcoming-order/files/${token}`, {
      termsDocuments,
      additionalDocuments,
      email
    })
    .then(({ data }) => data);

const readCompanyOutcomingOrderSumStatistics = (filter, from, to, period) =>
  createResource("statistics/outcoming-orders/sum", {
    fromDate: from,
    toDate: to,
    period,
    filter,
  }).then(({ data }) => deserializeOutcomingOrderSumStatistics(data, period));

const updateCustomerProfile = (firstName, lastName, phoneNumber, avatar) =>
  updateResource("customer-profile", {
    first_name: firstName,
    last_name: lastName,
    phoneNumber,
    avatar,
  });

const updateCustomerProfileAvatar = async (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: { avatar_url: avatarUrl },
  } = await updateResource("customer-profile", formData);

  return avatarUrl;
};

const updateCompanyProfileAvatar = async (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: { avatarUrl },
  } = await updateResource("company-profile", formData);

  return avatarUrl;
};

const updateVehicleAvatar = async (id, file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: { avatarUrl },
  } = await updateResource(`vehicles/${id}`, formData);

  return avatarUrl;
};

const updateCompanyProfile = ({
  ic,
  dic,
  url,
  companyName,
  phoneNumber,
  email,
  address,
  placeId,
  responsiblePerson,
  price,
  tags,
  statisticsDueTo,
  isPersonalEmailHidden,
}) =>
  updateResource("company-profile", {
    ic,
    dic,
    website: url,
    name: companyName,
    email,
    phoneNumber,
    headquarters: {
      formattedAddress: address,
      googleId: placeId,
    },
    responsiblePerson,
    pricePerKm: price,
    tags,
    statisticsDueTo,
    isPersonalEmailHidden,
  });

const readUserTerms = () => readResource("terms/user").then(({ data }) => data);

const readCompanyTerms = () =>
  readResource("terms/company").then(({ data }) => data);

const createTerms = (terms, isCompany) =>
  createResource("terms", {
    terms,
    isCompany,
  }).then(({ data }) => data);

const updateTerms = (termsId, terms) =>
  updateResource(`terms/${termsId}`, {
    terms,
  });

const deleteTerms = (termsId) => deleteResource(`terms/${termsId}`);

const updatePlace = (alias, address, placeId) =>
  updateResource(`places/${placeId}`, {
    ...address,
    alias,
  });

const updateContact = ({
  id,
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  billingPlace,
  mailingPlace,
  type,
  tags,
  insuranceExpiresAt,
  paymentDueDays,
  isBilledOnline,
  isPrivate,
  billingContact,
}) =>
  updateResource(`contacts/${id}`, {
    companyName,
    ic,
    dic,
    email,
    phoneNumber,
    website,
    notes,
    billingPlace,
    mailingPlace,
    type,
    tags,
    insuranceExpiresAt,
    paymentDueDays,
    isBilledOnline,
    billingContact,
    isPrivate,
  });

const deleteEmployee = (contactId, id) =>
  deleteResource(`contacts/${contactId}/employees/${id}`);

const updateEmployee = (contactId, id, name, email, phoneNumber) =>
  updateResource(`contacts/${contactId}/employees/${id}`, {
    name,
    email,
    phoneNumber,
  });

const createEmployee = (contactId, name, email, phoneNumber) =>
  createResource(`contacts/${contactId}/employees`, {
    name,
    email,
    phoneNumber,
  });

/*
const updateEmployee = (
  id,
  name,
  email,
  phoneNumber
) => updateResource(`contacts/employee/${id}`,{
  name,
  email,
  phoneNumber
}) */

const createCompanyProfile = (
  ic,
  dic,
  url,
  companyName,
  email,
  address,
  placeId
) =>
  createResource("company-profile", {
    ic,
    dic,
    url,
    company_name: companyName,
    email,
    address,
    place_id: placeId,
    tags: [],
  });

const createVehicle = (
  vendor,
  name,
  spz,
  vin,
  fuel,
  height,
  width,
  length,
  maxLoad,
  date
) =>
  createResource("vehicles", {
    vendor,
    name,
    spz,
    vin,
    fuel,
    cargoAreaHeight: height,
    cargoAreaWidth: width,
    cargoAreaLength: length,
    cargoAreaLoad: maxLoad,
    manufacturedAt: date,
  }).then(({ data }) => data);

const inviteUser = ({ firstName, lastName, email, roleId, phoneNumber }) =>
  createResource("users/invite", {
    firstName,
    lastName,
    email,
    roleId,
    phoneNumber,
  });

const createWarehouseman = ({ userId, warehouseId }) =>
  createResource(`warehouses/${warehouseId}/warehousemen`, {
    userId,
  });

const reinviteUser = ({ email }) =>
  createResource("auth/reinvite", {
    email,
  });

const deserializeInvoices = (data) => ({
  id: data.id,
  datePaid: data.date_paid,
  isPaid: data.paid,
  price: data.price,
  currency: data.price_currency,
  createdDate: data.date_created,
});

const deserializeInvoice = (data) => ({
  bankAccountId: data.bank_account_id,
  companyId: data.company_id,
  createdAt: data.created_at,
  currency: data.currency,
  customerId: data.customer_id,
  duedate: data.duedate,
  duzp: data.duzp,
  editorId: data.editor_id,
  expiredAt: data.expired_at,
  externalRefrence: data.external_reference,
  id: data.id,
  indexNumber: data.index_number,
  isDeleted: data.is_deleted,
  items: data.items.map((item) => ({
    count: item.count,
    description: item.description,
    dph: item.dph,
    dphInclusion: item.dph_inclusion,
    dphType: item.dph_type,
    id: item.id,
    shipmentInvoiceSnapshotId: item.shipment_invoice_snapshot_id,
    unit: item.unit,
    unitPrice: item.unit_price,
  })),
  note: data.note,
  paidAt: data.paid_at,
  paymentMethod: data.payment_method,
  price: data.price,
  releaseDate: data.release_date,
  shipmentInvoiceId: data.shipment_invoice_id,
  supplierId: data.supplier_id,
  type: data.type,
  vs: data.vs,
});

const deserializeShipmentInvoiceItem = (data) => ({
  count: data.count,
  description: data.description,
  dph: data.dph,
  dphInclusion: data.dph_inclusion,
  dphType: data.dph_type,
  id: data.id,
  shipmentInvoiceSnapshotId: data.shipment_invoice_snapshot_id,
  unit: data.unit,
  unitPrice: data.unit_price,
});

const deserializeShipmentInvoice = (data) => ({
  bankAccountId: data.bankAccountId,
  companyId: data.company_id,
  createdAt: data.createdAt,
  currency: data.currency,
  customer: data.customer,
  dueDate: data.dueDate,
  duzp: data.duzp,
  editorId: data.editorId,
  externalReference: data.externalReference,
  id: data.id,
  indexNumber: data.indexNumber,
  isDeleted: data.isDeleted,
  items: data.items?.map(deserializeShipmentInvoiceItem),
  note: data.note,
  paid: data.paid,
  paidAt: data.paidAt,
  paymentMethod: data.paymentMethod,
  price: data.price,
  releaseDate: data.releaseDate,
  snapshotId: data.snapshotId,
  supplier: data.supplier,
  type: data.type,
  vs: data.vs,
});

const createShipmentInvoice = (body) =>
  createResource("invoices/generateShipmentInvoice", body).then(
    ({ data }) => data
  );

const updateShipmentInvoice = (shipmentInvoiceId, body) =>
  updateResource(`invoices/${shipmentInvoiceId}`, body);

const getNextShipmentInvoiceNumber = (invoiceType) =>
  readResource("invoices/index-number", { type: invoiceType }).then(
    ({ data }) => data.nextInvoiceNumber
  );

const getIncomingOrderById = (id) =>
  readResource(`incoming-order/${id}`).then(({ data }) => data.incomingOrder);

const getWarehouseOrderById = (id) =>
  readResource(`warehouse-order/${id}`).then(({ data }) => data.incomingOrder);

const getOutcomingOrderById = (id) =>
  readResource(`outcoming-order/${id}`).then(({ data }) => data.outcomingOrder);

const getShipmentInvoice = (shipmentInvoiceId) =>
  readResource(`invoices/shipmentInvoiceEntity/${shipmentInvoiceId}`).then(
    ({ data }) => deserializeShipmentInvoice(data)
  );

const getInvoices = (filter) =>
  createResource("invoices/match", filter).then(({ data }) => {
    return data;
  });

const deleteInvoice = (id) =>
  deleteResource(`invoices/${id}`).then(({ data }) => data);

const setInvoicePaidAt = (id, data) =>
  updateResource(`invoices/paidAt/${id}`, data).then(({ data }) => data);

const downloadPDFInvoice = (id) =>
  readResource(`invoices/shipmentInvoice/${id}`).then(({ data }) =>
    console.log(data)
  );

const deserializePeer = (data) => ({
  id: data.id,
  name: `${data.first_name} ${data.last_name}`,
  isOnline: data.isOnline,
  unreadMessages: data.unreadMessages,
  role: data.role,
  lastMessage: data.last_message,
  avatarUrl: data.avatar_url,
});

const deserializeTrack = (data) => ({
  id: data.id,
  shipmentId: data.shipmentId,
  lat: Number(data.gpsLatitude),
  lng: Number(data.gpsLongitude),
  speed: data.speed,
  createdAt: parseISO(data.createdAt),
});

const deserializeTariff = (data) => ({
  country: data.tariff_country,
  currency: data.price_currency,
  customerId: data.id_customer,
  startTime: data.effective_starttime,
  endTime: data.effective_endtime,
  role: data.tariff_type,
  billingPeriod: data.plan,
  tariffType: data.tariff_code,
  durationDays: data.days,
  price: data.price,
});

const readCompanyProfile = (language) =>
  readResource(`company-profile?language=${language}`).then(({ data }) =>
    deserializeCompanyProfile(data)
  );

const patchCompanyProfile = (company) =>
  patchResource("company-profile", company).then(({ data }) => data);

const readExchangeRate = ({ sourceCurrency, targetCurrency }) =>
  readResource("exchange", { sourceCurrency, targetCurrency }).then(
    ({ data: { created_at, id, rate, source_currency, target_currency } }) => ({
      id,
      createdAt: created_at,
      targetCurrency: target_currency,
      sourceCurrency: source_currency,
      rate,
    })
  );

const readCurrentExchangeRates = (baseCurrency) =>
  readResource("exchange", { targetCurrency: baseCurrency }).then(({ data }) =>
    data.map(({ created_at, id, rate, source_currency, target_currency }) => ({
      id,
      createdAt: created_at,
      targetCurrency: target_currency,
      sourceCurrency: source_currency,
      rate,
    }))
  );

const createPlaceInput = ({ input, isFound }) =>
  createResource("analytics/shipment/place-input", {
    input,
    isFound,
  });

const readOrder = (id) =>
  readResource(`orders/${id}`).then(({ data: [first] }) =>
    deserializeOrder(first)
  );

const readOrderByIndexNumber = (indexNumberOrder) =>
  readResource(`orders/orderByIndexNumber/${indexNumberOrder}`).then(
    ({ data }) => deserializeOrder(data)
  );

const readOrderItems = (orderId) =>
  readResource(`orders/${orderId}/items`).then(({ data }) =>
    data.map(deserializeOrderItem)
  );

const readOrderAudits = (orderId) =>
  readResource(`orders/${orderId}/audits`).then(({ data }) => data);

const readShipmentItems = (shipmentId) =>
  readResource(`shipments/${shipmentId}/items`).then(({ data }) =>
    data.map(deserializeOrderItem)
  );

const readContact = (id) =>
  readContacts().then((contacts) =>
    findOrThrow(contacts, ({ id: other }) => other === id)
  );

const readInvoices = () =>
  readResource("invoices").then(({ data }) => data.map(deserializeInvoices));

const readTracks = () =>
  readResource("track").then((response) =>
    response.data.tracks.map(deserializeTrack)
  );

const updateOrder = (
  id,
  from,
  to,
  departureDateTimeFrom,
  departureDateTimeTo,
  deliveryDateTimeFrom,
  deliveryDateTimeTo,
  carrierId,
  customerId,
  carrierPrice,
  carrierPriceCurrency,
  customerPrice,
  customerPriceCurrency,
  commission,
  commissionCurrency,
  loadingContact,
  unloadingContact,
  dueDate,
  note,
  distance,
  duration,
  placeIdFrom,
  placeIdTo,
  index,
  name,
  withDPH,
  conditions,
  driverContact
) =>
  updateResource(`orders/${id}`, {
    carrierPriceCurrency,
    customerPriceCurrency,
    commissionCurrency,
    addr_from: from,
    addr_to: to,
    dt_pickup_from: departureDateTimeFrom,
    dt_pickup_to: departureDateTimeTo,
    dt_delivery_from: deliveryDateTimeFrom,
    dt_delivery_to: deliveryDateTimeTo,
    carrier_contact_id: carrierId,
    customer_contact_id: customerId,
    price_carrier: carrierPrice,
    price_customer: customerPrice,
    price_commission: commission,
    contact_pickup: loadingContact,
    contact_delivery: unloadingContact,
    description: note,
    place_id_from: placeIdFrom,
    place_id_to: placeIdTo,
    distance,
    duration,
    payment_duedate: dueDate,
    with_dph: withDPH,
    conditions,
    index_number: index,
    contact_driver: driverContact,
  }).then(() => undefined);

const updateShipment = ({
  shipmentName,
  customerId,
  driverId,
  vehicleId,
  phoneSender,
  emailSender,
  phoneReceiver,
  emailReceiver,
  origin,
  destination,
  originLat,
  originLng,
  originPlaceId,
  destinationLat,
  destinationLng,
  destinationPlaceId,
  pickupTime,
  deliveryTime,
  duration,
  distance,
  price,
  id,
  idJourney,
  shipmentDescription,
}) =>
  updateResource(`shipments/${id}`, {
    name: shipmentName,
    customer_contact_id: customerId,
    id_driver: driverId,
    id_vehicle: vehicleId,
    sender_phone: phoneSender,
    sender_email: emailSender,
    receiver_phone: phoneReceiver,
    receiver_email: emailReceiver,
    origin,
    destination,
    origin_lat: originLat,
    origin_lng: originLng,
    origin_place_id: originPlaceId,
    destination_lat: destinationLat,
    destination_lng: destinationLng,
    destination_place_id: destinationPlaceId,
    pickup_time: pickupTime,
    estimated_delivery_time: deliveryTime,
    duration,
    distance,
    price,
    id_journey: idJourney,
    description: shipmentDescription,
  });

const updateShipmentItem = (
  idShipment,
  idItem,
  amount,
  width,
  height,
  length,
  weight
) =>
  updateResource(`shipments/${idShipment}/items/${idItem}`, {
    amount,
    width_cm: width,
    height_cm: height,
    length_cm: length,
    weight_kg: weight,
  });

const updateVehicle = (
  id,
  vendor,
  name,
  spz,
  vin,
  fuel,
  height,
  width,
  length,
  maxLoad,
  date
) =>
  updateResource(`vehicles/${id}`, {
    vendor,
    name,
    spz,
    vin,
    fuel,
    cargoAreaHeight: height,
    cargoAreaWidth: width,
    cargoAreaLength: length,
    cargoAreaLoad: maxLoad,
    manufacturedAt: date,
  }).then(() => undefined);

const createShipmentsExport = (ids, language) =>
  createResource("new-shipments/exports", {
    ids,
    language,
  }).then(({ data: { url } }) => url);

const createInvoicesExport = (ids) =>
  createResource("xml/pohoda", {
    invoicesId: ids,
  }).then(({ data }) => data);

const printLabels = (incomingOrderIds) =>
  createResource("incoming-order/generate-labels-pdf", {
    incomingOrderIds,
  }).then(({ data }) => data);

const printShipment = (ids) =>
  createResource("new-shipments/generate-shipment-pdf", {
    shipmentIds: ids,
  }).then(({ data }) => data);

const exportOrdersRinkai = (ids, placeId, alias) =>
  createResource("rinkai", {
    incomingOrdersId: ids,
    placeId,
    alias,
  }).then(({ data }) => data);

const createMail = (content) =>
  createResource("email/feedback", {
    content,
  });

const readCompanyInfo = (ic) => readResource(`contacts/info/${ic}`);

const readAvailableTags = (t, language) =>
  readResource(`contacts/tags?language=${language}`).then(({ data: tags }) =>
    tags.map(({ id, type, geopoliticalType, label: value }) => ({
      id,
      value,
      type,
      geopoliticalType,
      localizedType: t(`contact.tag.${type.toLowerCase()}`),
      localizedGeopoliticalType: geopoliticalType
        ? t(`contact.tag.${geopoliticalType.toLowerCase()}`)
        : undefined,
    }))
  );

const updateSubscription = (tariffId) =>
  updateResource("subscription", {
    tariffId,
  });

const createCompanyBankAccountMatchQuery = ({ orderBy, limit, offset }) =>
  createResource("company-profile/bank-account/match", {
    orderBy,
    limit,
    offset,
  }).then(({ data }) => data);

const createCompanyBankAccount = ({ currency, iban, bban, isPrimary }) =>
  createResource("company-profile/bank-account", {
    currency,
    iban,
    bban,
    isPrimary,
  }).then(({ data }) => data);

const deleteCompanyBankAccount = (bankAccountId) =>
  deleteResource(`company-profile/bank-account/${bankAccountId}`);

const updateCompanyBankAccount = (
  bankAccountId,
  { currency, iban, bban, isPrimary }
) =>
  updateResource(`company-profile/bank-account/${bankAccountId}`, {
    bban,
    iban,
    currency,
    isPrimary,
  }).then(({ data }) => data);

const createCompanyBankAccountSuggestQuery = () =>
  createResource("company-profile/bank-account/suggest").then(
    ({ data }) => data
  );

const createStampSignature = async (stampSignatureFile) => {
  const formData = new FormData();
  formData.append("stampSignature", stampSignatureFile);

  const result = await createResource(
    "company-profile/stamp-signature",
    formData
  ).then(({ data: { id, createdAt, url, name } }) => ({
    id,
    createdAt: new Date(createdAt),
    url,
    name,
  }));
  return result;
};

const deleteStampSignature = () =>
  deleteResource("company-profile/stamp-signature");

const createStateMatchQuery = ({ resources, orderBy, limit, offset }) =>
  createResource("company-profile/state/match", {
    match: { resources },
    orderBy,
    limit,
    offset,
  }).then(({ data }) => data);

const createUserRole = ({ name, description, permissionsId }) =>
  createResource("permissions/company-role", {
    name,
    description,
    permissionsId,
  });

const readCompanyPermissions = (roleId) =>
  readResource("permissions/company-permissions").then(({ data }) => data);

const readCompanyRoleById = (roleId) =>
  readResource(`permissions/company-role/${roleId}`).then(({ data }) => data);

const updateCompanyRole = (roleId, data) =>
  updateResource(`permissions/company-role/${roleId}`, data).then(
    ({ data }) => data
  );

const createCompanyRoleMatchQuery = ({ orderBy, limit, offset }) =>
  createResource("permissions/company-role/match", {
    orderBy,
    limit,
    offset,
  }).then(({ data }) => data);

const readCompanyRoles = () =>
  readResource("permissions/company-roles").then(({ data }) => data);

const createInvoiceMatchQuery = () =>
  createResource("invoices/match", {}).then(({ data }) => data);

const readInvoiceById = (id) =>
  readResource(`invoices/shipmentInvoiceEntity/${id}`).then(({ data }) => data);

const deleteCompanyRole = (id) =>
  deleteResource(`permissions/company-role/${id}`);

const deleteState = (id) => deleteResource(`company-profile/state/${id}`);

const createState = ({
  statusName: name,
  resources,
  isDefault,
  automationRule,
}) =>
  createResource("company-profile/state/store", {
    name,
    resources,
    isDefault,
    automationRule,
  });

const updateIncomingOrdersStatuses = (ids, stateId) => updateResource(`incoming-order/status`, {
  incomingOrderIds: ids,
  stateId
});

const updateState = ({
  id: stateId,
  statusName: name,
  resources,
  isDefault,
  automationRule,
  tag,
}) =>
  updateResource(`company-profile/state/${stateId}`, {
    name,
    resources,
    isDefault,
    automationRule,
    tag,
  });

const createStateSuggestQuery = (args) =>
  createResource("company-profile/state/suggest", args).then(
    ({ data }) => data
  );

const deserializePlace = (place) => ({
  ...place,
  description: place.alias,
});

const suggestPlacesByAlias = (alias) =>
  createResource("places/suggest", {
    alias,
  }).then(({ data }) => data.places.map(deserializePlace));

const getAllPlaces = () =>
  createResource("places/all").then(({ data }) =>
    data.places.map(deserializePlace)
  );

const suggestUsers = ({ roleId, permissions, search, contactId }) =>
  createResource("users/suggest", {
    roleId,
    permissions,
    search,
    contactId,
  }).then(({ data }) => data);

const findUserById = (userId) =>
  readResource(`users/find/${userId}`).then(({ data }) => ({
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    role: data.role,
    phoneNumber: data.phoneNumber,
  }));

const createSubcontractorEmployee = (contactId, data) =>
  createResource(`contacts/${contactId}/subcontractor-employee`, data);

const createMatchQueryExchangeRates = ({ orderBy, limit, offset }) =>
  createResource("exchange/match", { orderBy, offset, limit }).then(
    ({ data }) => data
  );

const createExchangeRate = (exchangeRate) =>
  createResource("exchange", exchangeRate);

const deleteExchangeRate = (id) => deleteResource(`exchange/${id}`);

const putExchangeRate = (id, exchangeRate) =>
  updateResource(`exchange/${id}`, exchangeRate).then(({ data }) => data);

const putShipmentById = (id, shipment) =>
  updateResource(`new-shipments/${id}`, shipment).then(({ data }) => data);
const postShipment = (shipment) =>
  createResource("new-shipments", shipment).then(({ data }) => data);

const postIncomingOrderFindJourneysByIds = (orderIds, orderType) =>
  createResource("incoming-order/journeys", {
    incomingOrderIds: orderIds,
    orderType,
  }).then(({ data }) => data);

const putIncomingOrderById = (id, order) =>
  updateResource(`incoming-order/${id}`, order).then(({ data }) => data);

const putOutcomingOrderById = (id, order) =>
  updateResource(`outcoming-order/${id}`, order).then(({ data }) => data);

const createMatchQueryWarehouses = ({ orderBy, limit, offset }) =>
  createResource("warehouses/match", { orderBy, offset, limit }).then(
    ({ data }) => data
  );

const createWarehouse = (warehouse) => createResource("warehouses", warehouse);

const deleteWarehouse = (id) => deleteResource(`warehouses/${id}`);

const putWarehouse = (id, warehouse) =>
  updateResource(`warehouses/${id}`, warehouse).then(({ data }) => data);

const createMatchQueryWarehouseOrders = ({ match, orderBy, limit, offset }) =>
  createResource("warehouse-order/match", {
    match,
    orderBy,
    offset,
    limit,
  }).then(({ data }) => data);

const receiveCargoItemPackages = (cargoItemPackagesPayload) =>
  createResource("warehouse-order", cargoItemPackagesPayload);

const dispatchCargoItemPackages = (cargoItemPackagesPayload) =>
  createResource("warehouse-order/dispatch", cargoItemPackagesPayload);

const deleteWarehouseOrder = (id) => deleteResource(`warehouse-order/${id}`);

const putWarehouseOrder = (id, warehouseOrder) =>
  updateResource(`warehouses-order/${id}`, warehouseOrder).then(
    ({ data }) => data
  );

const updateWarehouseOrderState = (orderId, state) =>
  patchResource(`warehouse-order/${orderId}/state`, { state }).then(
    ({ data }) => data
  );

const getWarehouseOrderActivity = ({ warehouseOrderId }) =>
  readResource(`warehouse-order/${warehouseOrderId}/activity`).then(
    ({ data }) => data.map((item) => deserializeActivity(item))
  );

const readIndexNumberSequences = () =>
  readResource("company-profile/index-number-sequence").then(
    ({ data }) => data
  );

const putIndexNumberSequences = (sequences) =>
  updateResource("company-profile/index-number-sequence", sequences).then(
    ({ data }) => data
  );

const getIncomingOrderActivity = ({ incomingOrderId }) =>
  readResource(`incoming-order/${incomingOrderId}/activity`).then(({ data }) =>
    data.map((item) => deserializeActivity(item))
  );

const postIncomingOrderCommentar = ({ incomingOrderId, content }) =>
  createResource(`incoming-order/${incomingOrderId}/commentaries`, {
    content,
  }).then(({ data }) => data);

const postIncomingOrderWaypointDriveThrough = (
  waypointId,
  action,
  cargoItemsPackageIds,
  drivenThroughAt
) =>
  createResource(`incoming-order/waypoints/${waypointId}/drive-through`, {
    action,
    cargoItemsPackageIds,
    drivenThroughAt,
  }).then(({ data }) => data);

const postShipmentWaypointDriveThrough = (
  shipmentId,
  waypointId,
  packages
) =>
  createResource(`new-shipments/${shipmentId}/waypoints/${waypointId}/drive-through`, packages).then(({ data }) => data);

const postIncomingOrderNotDelivered = (orderId, shipmentId, reason) =>
  createResource(`incoming-order/${orderId}/not-delivered`, {
    reason,
    shipmentId,
  }).then(({ data }) => data);

const deleteIncomingOrderNotDelivered = (orderId) =>
  deleteResource(`incoming-order/${orderId}/not-delivered`);

const putIncomingOrderNotDelivered = (orderId, shipmentId, reason, timestamp) =>
  updateResource(`incoming-order/${orderId}/not-delivered`, {
    reason,
    timestamp,
    shipmentId 
  }).then(({ data }) => data);


// const putIncomingOrderWaypointDriveThrough = (
//   waypointId,
//   cargoItemsPackageIds,
//   drivenThroughAt,
// ) =>
//   updateResource(`incoming-order/waypoints/${waypointId}/drive-through`, {
//     drivenThroughAt,
//     cargoItemsPackageIds,
//   }).then(({ data }) => data);

const deleteIncomingOrderWaypointDriveThrough = (waypointId) =>
  deleteResource(`incoming-order/waypoints/${waypointId}/drive-through`).then(
    ({ data }) => data
  );

const putIncomingOrderCommentar = ({
  incomingOrderId,
  commentaryId,
  content,
}) =>
  updateResource(
    `incoming-order/${incomingOrderId}/commentaries/${commentaryId}`,
    { content }
  ).then(({ data }) => data);

const putShipmentBox = (shipmentId, box) =>
  updateResource(`new-shipments/${shipmentId}/box`, { box }).then(
    ({ data }) => data
  );

const deleteIncomingOrders = (ids) =>
  deleteResource("incoming-order", { ids }).then(({ data }) => data);

const deleteShipments = (ids) =>
  deleteResource("new-shipments", { ids }).then(({ data }) => data);

const deleteIncomingOrderCommentar = ({ incomingOrderId, commentaryId }) =>
  deleteResource(
    `incoming-order/${incomingOrderId}/commentaries/${commentaryId}`
  ).then(({ data }) => data);

const getOutcomingOrderActivity = ({ outcomingOrderId }) =>
  readResource(`outcoming-order/${outcomingOrderId}/activity`).then(
    ({ data }) => data.map((item) => deserializeActivity(item))
  );

const postOutcomingOrderCommentar = ({ outcomingOrderId, content }) =>
  createResource(`outcoming-order/${outcomingOrderId}/commentaries`, {
    content,
  }).then(({ data }) => data);

const putOutcomingOrderCommentar = ({
  outcomingOrderId,
  commentaryId,
  content,
}) =>
  updateResource(
    `outcoming-order/${outcomingOrderId}/commentaries/${commentaryId}`,
    { content }
  ).then(({ data }) => data);

const deleteOutcomingOrderCommentar = ({ outcomingOrderId, commentaryId }) =>
  deleteResource(
    `outcoming-order/${outcomingOrderId}/commentaries/${commentaryId}`
  ).then(({ data }) => data);
const deserializeCommentary = ({ createdAt, editedAt, ...rest }) => ({
  ...rest,
  createdAt: new Date(createdAt),
  editedAt: editedAt ? new Date(editedAt) : undefined,
});

const deserializeActivity = (data) => {
  if (
    data.type === "INCOMING_ORDER_COMMENTARY" ||
    data.type === "OUTCOMING_ORDER_COMMENTARY"
  ) {
    return {
      ...data,
      commentary: deserializeCommentary(data.commentary),
    };
  }

  return {
    ...data,
    createdAt: new Date(data.createdAt),
  };
};

const postFile = async ({ bucket, file }) => {
  const formData = new FormData();
  formData.append("document", file);

  const { data } = await createResource(`files/${bucket}`, formData);

  return {
    createdAt: new Date(data.createdAt),
    id: data.id,
    name: data.name,
    url: data.url,
  };
};

const postOutcomingOrderFile = async ({ bucket, file, kind }) => {
  const formData = new FormData();
  formData.append("document", file);
  formData.append("kind", kind);

  const { data } = await createResource(`files/${bucket}`, formData);
  return data;
};

const postOutcomingOrderFileWithToken = async (
  token,
  { bucket, file, kind }
) => {
  const formData = new FormData();
  formData.append("document", file);
  formData.append("kind", kind);

  const { data } = await axios.post(
    `${urlV2}/files/${bucket}/${token}`,
    formData
  );
  return data;
};

const uploadCSV = async ({ file }) => {
  const formData = new FormData();
  formData.append("csvFile", file);

  const { data } = await createResource("csv/uploadCSV", formData);
  return data;
};

const getOrderAttributes = () =>
  readResource("csv/order-attributes").then(({ data }) => data);

const mapColumns = async (data) => {
  const finalObj = {};
  Object.keys(data).forEach((csvArg) => {
    if (data[csvArg] && data[csvArg] !== "") {
      finalObj[data[csvArg]] = csvArg;
    }
  });
  const { data: res } = await createResource("csv/map-columns", {
    attributes: finalObj,
  });
  return res;
};

const postTemplate = async ({ name, attributes }) => {
  const { data: res } = await createResource("csv/template", {
    name,
    template: attributes,
  });
  return res;
};

const resolveCSV = async ({ resolvedDuplicates }) => {
  const { data: res } = await createResource("csv/resolve", {
    resolvedDuplicates,
  });
  return res;
};

const postExportIncomingOrdersToCSV = async (ids) =>
  createResource("csv/incoming-orders", { incomingOrderIds: ids }).then(
    ({ data }) => data
  );
const postExportShipmentsToCSV = async (ids) =>
  createResource("csv/shipments", { shipmentIds: ids }).then(
    ({ data }) => data
  );

const postExportOutcomingOrdersToCSV = async (ids) =>
  createResource("csv/outcoming-orders", { outcomingOrderIds: ids }).then(
    ({ data }) => data
  );

const createMatchQueryUsers = ({ query }) =>
  createResource("users/match", query).then(({ data }) => ({
    ...data,
    matches: data.matches.map((item) => ({
      ...item,
      lastSeenAt: item.lastSeenAt ? new Date(item.lastSeenAt) : undefined,
    })),
  }));

const createShipmentsByDriverQuery = ({query}) =>
createResource("new-shipments/match/driver", query).then(({data}) => ({
  ...data,
})); 

const deserializeIncomingOrder = (
  {
    createdAt,
    completedAt,
    updatedAt,
    customerContact,
    journey: {
      waypoints,
      ...restJourney
    },
    ...rest
  }
) => ({
  ...rest,

  createdAt: createdAt ? new Date(createdAt) : undefined,
  completedAt: completedAt ? new Date(completedAt) : undefined,
  updatedAt: updatedAt ? new Date(updatedAt) : undefined,

  customerContact: customerContact
    ? deserializeContact(customerContact)
    : undefined,

  journey: {
    ...restJourney,

    waypoints: waypoints.map(
      ({
        arriveAtFrom,
        arriveAtTo,
        ...restWaypoint
      }) => ({
        ...restWaypoint,
        arriveAtFrom: arriveAtFrom ? new Date(arriveAtFrom) : undefined,
        arriveAtTo: arriveAtTo ? new Date(arriveAtTo) : undefined
      })
    )
  }
});

const createMatchQueryIncomingOrders = ({ query }) => createResource("incoming-order/match", query).then(({ data: { matches, ...rest } }) => ({
  ...rest,
  matches: matches.map(deserializeIncomingOrder),
}));

const postExportShipmentsToCSVWithPackages = async (ids) =>
  createResource("csv/shipments-with-packages", { shipmentIds: ids }).then(
    ({ data }) => data
  );

const postExportShipmentsToXLSX = async (ids) =>
  createResource("csv/xlsx-shipments", { shipmentIds: ids }).then(
    ({ data }) => data
  );
const postExportShipmentsToXLM = async (ids) =>
  createResource("xml/sconto", { shipmentIds: ids }).then(({ data }) => data);

const postExportShipmentToOutcomingOrder = async (id) =>
  createResource(`new-shipments/${id}/copy-to-outcoming-order`);

const postExportWarehouseOrdersToCSV = async (ids) =>
  createResource("csv/warehouse-orders", { warehouseOrderIds: ids }).then(
    ({ data }) => data
  );

const getTemplates = () =>
  readResource("csv/template").then(({ data }) => data);

const deleteTemplate = ({ templateID }) =>
  deleteResource(`csv/template/${templateID}`).then(({ data }) => data);

const generateToken = (email) =>
  createResource("users/generateToken", { email }).then(({ data }) => data);

const matchUserToken = () =>
  createResource("users/matchUserToken").then(({ data }) => data);

const getNextContactNumber = () =>
  readResource("contacts/nextContactNumber").then(({ data }) => data);

const getNextIncomingOrderNumber = (contactNumber) =>
  readResource(`incoming-order/index-number/${contactNumber}`).then(
    ({ data }) => data.nextIndexNumber
  );

const getIncomingOrderServices = () =>
  readResource("incoming-order/services").then(({ data }) => data.services);
const getOutcomingOrderServices = () =>
  readResource("outcoming-order/services").then(({ data }) => data.services);

const deleteFile = (bucket, name) => deleteResource(`files/${bucket}/${name}`);

const getTrackingData = (userIds, from, to) =>
  createResource("tracking/filter", { userIds, range: {from, to }}).then(({ data }) => data);

export {
  getTrackingData,
  deserializeOrderIndex,
  createContact,
  createPlace,
  createDispatcher,
  createDriver,
  createShipment,
  createMail,
  createShipmentItem,
  createOrder,
  createOrderItems,
  createShipmentsExport,
  createSignIn,
  createSignUp,
  createResendVerificationMail,
  createResetPassword,
  readNextOrderIndex,
  createCompanyProfile,
  createVehicle,
  createSignOut,
  deleteContact,
  deletePlace,
  deleteDispatcher,
  deleteOrder,
  deleteShipment,
  deleteIncomingOrder,
  deleteDriver,
  deleteMe,
  deleteVehicle,
  deleteShipmentItem,
  downloadPDFOrder,
  readContacts,
  createContactsQuery,
  createPlacesQuery,
  createGetBoxes,
  readContact,
  readCarriers,
  readCompanyProfile,
  patchCompanyProfile,
  readCustomerProfile,
  readUserCommissionStatistics,
  readCompanyCommissionStatistics,
  readInvoices,
  readDispatchers,
  createDispatchersQuery,
  readCompanyStatistics,
  createOrdersQuery,
  createShipmentsQuery,
  createShipmentBoardQuery,
  createIncomingOrderBoardQuery,
  readShipment,
  readShipments,
  readUserShipmentsStatistics,
  readMyCommissionStatistics,
  readMyRevenueStatistics,
  readMyShipmentStatistics,
  readCompanyShipmentStatistics,
  readShipmentItems,
  readUserKpiStatistics,
  readUserRevenueStatistics,
  readCompanyRevenueStatistics,
  readLastOrderIndex,
  readUserIndexNumber,
  readVehicles,
  createVehiclesQuery,
  createDriversQuery,
  readDrivers,
  readDriverStatistics,
  readCustomers,
  readMe,
  readMyStatistics,
  readNews,
  updateDispatcher,
  updateDriver,
  updateCustomerProfile,
  updateCompanyProfile,
  updateContact,
  updatePlace,
  updateOrdersByImportingFromRinkai,
  readOrder,
  readOrderByIndexNumber,
  readOrderItems,
  readOrderAudits,
  updateOrder,
  updateShipment,
  updateShipmentState,
  updateIncomingOrderState,
  updateOutcomingOrderState,
  updateShipmentItem,
  updateVehicle,
  updateVehicleAvatar,
  updateCustomerProfileAvatar,
  updateCompanyProfileAvatar,
  readTracks,
  readCompanyInfo,
  stringifyOrderIndex,
  /* invoices */
  createShipmentInvoice,
  updateShipmentInvoice,
  getNextShipmentInvoiceNumber,
  getShipmentInvoice,
  getInvoices,
  getIncomingOrderById,
  getWarehouseOrderById,
  getOutcomingOrderById,
  deleteInvoice,
  setInvoicePaidAt,
  downloadPDFInvoice,
  inviteUser,
  reinviteUser,
  deleteUser,
  deleteCompany,
  updateRole,
  readUserHighlights,
  readCompanyHighlights,
  readCompanyIncomingOrderHighlights,
  readUserIncomingOrderHighlights,
  readUserOutcomingOrderHighlights,
  readCompanyOutcomingOrderHighlights,
  readCompanyShipmentHighlights,
  readUserShipmentHighlights,
  readCompanyRevenueHighlights,
  readUserRevenueHighlights,
  updateSubscription,
  suggestPlacesByAlias,
  getAllPlaces,
  suggestUsers,
  readAvailableTags,
  updateEmployee,
  createEmployee,
  deleteEmployee,
  readExchangeRate,
  readCurrentExchangeRates,
  createPlaceInput,
  createInvoicesExport,
  findUserById,
  createCompanyBankAccountMatchQuery,
  createCompanyBankAccount,
  deleteCompanyBankAccount,
  updateCompanyBankAccount,
  createCompanyBankAccountSuggestQuery,
  readCompanyTerms,
  readUserTerms,
  createTerms,
  updateTerms,
  deleteTerms,
  createStampSignature,
  deleteStampSignature,
  createUserRole,
  readCompanyRoleById,
  readCompanyRoles,
  updateCompanyRole,
  createCompanyRoleMatchQuery,
  readCompanyPermissions,
  deleteCompanyRole,
  createInvoiceMatchQuery,
  readInvoiceById,
  createMatchQueryExchangeRates,
  createExchangeRate,
  deleteExchangeRate,
  putExchangeRate,
  readIndexNumberSequences,
  putIndexNumberSequences,
  createSubcontractorEmployee,
  getIncomingOrderActivity,
  postIncomingOrderCommentar,
  putIncomingOrderCommentar,
  deleteIncomingOrders,
  deleteShipments,
  deleteIncomingOrderCommentar,
  getOutcomingOrderActivity,
  postOutcomingOrderCommentar,
  putOutcomingOrderCommentar,
  deleteOutcomingOrderCommentar,
  postFile,
  postOutcomingOrderFile,
  postOutcomingOrderFileWithToken,
  putShipmentById,
  putShipmentBox,
  postShipment,
  postIncomingOrderFindJourneysByIds,
  putIncomingOrderById,
  putOutcomingOrderById,
  readCompanyInvoiceStatistics,
  readCompanyInvoiceSumStatistics,
  readCompanyIncomingOrdersStatistics,
  readCompanyOutcomingOrdersStatistics,
  readCompanyOutcomingOrderSumStatistics,
  createWarehouse,
  putWarehouse,
  deleteWarehouse,
  createMatchQueryWarehouses,
  exportOrdersRinkai,
  createMatchQueryWarehouseOrders,
  putWarehouseOrder,
  deleteWarehouseOrder,
  updateWarehouseOrderState,
  createWarehouseman,
  receiveCargoItemPackages,
  dispatchCargoItemPackages,
  uploadCSV,
  getOrderAttributes,
  mapColumns,
  postTemplate,
  getTemplates,
  resolveCSV,
  postExportIncomingOrdersToCSV,
  postExportShipmentsToCSV,
  postExportShipmentsToCSVWithPackages,
  postExportOutcomingOrdersToCSV,
  postExportShipmentsToXLSX,
  postExportShipmentsToXLM,
  postExportShipmentToOutcomingOrder,
  postExportWarehouseOrdersToCSV,
  deleteTemplate,
  deleteState,
  createState,
  updateState,
  updateIncomingOrdersStatuses,
  createStateMatchQuery,
  createStateSuggestQuery,
  printLabels,
  generateToken,
  matchUserToken,
  printShipment,
  getWarehouseOrderActivity,
  getNextContactNumber,
  getNextIncomingOrderNumber,
  postIncomingOrderWaypointDriveThrough,
  postShipmentWaypointDriveThrough,
  deleteIncomingOrderWaypointDriveThrough,
  deleteIncomingOrderNotDelivered,
  putIncomingOrderNotDelivered,
  postIncomingOrderNotDelivered,
  createMatchQueryUsers,
  createShipmentsByDriverQuery,
  createMatchQueryIncomingOrders,
  getIncomingOrderServices,
  getOutcomingOrderServices,
  readOutcomingOrderDataByToken,
  updateOutcomingOrderDocuments,
  deleteFile,
};
