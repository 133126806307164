import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DialogContent,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

import { useApiClient } from "../../../cargotic-webapp-component";
import VehicleTrailerEditorSelectList from "./VehicleTrailerEditorSelectList";

import { VehicleType } from "@cargoticcom/model";


const useStyles = makeStyles(({ spacing }) => ({
  input: {
    flex: "none",
    marginBottom: spacing(1)
  },
  list: {
    padding: 0
  }
}));

const VehicleTrailerEditorSelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApiClient();

  const [search, setSearch] = useState("");
  const [availableTrailers, setAvailableTrailers] = useState();
  const [isTrailersLoading, setIsTrailersLoading] = useState(true);

  const handleDeselect = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  const handleSearchChange = ({ target: { value: newSearch } }) => (
    setSearch(newSearch)
  );

  const handleSelect = id => {
    if (onChange) {
      const trailer = availableTrailers.find(({ id: other }) => other === id);
      onChange(trailer);
    }
  };

  const loadTrailers = async () => {
    setIsTrailersLoading(true);

    try {
      const matches = await client.vehicle.postVehicleSuggestQuery({
        query: {
          search, 
          type: VehicleType.TRAILER 
        } 
      });

      const selected = matches.map(({ id, ...rest }) => ({
        ...rest,
        id,
        isSelected: value?.id === id
      }));

      setAvailableTrailers(selected);
      setIsTrailersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadTrailers();
  }, [search]);

  useEffect(() => {
    if (!availableTrailers) {
      return;
    }

    const selected = availableTrailers.map(({ id, ...rest }) => ({
      ...rest,
      id,
      isSelected: value?.id === id
    }));

    setAvailableTrailers(selected);
  }, [value]);

  return (
    <>
      <DialogContent className={classes.input}>
        <TextField
          label={t("webapp:common.title.search-trailer")}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={handleSearchChange}
        />
      </DialogContent>
      <DialogContent className={classes.list} dividers>
        <VehicleTrailerEditorSelectList
          trailers={availableTrailers}
          isLoading={isTrailersLoading}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
        />
      </DialogContent>
    </>
  );
};

export default VehicleTrailerEditorSelect;
