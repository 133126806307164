import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "@cargoticcom/time";

import {
  makeStyles,
  MenuList,
  Grow,
  TextField,
  MenuItem,
  Popper,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Button,
  Grid,
  Paper
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    width: "0.8em",
    height: "0.8em"
  },
  endIcon: {
    marginLeft: 0
  },
  buttonRoot: {
    textTransform: "none",
    fontWeight: 600
  },
  buttonRootSelected: {
    background: "#D6D6D6",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#D6D6D6"
    }
  },
  text: {
    padding: "4px 6px"
  }
}));

const FilterNumberRange = ({
  id,
  placeholderTitle,
  onChange,
  value,
  onClear,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState(value ?? { from: undefined, to: undefined });

  useEffect(() => {
    setRangeValue(value ?? { from: undefined, to: undefined });
  }, [value]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (rangeValue.from && rangeValue.to) {
      if (!hasError(rangeValue.from, rangeValue.to)) {
        if (value && value.from === rangeValue.from && value.to === rangeValue.to) {
          setOpen(false);
          return;
        }
        handleSuggest(rangeValue);
      }
    }
    setOpen(false);
  };

  const stopPropagation = e => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSuggest = debounce(1000)(async () => onChange(rangeValue));

  const handleClearAndClose = () => {
    setRangeValue({});
    onClear();
    handleToggle();
  };

  const hasError = (from, to) => {
    try {
      const fromNum = parseInt(from);
      const toNum = parseInt(to);
      return fromNum > toNum;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        classes={{ root: rangeValue.from && rangeValue.to ? classes.buttonRootSelected : classes.buttonRoot, endIcon: classes.endIcon, text: classes.text }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        disabled={disabled}
        endIcon={
          <>
            <IconButton onClick={() => null} size="small">
              <ArrowDropDownIcon size="small" className={classes.icon} />
            </IconButton>
            {rangeValue && rangeValue.from && rangeValue.to ?
              (<IconButton onClick={handleClearAndClose} size="small">
                <CloseIcon size="small" className={classes.icon} />
              </IconButton>) : null}
          </>
        }
      >
        {placeholderTitle}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 99999}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <MenuItem>
                    <TextField
                      id="from"
                      type="number"
                      value={rangeValue.from}
                      label={t("from")}
                      error={hasError(rangeValue.from, rangeValue.to)}
                      onChange={({ target: { value: v } }) => setRangeValue({ ...rangeValue, from: v })}
                      onKeyDown={stopPropagation}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">CZK</InputAdornment>
                      }}
                    />
                  </MenuItem>
                  <MenuItem>
                    <TextField
                      id="to"
                      type="number"
                      value={rangeValue.to}
                      label={t("to")}
                      error={hasError(rangeValue.from, rangeValue.to)}
                      onChange={({ target: { value: v } }) => setRangeValue({ ...rangeValue, to: v })}
                      onKeyDown={stopPropagation}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">CZK</InputAdornment>
                      }}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FilterNumberRange;
