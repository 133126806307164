import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserRole as PermissionRoles } from "@cargoticcom/model";
import { Paper, Grid, Typography } from "@material-ui/core";
import { ContactType, IncomingOrderState } from "@cargoticcom/model";
import { useSnackbar } from "notistack";
import Page from "../../../component/common/Page";
import SelectOrderType from "./SelectOrderType";
import SelectCarrier from "./SelectCarrier";
import IncomingOrderTable from "./IncomingOrderTable";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import SearchTextfield from "../../../component/common/SearchTextfield";
import useRouter from "../../../component/hook/useRouter";
import { useApiClient } from "../../../../cargotic-webapp-component";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window";

import DataTable from "../../../datatable";
import useTable from "../../../datatable/useTable";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import { suggestUsers } from "../../../resource";
import useAuth from "../../../component/hook/useAuth";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import useIncomingOrderMultiselectSelection from "../../../component/hook/useIncomingOrderMultiselectSelection";
import useOutcomingOrderConcept from "../../../component/hook/useOutcomingOrderConcept";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";

const ordersHeader = [
  {
    name: "indexNumber",
    label: "outcomingOrders.indexNumber",
    isOrderable: true,
    width: "20%"
  },
  {
    name: "customerContact",
    label: "outcomingOrders.customer",
    isOrderable: true
  },
  {
    name: "customerPrice",
    label: "outcomingOrders.customerPrice",
    isOrderable: true
  }
];

const IncomingOrderMultiloading = () => {
  const { t } = useTranslation();
  const { hasPermission, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  let reloadDelay;

  const canReadCompanyIncomingOrder = hasPermission("resource.incomingOrder.company.read");

  const { incomingOrderSelection, setIncomingOrderSelection } = useIncomingOrderMultiselectSelection();
  const { setShipment } = useShipmentConcept();
  const { setOutcomingOrder } = useOutcomingOrderConcept();

  const incomingOrderStateSource = [
    { title: t("outcomingOrders.stateDone"), value: IncomingOrderState.DONE },
    { title: t("outcomingOrders.stateInProgress"), value: IncomingOrderState.IN_PROGRESS },
    { title: t("outcomingOrders.stateQueue"), value: IncomingOrderState.QUEUE },
    { title: t("outcomingOrders.stateReady"), value: IncomingOrderState.READY }
  ];

  const { history, location: { search: routerSearch } } = useRouter();

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const [filter, setFilter] = useState(initFilter);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [search, setSearch] = useState(initSearchText);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);
  const handleSelectUserRole = (roles) => setFilter({ ...filter, roles });

  const clearFilter = () => setFilter({});

  const isAnyFilterActive = () => {
    const { roles } = filter;
    return roles !== undefined;
  };

  const [items, setItems] = useState([]);

  const client = useApiClient();

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    customers: filter.customers ? filter.customers.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined
  });

  const reloadItems = useCallback(async (offset, limit, ordering) => {
    const newFilter = transformFilter(filter);
    const data = [];
    const _items = await client.incomingOrder.postIncomingOrderMatchQuery({
      query: {
        match: { ...newFilter, search, isDraft: false },
        offset,
        limit,
        orderBy: ordering
      }
    });

    if (_items.total === 0 && offset !== 0) {
      handleChangePage(
        undefined,
        0
      );
    }

    _items.matches.map((item) => {
      item.selected = false;

      const tableCells = [];
      tableCells.push({
        render: item.indexNumber ? (
          <Typography variant="body2">
            #
            {item.indexNumber}
          </Typography>) : (
            <Typography variant="body2">
              Koncept
            </Typography>
          )
      });
      tableCells.push({
        render: item.customerContact ? <Typography variant="body2">{item.customerContact.name}</Typography> : undefined
      });
      tableCells.push({
        render: (
          <Typography variant="body2">
            {item.customerPrice !== undefined ? `${item.customerPrice.toFixed(2)} ${item.customerPriceCurrency}` : ""}
          </Typography>
        )
      });

      data.push({
        id: item.id,
        indexNumber: item.indexNumber,
        row: tableCells,
        selected: incomingOrderSelection.findIndex(id => id === item.id) !== -1
      });

      return item;
    });

    setItems(_items.matches);
    return { data, totalCnt: _items.total };
  }, [filter]);

  const {
    data,
    dataCount,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage
  } = useTable(reloadItems);

  const handleSelectCreators = (creators) => setFilter({ ...filter, creators });
  const handleSelectCustomers = (customers) => setFilter({ ...filter, customers });
  const handleSelectLoadingsDateRange = (loadingDate) => setFilter({ ...filter, loadingDate });
  const handleSelectState = (state) => setFilter({ ...filter, state });

  const loadAvailableUsers = async (search, permissions) => {
    try {
      const matches = await suggestUsers({ permissions, search });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await client.contact.postContactSuggestQuery({
        query: {
          search, types: ["BOTH", type]
        }
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  useEffect(() => {
    const toAdd = data.filter(item => item.selected).map(item => ({ ...item }));
    setIncomingOrderSelection([...toAdd]);
  }, [data, checkedAll]);

  useEffect(() => {
    reloadData();
  }, [search]);

  const handleShipmentSelected = () => {
    if (!incomingOrderSelection.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setShipment({
        incomingOrderIds: incomingOrderSelection.map(({ id }) => id),
        incomingOrders: incomingOrderSelection,
        fresh: true
      });
      setIncomingOrderSelection([]);
      history.push("shipment");
    }
  };

  const handleOutcomingOrderSelected = () => {
    if (!incomingOrderSelection.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setOutcomingOrder({
        incomingOrderIds: incomingOrderSelection.map(({ id }) => id),
        incomingOrders: incomingOrderSelection,
        fresh: true
      });
      setIncomingOrderSelection([]);
      history.push("outcoming-order");
    }
  };

  return (
    <>
      <Page>
        <SelectOrderType
          handleShipmentSelected={handleShipmentSelected}
          handleOutcomingOrderSelected={handleOutcomingOrderSelected}
        />
        {/*
        <SelectCarrier
          suggest={(search) => loadAvailableContacts(ContactType.CARRIER, search)}
          handleChange={setCarrierContact}
        />
*/}
        <FilterContainer
          searchField={<SearchTextfield handleSearch={handleSearch} value={search} fullWidth placeholder={t("users.searchTooltip")} />}
          showClearButton={isAnyFilterActive}
          handleFilterSettingsOpen={handleFilterSettingsOpen}
          defaultFilters={defaultFilters}
          loading={loading}
          filters={[
            <UserFilterDropdown
              id="creators"
              key="creators"
              onChange={(value) => handleSelectCreators(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCreators(all)}
              value={filter.creators ?? []}
              placeholderTitle={t("outcomingOrders.creator")}
              search={(text) => loadAvailableUsers(text)}
              onClear={() => handleSelectCreators(undefined)}
              getTitle={(item) => item.name}
              disabled={!canReadCompanyIncomingOrder}
            />,
            <ContactFilterDropdown
              id="customers"
              key="customers"
              onChange={(value) => handleSelectCustomers(value.length === 0 ? undefined : value)}
              selectAll={(all) => handleSelectCustomers(all)}
              value={filter.customers ?? []}
              search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
              placeholderTitle={t("contacts.customer")}
              onClear={() => handleSelectCustomers(undefined)}
              getTitle={(item) => item.name}
            />,
            <FilterDateRangeDropdown
              id="loadingDate"
              key="loadingDate"
              onChange={(value) => handleSelectLoadingsDateRange(value)}
              value={filter.loadingDate ? [filter.loadingDate.from, filter.loadingDate.to] : []}
              onClear={() => handleSelectLoadingsDateRange(undefined)}
              placeholderTitle={t("outcomingOrders.loadingsDateRange")}
            />,
            <FilterStaticDropdown
              id="state"
              key="state"
              placeholderTitle={t("outcomingOrders.state")}
              value={filter.state ?? []}
              onChange={(arr) => handleSelectState(arr.length === 0 ? undefined : arr)}
              selectAll={() => handleSelectState(incomingOrderStateSource.map(item => item.value))}
              onClear={() => handleSelectState(undefined)}
              source={incomingOrderStateSource}
            />
          ]}
          onClear={clearFilter}
        />

        <Paper>
          <DataTable
            headers={ordersHeader}
            data={data}
            dataCount={dataCount}
            loading={loading}
            ordering={ordering}
            direction={direction}
            checkedAll={checkedAll}
            page={page}
            rowsPerPage={rowsPerPage}
            onSort={handleSort}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Page>
    </>
  );
};

export default IncomingOrderMultiloading;
