import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  deleteShipmentDocument,
} from "@cargotic/api-client-deprecated";

import {
  Fab,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  AccountCircle,
  Edit as EditIcon,
  DirectionsCar,
} from "@material-ui/icons";

import { usePrevious } from "@cargotic/common-deprecated";
import { VehicleType } from "@cargoticcom/model";
import useAuth from "../../../component/hook/useAuth";

import PlaceSearchFailDialog from "../../../component/common/PlaceSearchFailDialog";
import {
  FormikVehicleAutosuggestTextField,
  useApiClient,
  FormUserAutosuggestTextField,
  FormikTextField, AttachmentPreview,
} from "../../../../cargotic-webapp-component";

import VehicleEditor from "../../../../cargotic-webapp-vehicle/component/VehicleEditor";
import { deleteFile, postFile } from "../../../resource";
import {
  FormTextField,
} from "../../../form";

import {
  generateUuid,
  formatDateTime,
} from "../../../../../multiload/cargotic-common";

import FileDropzone from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload from "../../../../../multiload/cargotic-webapp/component/FileUpload";

import { ErrorHandler } from "../../../../cargotic-webapp-error";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > section": {
      "&:not(:first-child)": {
        marginTop: spacing(4),
      },
      "& > h6": {
        marginBottom: spacing(3),
      },
    },
  },
  fab: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ShipmentCarrierForm = ({
  className,
  form,
  apiClient,
  newApiClient,
  onDocumentsChange,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { hasPermission } = useAuth();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const client = useApiClient();
  const handleError = ErrorHandler(t, (message) =>
    enqueueSnackbar(message, { variant: "error" })
  );

  const previousValues = usePrevious(form.values);

  const [hasDocumentFileDropzoneError, setHasDocumentFileDropzoneError] =
    useState(false);

  const isVehicleComplete = useRef(false);
  const isTrailerComplete = useRef(false);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState();

  const [optionTags, setOptionTags] = useState([]);
  const [sharingUsers, setSharingUsers] = useState([]);

  const canUpdateCompanyContact = hasPermission(
    "resource.contact.company.update"
  );

  const handleDocumentDelete = async (uuid) => {
    const { url, bucket, name } = form.values.documents.find(
      ({ uuid: other }) => other === uuid
    );

    if (url) {
      try {
        await deleteFile(bucket, name);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("webapp:shipment.form.document.error.file.delete"), {
          variant: "error",
        });

        return;
      }
    }

    form.setValues((values) => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid),
    }));
  };

  const handleDocumentFileDropzoneError = () =>
    setHasDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = (files) => {
    setHasDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true,
        bucket: 'cgt-staging-shipment-documents'
      };

      const updateDocument = (update) =>
        form.setValues((values) => ({
          ...values,
          documents: values.documents.map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          }),
        }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document],
      }));

      try {
        const { url, createdAt, id } = await postFile({ file, bucket: 'cgt-staging-shipment-documents' });

        updateDocument({
          id,
          url,
          createdAt,
          isUploading: false,
          bucket: 'cgt-staging-shipment-documents'
        });
      } catch (error) {
        console.log(error);

        let message = t("webapp:shipment.form.document.error.upload.general");

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:shipment.form.document.error.upload.size");
        }

        if (error?.response?.data?.error === "StorageLimitExceeded") {
          message = t("webapp:incomingOrder.form.document.error.upload.limit");
        }

        updateDocument({
          isUploading: false,
          error: message,
        });
      }
    });
  };

  useEffect(() => {
    onDocumentsChange();
  }, [form.values.documents]);

  const handleVehicleChange = async () => {
    const { driver, trailer } = form.values;
    let { vehicle } = form.values;

    if (typeof vehicle === "string") {
      isVehicleComplete.current = false;
      return;
    }

    if (typeof vehicle === "object" && !isVehicleComplete.current) {
      vehicle = await client.vehicle.getVehicle({ vehicleId: vehicle.id });

      form.setValue("vehicle", vehicle);

      isVehicleComplete.current = true;
    }

    if (typeof driver !== "object" && vehicle?.defaultDriver) {
      form.setValue("driver", vehicle.defaultDriver);
    }

    if (typeof trailer !== "object" && vehicle?.defaultTrailer) {
      form.setValue("trailer", vehicle.defaultTrailer);
    }
  };

  const handleTrailerChange = async () => {
    let { trailer } = form.values;

    if (typeof trailer === "string") {
      isTrailerComplete.current = false;
      return;
    }

    if (typeof trailer === "object" && !isTrailerComplete.current) {
      trailer = await client.vehicle.getVehicle({ vehicleId: trailer.id });

      form.setValue("trailer", trailer);

      isTrailerComplete.current = true;
    }
  };

  useEffect(() => {
    handleVehicleChange();
  }, [form.values.vehicle]);

  useEffect(() => {
    handleTrailerChange();
  }, [form.values.trailer]);

  const handleVehicleEditorSubmit = async (vehicle) => {
    setIsVehicleEditorOpen(false);

    const vehicleId =
      selectedVehicleType === VehicleType.CAR
        ? form.values.vehicle?.id
        : form.values.trailer?.id;

    try {
      let result = vehicleId
        ? await client.vehicle.putVehicle({ vehicleId, vehicle })
        : await client.vehicle.postVehicle({ vehicle });

      result = { ...vehicle, ...result };

      if (selectedVehicleType === VehicleType.CAR) {
        form.setValue("vehicle", result);
      } else {
        form.setValue("trailer", result);
      }
    } catch (error) {
      console.log(error);

      const {
        response: { data: errData },
      } = error;

      if (errData.type === "DuplicateEntityError") {
        enqueueSnackbar(t("vehicles.error.duplicate"), { variant: "error" });
      } else if (vehicleId) {
        enqueueSnackbar(t("vehicles.error.update"), { variant: "error" });
      } else {
        enqueueSnackbar(t("vehicles.error.create"), { variant: "error" });
      }
    }
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.shipping")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                onClick={() => {
                  setIsVehicleEditorOpen(true);
                  setSelectedVehicle(
                    typeof form.values.vehicle !== "string"
                      ? form.values.vehicle
                      : {}
                  );
                  setSelectedVehicleType(VehicleType.CAR);
                }}
              >
                {typeof form.values.vehicle === "string" ? (
                  <DirectionsCar />
                ) : (
                  <EditIcon />
                )}
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormikVehicleAutosuggestTextField
                key="carrier-form-vehicle"
                form={form}
                name="vehicle"
                type={VehicleType.CAR}
                label={t("webapp:shipment.form.label.vehicle")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                onClick={() => {
                  setIsVehicleEditorOpen(true);
                  setSelectedVehicle(
                    typeof form.values.trailer !== "string"
                      ? form.values.trailer
                      : {}
                  );
                  setSelectedVehicleType(VehicleType.TRAILER);
                }}
              >
                {typeof form.values.trailer === "string" ? (
                  <DirectionsCar />
                ) : (
                  <EditIcon />
                )}
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormikVehicleAutosuggestTextField
                key="carrier-form-trailer"
                form={form}
                name="trailer"
                type={VehicleType.TRAILER}
                label={t("webapp:shipment.form.label.trailer")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab size="medium" color="primary" disabled>
                <AccountCircle />
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormUserAutosuggestTextField
                form={form}
                role="DRIVER"
                name="driver"
                label={t("webapp:shipment.form.label.driver")}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormikTextField
                key="externalReference"
                form={form}
                name="externalReference"
                label={t("webapp:shipment.form.label.externalReference")}
                fullWidth
              />
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.documents")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="notes"
                label={t("webapp:shipment.form.label.notes")}
                variant="outlined"
                rows={6}
                isMultiline
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FileDropzone
                accept={["application/pdf", "image/jpeg", "image/png"]}
                title={
                  hasDocumentFileDropzoneError
                    ? t("webapp:shipment.form.document.error.file.type")
                    : t("webapp:shipment.form.document.title.upload")
                }
                description={[
                  t("webapp:shipment.form.document.description.about"),
                  t("webapp:shipment.form.document.description.supported"),
                  t("webapp:shipment.form.document.description.limit"),
                ]}
                hasError={hasDocumentFileDropzoneError}
                onError={handleDocumentFileDropzoneError}
                onSelect={handleDocumentFilesSelect}
              />
            </Grid>
            {form.values.documents.map(
              ({ uuid, name, url, error, isUploading, createdAt }, index) => (
                <React.Fragment key={uuid}>
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <FileUpload
                      className={index === 0 ? classes.upload : undefined}
                      title={name}
                      error={error}
                      description={
                        createdAt ? formatDateTime(createdAt) : undefined
                      }
                      url={url}
                      isUploading={isUploading}
                      onDelete={() => handleDocumentDelete(uuid)}
                    />
                  </Grid>
                  <AttachmentPreview name={name} url={url}></AttachmentPreview>
                </React.Fragment>
              )
            )}
          </Grid>
        </section>
      </div>
      <VehicleEditor
        initialValue={selectedVehicle}
        isOpen={isVehicleEditorOpen}
        type={selectedVehicleType}
        onClose={() => setIsVehicleEditorOpen(false)}
        onSubmit={handleVehicleEditorSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
    </>
  );
};

export default ShipmentCarrierForm;
