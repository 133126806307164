import React, { useState, useEffect } from "react";

import useRouter from "../../../hook/useRouter";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Card,
  Divider,
  Paper,
  makeStyles,
  Tooltip
} from "@material-ui/core";

import { ShipmentState } from "@cargoticcom/model";

import {
  getActionTypeOfLoading,
  getWaypointsWithCargo,
  getShortCargoWaypointsTitle,
  getShortCargoWaypointsTitleLimited,
  mapAndFormatWaypoints
} from "../../../../utility/waypoint";

import BoardCardActions from "../component/BoardCardActions";
import BoardCardAvatarGroup from "../component/BoardCardAvatarGroup";
import BoardCardDetailRow from "../component/BoardCardDetailRow";
import BoardCardHeader from "../component/BoardCardHeader";
import BoardCardLabels from "../component/BoardCardLabels";
import BoardCardWaypointRow from "../component/BoardCardWaypointRow";
import { red } from "@material-ui/core/colors";
import { Error } from "@material-ui/icons";


const useStyles = makeStyles(({ spacing, transitions, palette }) => ({
  root: {
    padding: 8
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
    height: 1,
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  },
  bottomLine: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    marginLeft: 15,
    marginRight: 5,
    marginTop: 5
  }
}));

const BoardCardXL = ({
  id,
  isDraft,
  state,
  type,
  indexNumber,
  customer,
  carrier,
  customerPrice,
  createdBy,
  loadings,
  currency,
  vehicle,
  driver,
  avatar,
  actions,
  paidAt,
  hasWarning,
  hasError,
  onClick,
  onChangeState,
  hasCreateShipmentPermission,
  hasUpdateShipmentPermission,
  hasDeleteShipmentPermission,
  updateShipmentDriveThrough,
  openDeleteDialog
}) => {
  const classes = useStyles();
  const [currentState, setCurrentState] = useState(state);
  const { t, i18n } = useTranslation();
  const { history } = useRouter();

  const waypoints = getWaypointsWithCargo(loadings);
  const shortCargoTitle = getShortCargoWaypointsTitle(waypoints, t);
  const shortCargoTitleLimited = getShortCargoWaypointsTitleLimited(shortCargoTitle);
  const data = mapAndFormatWaypoints(loadings, true, true);

  const [loading, setLoading] = useState(data[0]);
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [driveThroughLoading, setDriveThroughLoading] = useState(-1);

  const onCardClick = (event) => setClicked(!clicked);

  const [elevation, setElevation] = useState(2);

  useEffect(() => {
    setElevation(hovered ? 7 : 2);
  }, [hovered]);

  const states = [ShipmentState.QUEUE, ShipmentState.READY, ShipmentState.IN_PROGRESS, ShipmentState.DONE];

  return (
    <Paper
      key={id}
      elevation={elevation}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={classes.root} >
        <div
          onClick={(e) => onClick(e, id, state)}
          style={{ cursor: "pointer" }}
        >
          <BoardCardLabels
            isDraft={isDraft}
            type={type}
            paidAt={paidAt}
          />
          {!isDraft
            ? <BoardCardHeader
              t={t}
              id={id}
              hasWarning={hasWarning}
              hasError={hasError}
              isLastState={currentState === states[states.length - 1]}
              state={state}
              indexNumber={indexNumber}
              onStateChange={(event) => onChangeState(event.target.value, id, currentState)}
              hasUpdateShipmentPermission={hasUpdateShipmentPermission}
              moveToNextState={() => onChangeState(states[states.indexOf(currentState) + 1], id, currentState)}
              currentState={currentState}
            />
            : null}
          {data.map(({
            action,
            id: waypointId,
            index,
            isDrivenThrough,
            hasWarning,
            haveError,
            drivenThroughAt,
            formattedPlace,
            formattedDateTime
          }, i) =>
            <BoardCardWaypointRow
              key={`waypoint-row-${i}`}
              t={t}
              id={waypointId}
              action={action}
              isDrivenThrough={isDrivenThrough}
              inProgress={hasWarning}
              haveError={haveError}
              drivenThroughAt={drivenThroughAt}
              driveThroughLoading={driveThroughLoading}
              updateShipmentDriveThrough={async () => {
                setDriveThroughLoading(waypointId);
                await updateShipmentDriveThrough(id, waypointId);
              }}
              formattedPlace={formattedPlace}
              formattedDateTime={formattedDateTime}
            />
          )}
          <BoardCardDetailRow
            classes={classes}
            noCargoLabel={t("board.noCargo")}
            shortCargoTitle={shortCargoTitle}
            shortCargoTitleLimited={shortCargoTitleLimited}
            customerPrice={customerPrice}
            currency={currency}
            vehicle={vehicle}
            language={i18n.language}
          />
        </div>
        <div className={classes.divider} />
        <div className={classes.bottomLine}>
          <BoardCardAvatarGroup
            t={t}
            createdBy={createdBy}
            customer={customer}
            carrier={carrier}
            driver={driver}
          />
          {hasError ?
            <Tooltip title={t("board.wrongColumn")}>
              <Error style={{ width: 24, height: 24, color: red[700] }} />
            </Tooltip>
            : null}
          <BoardCardActions
            t={t}
            history={history}
            id={id}
            hovered={hovered}
            openDeleteDialog={openDeleteDialog}
            hasCreateShipmentPermission={hasCreateShipmentPermission}
            hasUpdateShipmentPermission={hasUpdateShipmentPermission}
            hasDeleteShipmentPermission={hasDeleteShipmentPermission}
          />
        </div>
      </div>
    </Paper>
  );
}

export default BoardCardXL;