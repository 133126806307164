import React, { useState } from "react";

import { Fab, IconButton, makeStyles, Tooltip, Typography, } from "@material-ui/core";

import { ArrowDownward, ArrowUpward, EmojiObjectsOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { ContactType } from "@cargoticcom/model";

import DataTable from "../../../datatable";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import SearchTextfield from "../../common/SearchTextfield";
import SendOrderDialog from "../../../incomingOrder/component/SendOrderDialog";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterNumberRange from "../../../../cargotic-webapp-filter/component/FilterNumberRange";
import ContactFilterDropdown from "../../../../cargotic-webapp-filter/component/ContactFilterDropdown";
import { WarehouseOrderDialogModeEnum, WarehouseOrdersModeEnum, WarehouseOrderStateEnum } from "../../enums/enums";
import ReceiveIncomingOrdersDialog from "./ReceiveIncomingOrdersDialog";
import DispatchIncomingOrdersDialog from "./DispatchIncomingOrdersDialog";
import StateFilterDropdown from "../../../../cargotic-webapp-filter/component/StateFilterDropdown";
import { loadAvailableWarehouseStates } from "./utils/utils";
import AvailableCargoItemTemplates from "../../../../../multiload/cargotic-core/model/AvailableCargoItemTemplates";

const useStyles = makeStyles(({ palette }) => ({
	danger: {
		background: palette.status.danger,
		color: "white",
		"&:hover": {
			background: palette.status.danger,
		},
	},
	pressedChip: {
		fontWeight: "bold",
		filter: "brightness(20%)",
	},
}));

const DispatchIncomingOrders = ({
	data,
	mode,
	dataCount,
	selectedColumns,
	incomingOrders,
	setIncomingOrders,
	search,
	loading,
	ordering,
	direction,
	page,
	rowsPerPage,
	checkedAll,
	canCreateIncomingOrder,
	canUpdateIncomingOrder,
	canDeleteIncomingOrder,
	canReadCompanyIncomingOrder,
	additionalFilter: {
		type: additionalFilterType,
		lastWaypointDateFrom: additionalFilterLastDateFrom,
	},
	handleSearch,
	handleSort,
	handleChangePage,
	handleChangeRowsPerPage,
	handleIncomingOrdersExportRequest,
	handleSelectAll,
	handleSelect,
	handleDeleteRequest,
	filter,
	handleSelectIncomingOrderState,
	handleSelectWarehouseOrderState,
	handleDeselect,
	handleSelectLoadingsDateRange,
	handleSelectUnloadingsDateRange,
	handleSelectCreatedAtDateRange,
	handleSelectReceivedDateDateRange,
	handleSelectDeliveryDateDateRange,
	handleSelectCreators,
	handleSelectReceivers,
	loadAvailableUsers,
	handleSelectCustomer,
	handleSelectCustomerPrice,
	loadAvailableContacts,
	handleFilterSettingsOpen,
	defaultFilters,
	handleSelectCargoTemplate,
	handleSelectIsDraft,
	handleSendOrder,
	handleChangeSelectedColumns
}) => {
	const { history } = useRouter();
	const classes = useStyles();
	const { t } = useTranslation();

	const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] =
		useState(false);

	const [sendOrderDialogOpen, setSendOrderDialogOpen] = useState(false);
	const [sendOrderDialogData, setSendOrderDialogData] = useState({});

	const [warehouseDispatchDialogOpen, setWarehouseDispatchDialogOpen] = useState(false);
	const [warehouseReceiveDialogOpen, setWarehouseReceiveDialogOpen] = useState(false);

	const [loadingPayAction, setLoadingPayAction] = useState(false);

	const expandFilters = (values, fullValues) =>
		values.map((item) => fullValues.find((i) => i.value === item));


	const handleSendOrderSubmit = async ({
		email,
		message,
		language,
		isSendCopyToMeChecked,
	}) => {
		setSendOrderDialogOpen(false);
		await handleSendOrder(sendOrderDialogData.id, {
			email,
			message,
			language,
			isSendCopyToMeChecked,
		});
	};

	const selected = loading
		? null
		: data.filter(({ selected }) => selected).length;

	const warehouseOrdersHeader = [
		{
			name: "indexNumber",
			label: "incomingOrders.number",
			isOrderable: true,
			width: "10%",
		},
		{
			name: "customerContact",
			label: "incomingOrders.customer",
			isOrderable: false,
		},
		{
			name: "route",
			label: "incomingOrders.route",
			isOrderable: false,
		},
		{
			name: "warehouseState",
			label: "incomingOrders.warehouseState",
			isOrderable: false,
			width: "6%",
		},
		{
			name: "customerPrice",
			label: "incomingOrders.customerPrice",
			isOrderable: false,
		},
		{
			name: "packages",
			label: "incomingOrders.warehousePackages",
			isOrderable: false,
		},
		{
			name: "receivedDate",
			label: "incomingOrders.warehouseReceivedDate",
			isOrderable: false,
		},
		{
			name: "receivedBy",
			label: "incomingOrders.warehouseReceivedBy",
			isOrderable: false,
		},
	];

	const isAnyFilterActive = () => {
		const {
			types,
			drivers,
			carrierPrice,
			customerPrice,
			commission,
			carriers,
			customers,
			creators,
			cargo,
			loadingDate,
			createdAt,
			issuedPaymentState,
			receivedPaymentState,
			vehicles,
			isDraft,
		} = filter;

		return [
			types,
			drivers,
			carrierPrice,
			customerPrice,
			commission,
			carriers,
			customers,
			creators,
			cargo,
			loadingDate,
			createdAt,
			issuedPaymentState,
			receivedPaymentState,
			vehicles,
			isDraft,
		].some((item) => item !== undefined);
	};

	const warehouseOrdersFilters = [
		<ContactFilterDropdown
			id="customers"
			key="customers"
			onChange={(value) =>
				handleSelectCustomer(value.length === 0 ? undefined : value)
			}
			selectAll={(all) => handleSelectCustomer(all)}
			value={filter.customers ?? []}
			search={(text) => loadAvailableContacts(ContactType.CUSTOMER, text)}
			placeholderTitle={t("contacts.customer")}
			onClear={() => handleSelectCustomer(undefined)}
			getTitle={(item) => item.name}
		/>,
		<FilterDateRangeDropdown
			id="loadingDate"
			key="loadingDate"
			onChange={(value) => handleSelectLoadingsDateRange(value)}
			value={
				filter.loadingDate
					? [filter.loadingDate.from, filter.loadingDate.to]
					: []
			}
			onClear={() => handleSelectLoadingsDateRange(undefined)}
			placeholderTitle={t("incomingOrders.loadingsDateRange")}
		/>,
		<FilterDateRangeDropdown
			id="unloadingDate"
			key="unloadingDate"
			onChange={(value) => handleSelectUnloadingsDateRange(value)}
			value={
				filter.unloadingDate
					? [filter.unloadingDate.from, filter.unloadingDate.to]
					: []
			}
			onClear={() => handleSelectUnloadingsDateRange(undefined)}
			placeholderTitle={t("incomingOrders.unloadingsDateRange")}
		/>,
		<FilterDateRangeDropdown
			id="createdAt"
			key="createdAt"
			onChange={(value) => handleSelectCreatedAtDateRange(value)}
			value={
				filter.createdAt
					? [filter.createdAt.from, filter.createdAt.to]
					: []
			}
			onClear={() => handleSelectCreatedAtDateRange(undefined)}
			placeholderTitle={t("incomingOrders.creationDate")}
		/>,
		<FilterDateRangeDropdown
			id="receivedDate"
			key="receivedDate"
			onChange={(value) => handleSelectReceivedDateDateRange(value)}
			value={
				filter.receivedDate
					? [filter.receivedDate.from, filter.receivedDate.to]
					: []
			}
			onClear={() => handleSelectReceivedDateDateRange(undefined)}
			placeholderTitle={t("incomingOrders.warehouseReceivedDate")}
		/>,
		<FilterNumberRange
			id="customerPrice"
			key="customerPrice"
			onChange={(value) => handleSelectCustomerPrice(value)}
			value={filter.customerPrice}
			placeholderTitle={t("incomingOrders.customerPrice")}
			onClear={() => handleSelectCustomerPrice(undefined)}
		/>,
		<UserFilterDropdown
			id="creators"
			key="creators"
			onChange={(value) =>
				handleSelectCreators(value.length === 0 ? undefined : value)
			}
			selectAll={(all) => handleSelectCreators(all)}
			value={filter.creators ?? []}
			placeholderTitle={t("incomingOrders.creator")}
			search={(text) => loadAvailableUsers(text)}
			onClear={() => handleSelectCreators(undefined)}
			getTitle={(item) => item.name}
		// disabled={!canReadCompanyIncomingOrder}
		/>,
		<UserFilterDropdown
			id="receivers"
			key="receivers"
			onChange={(value) =>
				handleSelectReceivers(value.length === 0 ? undefined : value)
			}
			selectAll={(all) => handleSelectReceivers(all)}
			value={filter.receivers ?? []}
			placeholderTitle={t("incomingOrders.warehouseReceivedBy")}
			search={(text) => loadAvailableUsers(text)}
			onClear={() => handleSelectReceivers(undefined)}
			getTitle={(item) => item.name}
		// disabled={!canReadCompanyIncomingOrder}
		/>,
		<FilterStaticDropdown
			id="cargo"
			key="cargo"
			placeholderTitle={t("incomingOrders.cargo")}
			value={filter.cargo ?? []}
			onChange={(arr) =>
				handleSelectCargoTemplate(arr.length === 0 ? undefined : arr)
			}
			selectAll={() =>
				handleSelectCargoTemplate(AvailableCargoItemTemplates.map((item) => item.value))
			}
			onClear={() => handleSelectCargoTemplate(undefined)}
			source={AvailableCargoItemTemplates.map(({ label, value }) => ({ value, label: t(label) }))}
		/>,
		<StateFilterDropdown
			id="warehouseState"
			key="warehouseState"
			placeholderTitle={t("board.warehouseState.title")}
			value={filter.state ?? []}
			onChange={(arr) =>
				handleSelectWarehouseOrderState(arr.length === 0 ? undefined : arr)
			}
			search={(text) => loadAvailableWarehouseStates(text)}
			selectAll={(all) =>
				handleSelectWarehouseOrderState(all)
			}
			onClear={() => handleSelectWarehouseOrderState(undefined)}
			getTitle={(item) => item.name}
		/>,
	]

	return (
		<Page
			title={ }
			actions={
				<>
					{mode === WarehouseOrdersModeEnum.INCOMING_ORDERS && (
						<>
							<Tooltip title={t("incomingOrders.warehouseOrderReceive")}>
								<span>
									<Fab
										color="primary"
										// disabled={loading || !canCreateIncomingOrder}
										onClick={() => {
											setWarehouseReceiveDialogOpen(true)
										}}
									>
										<ArrowDownward />
									</Fab>
								</span>
							</Tooltip>
							<Tooltip title={t("incomingOrders.warehouseOrderDispatch")}>
								<span>
									<Fab
										color="inherit"
										// disabled={loading || !canCreateIncomingOrder}
										onClick={() => {
											setWarehouseDispatchDialogOpen(true)
										}}
									>
										<ArrowUpward />
									</Fab>
								</span>
							</Tooltip>

						</>
					)}
				</>
			}
			tertiaryActions={ }
		>
			<FilterContainer
				searchField={
					<SearchTextfield
						handleSearch={handleSearch}
						value={search}
						fullWidth
						placeholder={t("incomingOrders.searchTooltip")}
					/>
				}
				showClearButton={isAnyFilterActive}
				handleFilterSettingsOpen={handleFilterSettingsOpen}
				onOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
				defaultFilters={defaultFilters}
				loading={loading}
				filters={warehouseOrdersFilters}
				onClear={handleDeselect}
			/>
			<DataTable
				headers={warehouseOrdersHeader}
				actions={[]}
				selectedColumns={selectedColumns}
				data={data}
				dataCount={dataCount}
				loading={loading || loadingPayAction}
				ordering={ordering}
				direction={direction}
				checkedAll={checkedAll}
				page={page}
				rowsPerPage={rowsPerPage}
				enableDetail={false}
				enableDetails={true}
				enableMultipleDetails={true}
				onSort={handleSort}
				onSelect={handleSelect}
				onSelectAll={handleSelectAll}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
				setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
				onChangeSelectedColumns={handleChangeSelectedColumns}
			/>
			<ReceiveIncomingOrdersDialog
				open={warehouseReceiveDialogOpen}
				handleClose={() => setWarehouseReceiveDialogOpen(false)}
				handleSubmit={() => null}
			/>
			<DispatchIncomingOrdersDialog
				open={warehouseDispatchDialogOpen}
				handleClose={() => setWarehouseDispatchDialogOpen(false)}
				handleSubmit={() => null}
			/>
			<SendOrderDialog
				email={sendOrderDialogData.email}
				message={t("webapp:incomingOrder.send-order.label.default-text", {
					number: sendOrderDialogData.orderSerialNumber,
				})}
				language="cs"
				shipmentId={sendOrderDialogData.id}
				isOpen={sendOrderDialogOpen}
				handleSubmit={handleSendOrderSubmit}
				handleClose={() => setSendOrderDialogOpen(false)}
			/>
		</Page>
	);
};

export default DispatchIncomingOrders;
