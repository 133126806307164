import { useFormik } from "formik2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { VehicleExpirationType } from "@cargoticcom/model";

import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Step,
  StepButton,
  StepContent,
  Stepper,
  makeStyles,
  Collapse
} from "@material-ui/core";

import {
  DrawerDialog,
  FormikDatePicker,
  FormikSelect,
  FormikTextField,
  ImageFileDropzone
} from "../../../cargotic-webapp-component";

import { generateUuid } from "../../../../multiload/cargotic-common";

import VehicleExpirationIcon from "../VehicleExpirationIcon";
import VehicleExpirationFileDropzoneGroup
  from "../VehicleExpirationFileDropzoneGroup";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
  select: {
    display: "flex",
    alignItems: "center"
  },
  dialog: {
    maxWidth: 560
  }
}));

const VehicleExpirationEditor = ({
  initialValue = {},
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const VehicleExpirationSchema = Yup.object({
    type: Yup.string()
      .required(t("webapp:vehicle.validate.expiration-type-required")),
    expiresAt: Yup.date().typeError(t("webapp:vehicle.validate.expiration-expires-at-required"))
      .required(t("webapp:vehicle.validate.expiration-expires-at-required")),
    note: Yup.string().max(255, t("webapp:vehicle.validate.expiration-note"))
  });

  const [activeStep, setActiveStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState();

  const initialId = initialValue.id;
  const initialName = initialValue.name || null;
  const initialType = initialValue.type || "";
  const initialExpiresAt = initialValue.expiresAt || null;
  const initialFiles = initialValue.images
    ? initialValue.images.map(file => ({ ...file, uuid: generateUuid() }))
    : [];

  const initialNote = initialValue.note || "";

  const generalForm = useFormik({
    initialValues: {
      name: initialName,
      type: initialType,
      expiresAt: initialExpiresAt,
      note: initialNote
    },
    validationSchema: VehicleExpirationSchema,
    onSubmit: () => setActiveStep(1)
  });

  const fileForm = useFormik({
    initialValues: {
      files: initialFiles
    },
    onSubmit: ({ files }) => {
      const { type, name, expiresAt, note } = generalForm.values;

      const expiration = {
        id: initialId,
        type,
        name,
        expiresAt,
        note: note || undefined,
        images: files.length > 0
          ? files.map(({ id }) => ({ id }))
          : undefined
      };

      if (onSubmit) {
        onSubmit(expiration);
      }
    }
  });

  const handleNextButtonClick = async () => {
    if (activeStep === 0) {
      try {
        await generalForm.submitForm();

        return;
      } catch (error) {
        console.log(error);

        return;
      }
    }

    if (activeStep === 1) {
      try {
        await fileForm.submitForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePreviousButtonClick = () => {
    if (activeStep === 0) {
      onClose();

      return;
    }

    setActiveStep(activeStep - 1);
  };

  const handleStepChange = step => {
    if (activeStep === 0 && step === 1) {
      generalForm.submitForm();
    } else {
      setActiveStep(step);
    }
  };

  useEffect(() => {
    generalForm.resetForm({
      errors: {},
      values: {
        type: initialType,
        name: initialName,
        expiresAt: initialExpiresAt,
        note: initialNote
      }
    });

    fileForm.resetForm({
      errors: {},
      values: {
        files: initialFiles
      }
    });

    setActiveStep(0);
    setSelectedFile(undefined);
  }, [isOpen]);

  const steps = [
    [
      t("webapp:vehicle.title.general-information"),
      <div key={0}>
        <div>
          <FormikSelect
            className={classes.select}
            form={generalForm}
            name="type"
            label={t("webapp:vehicle.title.expiration-type")}
            disabled={initialType !== ""}
            required
            fullWidth
          >
            {Object.values(VehicleExpirationType).map(value => (
              <MenuItem key={value} value={value}>
                <ListItemIcon>
                  <VehicleExpirationIcon type={value} />
                </ListItemIcon>
                <ListItemText
                  primary={t(`webapp:vehicle.expiration.${value}`)}
                />
              </MenuItem>
            ))}
          </FormikSelect>
        </div>
        <div>
            <Collapse
              in={generalForm.values.type === VehicleExpirationType.OTHER}
            >
              <FormikTextField
                form={generalForm}
                name="name"
                label={t("webapp:vehicle.title.expiration-name")}
                fullWidth
              />
            </Collapse>
        </div>
        <div>
          <FormikDatePicker
            form={generalForm}
            name="expiresAt"
            label={t("webapp:vehicle.title.expiration-expires-at")}
            required
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={generalForm}
            name="note"
            label={t("webapp:vehicle.title.expiration-note")}
            fullWidth
          />
        </div>
      </div>
    ],
    [
      t("webapp:vehicle.title.files"),
      <div key={1}>
        <div>
          <VehicleExpirationFileDropzoneGroup
            form={fileForm}
            name="files"
          />
        </div>
      </div>
    ]
  ];

  return (
    <>
      <DrawerDialog
        title={t("webapp:vehicle.title.expiration")}
        className={classes.dialog}
        actions={(
          <div className={classes.actions}>
            <Button variant="contained" onClick={handlePreviousButtonClick}>
              {
                activeStep === 0
                  ? t("webapp:common.title.cancel")
                  : t("webapp:common.title.back")
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextButtonClick}
            >
              {
                activeStep === 1
                  ? t("webapp:common.title.complete")
                  : t("webapp:common.title.continue")
              }
            </Button>
          </div>
        )}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(([label, content], step) => (
            <Step key={label}>
              <StepButton onClick={() => handleStepChange(step)}>
                {label}
              </StepButton>
              <StepContent>
                {content}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DrawerDialog>
    </>
  );
};

export default VehicleExpirationEditor;
