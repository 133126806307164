import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { deleteShipmentDocument, setContactSharingUsers, uploadShipmentDocument } from "@cargotic/api-client-deprecated";

import { Currency } from "@cargotic/currency";
import { ContactType } from "@cargoticcom/model";
import {
  Fab,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
} from "@material-ui/core";
import { AccountCircle, Edit as EditIcon, Work } from "@material-ui/icons";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { usePrevious } from "@cargotic/common-deprecated";
import { VehicleType } from "@cargoticcom/model";
import useAuth from "../../../component/hook/useAuth";

import { ContactFormAutosuggestTextField, EmployeeFormAutosuggestTextField } from "../../../contact";
import PlaceSearchFailDialog from "../../../component/common/PlaceSearchFailDialog";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";
import EmployeeEditor from "../../../../cargotic-webapp-contact/component/ContactEmployeeEditor";
import { useApiClient } from "../../../../cargotic-webapp-component";

import VehicleEditor from "../../../../cargotic-webapp-vehicle/component/VehicleEditor";

import { FormCheckbox, FormSelect, FormTextField } from "../../../form";

import { createContact, updateContact, postFile, getIncomingOrderServices, deleteFile } from "../../../resource";

import { formatDateTime, generateUuid } from "../../../../../multiload/cargotic-common";

import FileDropzone from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload from "../../../../../multiload/cargotic-webapp/component/FileUpload";

import { ErrorHandler } from "../../../../cargotic-webapp-error";
import IncomingOrderServicesInput from "./IncomingOrderServicesInput";
import Placeholder from "../../../../cargotic-webapp/component/common/Placeholder";
import AttachmentPreview from "../../../../cargotic-webapp-component/component/AttachmentPreview";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > section": {
      "&:not(:first-child)": {
        marginTop: spacing(4)
      },
      "& > h6": {
        marginBottom: spacing(3)
      }
    }
  },
  fab: {
    display: "flex",
    justifyContent: "center"
  },
  preview: {
    backgroundSize: "contain",
    width: "100%",
    height: "auto"
  }
}));

const IncomingOrderFormContent = ({
  className,
  form,
  apiClient,
  onDocumentsChange,
  initialCustomerPaymentDueDays
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { hasPermission } = useAuth();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const client = useApiClient();
  const handleError = ErrorHandler(
    t,
    message => enqueueSnackbar(message, { variant: "error" })
  );

  const [availableServices, setAvailableServices] = useState([]);

  const previousValues = usePrevious(form.values);

  const [
    hasTermsDocumentFileDropzoneError,
    setHasTermsDocumentFileDropzoneError
  ] = useState(false);
  const [
    hasAdditionalDocumentFileDropzoneError,
    setHasAdditionalDocumentFileDropzoneError
  ] = useState(false);

  const isVehicleComplete = useRef(false);
  const isTrailerComplete = useRef(false);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [isContactEditorOpen, setIsContactEditorOpen] = useState(false);
  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [customerUpdateDialogOpen, setCustomerUpdateDialogOpen] = useState(false);
  const [customerCreateDialogOpen, setCustomerCreateDialogOpen] = useState(false);
  const [employeeEditorOpen, setEmployeeEditorOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState({});
  const [selectedVehicleType, setSelectedVehicleType] = useState();


  const [optionTags, setOptionTags] = useState([]);
  const [sharingUsers, setSharingUsers] = useState([]);

  const canUpdateCompanyContact = hasPermission("resource.contact.company.update");

  const handleCustomerContactEditDialogClose = async () => setCustomerUpdateDialogOpen(false);

  const handleCustomerUpdateSubmit = (contact) => {
    setCustomerUpdateDialogOpen(false);
    updateContact(contact)
      .then(async () => {
        const newContact = await apiClient.contact.findContact({ contactId: contact.id });
        form.setValue("customerContact", newContact);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("contacts.error.update"), {
          variant: "error"
        });
      });
  };

  const handleDocumentDelete = async (uuid) => {
    const { url, bucket, name } = form.values.documents
      .find(({ uuid: other }) => other === uuid);

    if (url) {
      try {
        await deleteFile(bucket, name);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(
          t("webapp:incomingOrder.form.document.error.file.delete"),
          { variant: "error" }
        );

        return;
      }
    }

    form.setValues(values => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid)
    }));
  };

  const handleTermsDocumentFileDropzoneError = () => setHasTermsDocumentFileDropzoneError(true);
  const handleAdditionalDocumentFileDropzoneError = () => setHasAdditionalDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = (files, form, bucket) => {
    setHasAdditionalDocumentFileDropzoneError(false);
    setHasTermsDocumentFileDropzoneError(false);
    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true,
        bucket,
      };

      const updateDocument = update => form.setValues(values => ({
        ...values,
        documents: values.documents
          .map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          })
      }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document]
      }));

      try {
        const { url, createdAt, id } = await postFile({
          bucket,
          file
        });
        updateDocument({
          id, url, createdAt: new Date(createdAt), isUploading: false, bucket
        });
      } catch (error) {
        console.log(error);

        let message = t("webapp:incomingOrder.form.document.error.upload.general");

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:incomingOrder.form.document.error.upload.size");
        }

        if (error?.response?.data?.error === "StorageLimitExceeded") {
          message = t("webapp:incomingOrder.form.document.error.upload.limit");
        }

        updateDocument({
          isUploading: false,
          error: message
        });
      }
    });
  };

  const fetchContact = async (contactId) => apiClient.contact.getContact({ contactId });

  const handleCustomerContactChange = async () => {
    const { values: { customerContact } } = form;
    const { customerContact: oldCustomer } = previousValues ?? {};

    if (!customerContact || (oldCustomer && oldCustomer.id !== customerContact.id)) {
      form.setValue("customerEmployee", "");
    }

    if (typeof form.values.customerContact === "string") {
      return;
    }

    if (customerContact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", customerContact.paymentDueDays);
    }

    if (customerContact?.billingAddress) {
      return;
    }

    const contact = await fetchContact(customerContact.id);
    if (contact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", contact.paymentDueDays);
    }

    form.setValue("customerContact", contact);
  };

  useEffect(() => {
    getIncomingOrderServices()
      .then(services => {
        setAvailableServices(services.map(({ name, type }) => ({ name, type })));
      });
  }, []);

  useEffect(() => {
    handleCustomerContactChange();
  }, [form.values.customerContact]);

  useEffect(() => {
    onDocumentsChange();
  }, [form.values.documents]);

  const handleEmployeeSubmit = async employee => {
    try {
      if (employee.id) {
        await apiClient.contact.putContactEmployee({
          contactId: employee.contactId,
          employeeId: employee.id,
          employee: {
            email: employee.email,
            name: employee.name,
            phoneNumber: employee.phoneNumber
          }
        });
      } else {
        const { id } = await apiClient.contact.postContactEmployee({
          contactId: employee.contactId,
          employee: {
            name: employee.name,
            email: employee.email,
            phoneNumber: employee.phoneNumber
          }
        });
        employee = { ...employee, id };
      }
      if (employee.contactId === form.values.customerContact.id) {
        form.setValue("customerEmployee", employee);
      } else {
        form.setValue("carrierEmployee", employee);
      }
      setEmployeeEditorOpen(false);
    } catch (err) {
      console.log(err);
      if (employee.id) {
        enqueueSnackbar(t("contacts.employees.error.update"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("contacts.employees.error.create"), {
          variant: "error"
        });
      }
    }
  };

  const disableCustomerContactButton = () => {
    const { values: { customerContact } } = form;

    if (typeof customerContact === "string" && customerContact !== "") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCustomerEmployeeButton = () => {
    const { values: { customerContact, customerEmployee } } = form;

    if (typeof customerContact === "string") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    if (typeof customerEmployee === "string" && customerEmployee !== "") {
      return true;
    }

    return false;
  };

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    setIsContactEditorOpen(false);

    try {
      let result;

      if (contactId) {
        result = await client.contact.putContact({ contactId, contact });
      } else {
        result = await client.contact.postContact({ contact });
      }

      form.setValue("customerContact", result);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:incomingOrder.form.subtitle.customer")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerContactButton()}
                onClick={() => {
                  setSelectedContact(form.values.customerContact || { type: ContactType.CUSTOMER });
                  setIsContactEditorOpen(true);
                }}
              >
                {
                  form.values.customerContact
                    ? (
                      <EditIcon />
                    ) : (
                      <AccountCircle />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <ContactFormAutosuggestTextField
                form={form}
                contactType={ContactType.CUSTOMER}
                apiClient={apiClient}
                name="customerContact"
                label={t("webapp:incomingOrder.form.label.contact")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerEmployeeButton()}
                onClick={() => {
                  setEmployeeToEdit({ contactId: form.values.customerContact.id, ...(form.values.customerEmployee) });
                  setEmployeeEditorOpen(true);
                }}
              >
                {
                  form.values.customerEmployee
                    ? (
                      <EditIcon />
                    ) : (
                      <Work />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <EmployeeFormAutosuggestTextField
                form={form}
                apiClient={apiClient}
                disabled={!form.values.customerContact?.id}
                contactId={form.values.customerContact?.id}
                name="customerEmployee"
                label={t("webapp:incomingOrder.form.label.employee")}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={7}>
              <FormTextField
                form={form}
                name="externalReference"
                label={t("webapp:incomingOrder.form.label.externalReference")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextField
                form={form}
                name="customerPaymentDueDays"
                label={t("webapp:incomingOrder.form.label.dueDays")}
                hasFullWidth
              />
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("incomingOrders.services")}
          </Typography>
          <Placeholder
            loading={!availableServices || !availableServices.length}
            render={() =>
              <IncomingOrderServicesInput
                form={form}
                name="services"
                availableServices={availableServices}
              />
            }
          />
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("incomingOrders.totalPrice")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <FormTextField
                form={form}
                name="customerPrice"
                label={t("webapp:incomingOrder.form.label.price")}
                hasFullWidth
                isDisabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelect
                form={form}
                name="customerPriceCurrency"
                label={t("webapp:incomingOrder.form.label.currency")}
                hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={3}>
              {/* <FormCheckbox
                form={form}
                name="isCustomerPriceWithDph"
                label={t("webapp:incomingOrder.form.label.isWithDph")}
                color="primary"
              /> */}
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:incomingOrder.form.subtitle.documents")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="notes"
                label={t("webapp:incomingOrder.form.label.notes")}
                variant="outlined"
                rows={6}
                isMultiline
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.caption}>{t("webapp:common.title.terms-documents")}</Typography>
                <FileDropzone
                  accept={["application/pdf", "image/jpeg", "image/png"]}
                  title={
                    hasTermsDocumentFileDropzoneError
                      ? t("webapp:shipment.form.document.error.file.type")
                      : t("webapp:shipment.form.document.title.upload")
                  }
                  description={[
                    t("webapp:shipment.form.document.description.about"),
                    t("webapp:shipment.form.document.description.supported"),
                    t("webapp:shipment.form.document.description.limit"),
                  ]}
                  hasError={hasTermsDocumentFileDropzoneError}
                  onError={handleTermsDocumentFileDropzoneError}
                  onSelect={(files) => handleDocumentFilesSelect(files, form, "cgt-staging-incoming-order-terms-documents")}
                />
                {form.values.documents.filter(({ bucket }) => bucket === "cgt-staging-incoming-order-terms-documents" || bucket === 'incoming-order-terms-documents' || bucket ==='cgt-staging-fakturoid-invoice').map(
                  ({ uuid, name, url, error, isUploading, createdAt }, index) => (
                    <React.Fragment key={uuid}>
                      <Grid item xs={1} />
                      <Grid item xs={11}>
                        <FileUpload
                          className={index === 0 ? classes.upload : undefined}
                          title={name}
                          description={
                            createdAt ? formatDateTime(createdAt) : undefined
                          }
                          url={url}
                          isUploading={isUploading}
                          error={error}
                          onDelete={() => handleDocumentDelete(uuid)}
                        />
                      </Grid>
                      <AttachmentPreview url={url} name={name}></AttachmentPreview>
                    </React.Fragment>
                  )
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.caption}>{t("webapp:common.title.additional-documents")}</Typography>
                <FileDropzone
                  accept={["application/pdf", "image/jpeg", "image/png"]}
                  title={
                    hasAdditionalDocumentFileDropzoneError
                      ? t("webapp:shipment.form.document.error.file.type")
                      : t("webapp:shipment.form.document.title.upload")
                  }
                  description={[
                    t("webapp:shipment.form.document.description.about"),
                    t("webapp:shipment.form.document.description.supported"),
                    t("webapp:shipment.form.document.description.limit"),
                  ]}
                  hasError={hasAdditionalDocumentFileDropzoneError}
                  onError={handleAdditionalDocumentFileDropzoneError}
                  onSelect={(files) => handleDocumentFilesSelect(files, form, "cgt-staging-incoming-order-additional-documents")}
                />

                {form.values.documents.filter(({ bucket }) => bucket === 'cgt-staging-incoming-order-additional-documents' || bucket === 'incoming-order-additional-documents' || bucket === 'incoming-order-documents').map(
                  ({ uuid, name, url, error, isUploading, createdAt }, index) => (
                    <React.Fragment key={uuid}>
                      <Grid item xs={1} />
                      <Grid item xs={11}>
                        <FileUpload
                          className={index === 0 ? classes.upload : undefined}
                          title={name}
                          description={
                            createdAt ? formatDateTime(createdAt) : undefined
                          }
                          url={url}
                          isUploading={isUploading}
                          error={error}
                          onDelete={() => handleDocumentDelete(uuid)}
                        />
                        <AttachmentPreview url={url} name={name}></AttachmentPreview>
                      </Grid>
                    </React.Fragment>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      <ContactEditor
        initialValue={selectedContact}
        isOpen={isContactEditorOpen}
        type={ContactType.CUSTOMER}
        onClose={() => setIsContactEditorOpen(false)}
        onSubmit={handleContactEditorSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
      <EmployeeEditor
        initialValue={employeeToEdit}
        isOpen={employeeEditorOpen}
        onClose={() => setEmployeeEditorOpen(false)}
        onSubmit={handleEmployeeSubmit}
      />
    </>
  );
};

export default IncomingOrderFormContent;
