import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Button,
  MenuItem
} from "@material-ui/core";

import { DrawerDialog, FormikTextField, FormikSelect, FormikDatePicker } from "../../../cargotic-webapp-component";
import { useFormik } from "formik2";
import { Currency } from "@cargoticcom/common";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },
}));

const currentDate = new Date();

const DirectVehicleIncomeEditor = ({ income, isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: income?.id ?? undefined,
      accountedAt: income?.accountedAt ?? currentDate,
      description: income?.description ?? undefined,
      value: income?.value ?? undefined,
      currency: income?.currency ?? Currency.CZK
    },
    validationSchema: Yup.object({
      description: Yup.string().max(128, t("webapp:common.validate.value")).required(t("webapp:common.validate.value")),
      value: Yup.number().typeError(t("webapp:common.validate.value")).required(t("webapp:common.validate.value")),
      accountedAt: Yup.date().typeError(t("webapp:vehicle.validate.date")).required(t("webapp:vehicle.validate.date"))
    }),
    onSubmit: (income) => {
      form.resetForm();
      onSubmit(income);
    }
  });

  const onDialogClose = () => {
    form.resetForm();
    onClose();
  }

  return (
    <>
      <DrawerDialog
        title={(income?.id) ? t("webapp:vehicle.title.edit-income") : t("webapp:vehicle.title.add-income")}
        actions={(
          <div className={classes.actions}>
            <Button variant="contained" onClick={onDialogClose}>
              {t("webapp:common.title.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => form.submitForm()}
            >
              {t("webapp:common.title.complete")}
            </Button>
          </div>
        )}
        isOpen={isOpen}
        onClose={onDialogClose}
      >
        <FormikDatePicker
          form={form}
          name="accountedAt"
          label={t("webapp:vehicle.title.accountedAt")}
          format="dd.MM.yyyy"
          fullWidth
        />
        <FormikTextField
          form={form}
          inputProps={{
            maxLength: 64
          }}
          name="description"
          label={t("webapp:vehicle.title.description")}
          required
          fullWidth
        />
        <FormikTextField
          form={form}
          name="value"
          type="number"
          label={t("webapp:vehicle.title.value")}
          required
          fullWidth
        />
        <FormikSelect
          form={form}
          name="currency"
          label={t("webapp:vehicle.title.currency")}
          required
          fullWidth
        >
          <MenuItem value={Currency.CZK}>{Currency.CZK}</MenuItem>
          <MenuItem value={Currency.EUR}>{Currency.EUR}</MenuItem>
        </FormikSelect>
      </DrawerDialog>
    </>
  );
};

export default DirectVehicleIncomeEditor;
