import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { MenuItem, IconButton, Box, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";

// import Select from "@cargotic/webapp-component/component/FormikSelect";
import { InvoiceVatType } from "@cargotic/webapp/component/enums/enums";

import { renderTextField, renderSelect } from "../formHelpers";
import Summary from "../Summary";
import InvoiceItemForm from "./InvoiceItemForm";

const DEFAULT_INVOICE_ITEM = {
  dph: 21,
  dphType: Object.values(InvoiceVatType)[0],
  dphInclusion: 0,
  count: 1,
};

export function InvoiceItems({ helpers: { push, remove }, form }) {
  const { t } = useTranslation();
  const { initialValues, values } = form;
  const { items } = values;
  const itemsCount = items?.length || 0;
  const total = useMemo(
    () =>
      Math.round(
        (items.reduce((acc, value) => {
          const { dphInclusion, dph, unitPrice, count = 1 } = value;
          return (
            acc +
            (parseInt(count) *
              (dphInclusion
                ? parseInt(unitPrice)
                : (1 + parseInt(dph) / 100) * unitPrice) || 0)
          );
        }, 0) +
          Number.EPSILON) *
          100
      ) / 100,
    [items]
  );
  const totalVAT = useMemo(
    () =>
      Math.round(
        (items.reduce((acc, value) => {
          const { dphInclusion, dph, unitPrice, count = 1 } = value;
          return (
            acc + (parseInt(count) * parseInt(unitPrice) * (parseInt(dph) / 100) || 0)
          );
        }, 0) +
          Number.EPSILON) *
          100
      ) / 100,
    [items]
  );

  const totalWithoutVAT = useMemo(
    () =>
      Math.round(
        (items.reduce((acc, value) => {
          const { dphInclusion, dph, unitPrice = 0, count = 1 } = value;
          return (
            acc +
            parseInt(count) *
              (dphInclusion
                ? parseInt(unitPrice) * (1 - parseInt(dph) / 100)
                : parseInt(unitPrice) || 0)
          );
        }, 0) +
          Number.EPSILON) *
          100
      ) / 100,
    [items]
  );

  return (
    <Box>
      <Box display="flex" pt={4} pb={4}>
        <Box alignSelf="flex-end" pb={1} pr={2}>
          <Fab
            color="primary"
            size="small"
            onClick={() => push({ id: Math.random(), ...DEFAULT_INVOICE_ITEM })}
          >
            <Add />
          </Fab>
        </Box>

        <Box display="flex" flexGrow="2" flexDirection="column">
          <div>
            {items?.map((row, index) => {
              return (
                <div key={`invoice-item-${row?.id}`}>
                  <InvoiceItemForm
                    index={index}
                    form={form}
                    initialValues={initialValues.items?.[index]}
                    onDelete={itemsCount > 1 && remove}
                  />
                </div>
              );
            })}
          </div>
        </Box>
      </Box>

      <Box pt={2}>
        <Summary
          total={total}
          totalVAT={totalVAT}
          totalWithoutVAT={totalWithoutVAT}
          currency={values.currency}
        />
      </Box>
    </Box>
  );
}

InvoiceItems.propTypes = {
  form: PropTypes.object.isRequired,
  helpers: PropTypes.shape({
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
};

InvoiceItems.DEFAULT_INVOICE_ITEM = DEFAULT_INVOICE_ITEM;

export default InvoiceItems;
