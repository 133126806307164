import React, {
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import { CargoActionType } from "@cargoticcom/model";
import { useTheme } from "@material-ui/core";
import { ErrorOutlineSharp } from "@material-ui/icons";

import { compare, firstElement, lastElement } from "../../../cargotic-common";
import {
  calculateRoute,
  searchGooglePlace,
  suggestGooglePlace,
  searchGooglePlaceByCoordinates
} from "../../../cargotic-map";

import {
  CargoItemTemplateSpecification,
  LengthUnit,
  LengthUnitSpecification,
  Waypoint,
  convertUnit
} from "../../../cargotic-core";

import { createPlaceInput } from "../../../../packages/cargotic-webapp/resource";

import { normalizeGooglePlace } from "../../utility";
import JourneyPlanner from "./JourneyPlanner";
import JourneyPlannerAction, {
  addWaypoint,
  addError,
  copyCargoItem,
  deleteCargoItem,
  deleteWaypoint,
  loadCargoItem,
  reloadCargoItem,
  selectCargoItem,
  reorderWaypoints,
  selectWaypoint,
  setDistanceAndDuration,
  setIsPlaceSearchFailAlertOpen,
  updateCargoItem,
  updateWaypoint,
  unloadCargoItem,
  validateWaypointDates,
  validateWaypointPlace,
  validateWaypointPhoneNumber,
  validateWaypointNote,
  validateWaypointContact
} from "./JourneyPlannerAction";

import JourneyPlannerReducer from "./JourneyPlannerReducer";
import JourneyPlannerState from "./JourneyPlannerState";

const CZECH_REPUBLIC_GEOLOCATION = {
  lat: 49.8175,
  lng: 15.4730
};

const round = n =>
  Math.round((n + Number.EPSILON) * 100) / 100;

const JourneyPlannerContainer = ({
  className,
  state,
  dispatch,
  googleMapsApi,
  mapTheme,
  defaultLengthUnit,
  storeDefaultLengthUnit,
  onComplete
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const mapRef = useRef();

  const [map, setMap] = useState();
  const [isCargoEditorVisible, setIsCargoEditorVisible] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [placesService, setPlacesService] = useState();
  const [geocoderService, setGeocoderService] = useState();
  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [renderedPlaceIds, setRenderedPlaceIds] = useState([]);

  const [placesAutocompleteService] = useState(
    () => new googleMapsApi.places.AutocompleteService()
  );

  const [ lastLatLng, setLastLatLng ] = useState(null);

  const {
    selectedCargoItemId,
    selectedWaypointIndex,
    waypoints,
    distance,
    duration,
    errors,
    isPlaceSearchFailAlertOpen
  } = state;

  const waypoint = waypoints[selectedWaypointIndex];
  const cargo = waypoint?.cargo;
  const isCargoItemLoadingDisabled = (
    selectedWaypointIndex === waypoints.length - 1
  );

  const unloadableCargoItems = waypoints.slice(0, selectedWaypointIndex)
    .flatMap(waypoint => waypoint.cargo)
    .filter(({ action }) => action === CargoActionType.LOAD)
    .filter(({ itemId }) => !waypoint.cargo.some(({ itemId: otherItemId }) => (
      itemId === otherItemId
    )));

  const isCargoItemUnloadingDisabled = selectedWaypointIndex === 0;

  const isJourneyComplete =
    waypoints.reduce(
      (isComplete, { place: { googleId } }) => isComplete
        && googleId !== undefined,
      true
    ) && errors.reduce(
      (noErrors, error) => noErrors
        && Object.keys(error).length == 0,
      true
    );

  const findCargoItemById = (id) =>
    cargo.find(({ id: other }) => other === id);

  const handleCargoItemChange = (id, name, type, value) => {
    const {
      action,
      itemId,
      id: otherId,
      ...item
    } = findCargoItemById(id);

    const { template } = item;

    let casted;
    let updated;

    if (type === "number") {
      casted = value === ""
        ? undefined
        : parseFloat(value);

      if (Number.isNaN(casted) || casted < 0) {
        return;
      }
    } else {
      casted = value === "" ? undefined : value;
    }

    if (name === "template") {
      const {
        width,
        height,
        length,
        lengthUnit = defaultLengthUnit
      } = CargoItemTemplateSpecification[casted] || {};

      updated = {
        ...item,
        width,
        height,
        length,
        lengthUnit,
        template: casted,
        isStackable: false
      };
    } else {
      updated = {
        ...item,
        [name]: casted
      };
    }

    if (name === "lengthUnit") {
      const currentTemplate = CargoItemTemplateSpecification[template];

      updated = {
        ...updated,
        width: currentTemplate && currentTemplate.width ? round(convertUnit(
          updated.width,
          item.lengthUnit,
          updated.lengthUnit,
          LengthUnitSpecification
        ))
          : updated.width,
        height: currentTemplate && currentTemplate.height ? round(convertUnit(
          updated.height,
          item.lengthUnit,
          updated.lengthUnit,
          LengthUnitSpecification
        )) : updated.height,
        length: currentTemplate && currentTemplate.length ? round(convertUnit(
          updated.length,
          item.lengthUnit,
          updated.lengthUnit,
          LengthUnitSpecification
        )) : updated.length
      };

      if (!template) {
        storeDefaultLengthUnit(updated.lengthUnit);
      }
    }

    if (
      name === "template"
      || name === "width"
      || name === "height"
      || name === "length"
      || name === "lengthUnit"
    ) {
      const baseWidth = updated.width
        ? convertUnit(
          updated.width,
          updated.lengthUnit,
          LengthUnit.M,
          LengthUnitSpecification
        )
        : undefined;

      const baseHeight = updated.height
        ? convertUnit(
          updated.height,
          updated.lengthUnit,
          LengthUnit.M,
          LengthUnitSpecification
        )
        : undefined;

      const baseLength = updated.length
        ? convertUnit(
          updated.length,
          updated.lengthUnit,
          LengthUnit.M,
          LengthUnitSpecification
        )
        : undefined;

      const volume = baseWidth && baseHeight && baseLength
        ? round(baseWidth * baseHeight * baseLength)
        : undefined;

      updated = {
        ...updated,
        volume
      };
    }

    if (
      name === "template"
      || name === "width"
      || name === "height"
      || name === "length"
      || name === "lengthUnit"
      || name === "quantity"
    ) {
      const { quantity, volume } = updated;

      const totalVolume = quantity && volume
        ? round(quantity * volume)
        : undefined;

      updated = {
        ...updated,
        totalVolume
      };
    }

    if (name === "quantity" || name === "weight") {
      const { quantity, weight } = updated;

      const totalWeight = quantity && weight
        ? round(quantity * weight)
        : undefined;

      updated = {
        ...updated,
        totalWeight
      };
    }

    if (name === "edit-template") {
      updated = {
        ...updated,
        description: t(`core:cargo.item.template.${template}`),
        template: undefined
      };
    }

    dispatch(updateCargoItem(id, itemId, action, updated));
  };

  const handleCargoItemCopy = id =>
    dispatch(copyCargoItem(id));

  const handleCargoItemDelete = (id, itemId, action) =>
    dispatch(deleteCargoItem(id, itemId, action));

  const handleCargoItemLoad = () =>
    dispatch(loadCargoItem());

  const handleCargoItemReload = id =>
    dispatch(reloadCargoItem(id))

  const handleCargoItemSelect = id =>
    dispatch(selectCargoItem(id));

  const handleCargoItemUnload = item =>
    dispatch(unloadCargoItem(item));

  const handleComplete = () =>
    onComplete({ waypoints, distance, duration });

  const handlePlaceSearchFail = () =>
    dispatch(setIsPlaceSearchFailAlertOpen(true));

  const handlePlaceSearchFailAlertClose = () =>
    dispatch(setIsPlaceSearchFailAlertOpen(false));

  const handleWaypointsReorder = (sourceIndex, destinationIndex) =>
    dispatch(reorderWaypoints(sourceIndex, destinationIndex));

  const handleWaypointAdd = () =>
    dispatch(addWaypoint());

  const handleWaypointError = async (index, name, value) =>
    dispatch(addError(index, name, value));

  const handleWaypointChange = async (index, name, value) => {
    const changedWaypoint = waypoints[index];
    let updated;

    if (name === "formattedAddress") {
      updated = { place: { address: { formatted: value } } };
    } else if (name === "alias") {
      updated = {
        ...changedWaypoint,
        place: {
          ...changedWaypoint.place,
          alias: value
        }
      };
    } else if (name === "place") {
      const formatted = changedWaypoint.place.address.formatted || value.formatted_address;
      createPlaceInput({ input: formatted, isFound: true });

      const { place_id: googleId, alias } = value;
      const place = await normalizeGooglePlace(placesService, googleId);

      updated = { place: { ...place, name: value.alias || place.name, alias } };
    } else {
      updated = { [name]: value };
    }

    dispatch(updateWaypoint(index, updated, name));
    if (name === "arriveAtFrom" || name === "arriveAtTo") {
      dispatch(validateWaypointDates(index));
    }
    if (name === "formattedAddress") {
      dispatch(validateWaypointPlace(index));
    }
    if (name === "phoneNumber") {
      dispatch(validateWaypointPhoneNumber(index));
    }
    if (name === "note") {
      dispatch(validateWaypointNote(index));
    }
    if (name === "contact") {
      dispatch(validateWaypointContact(index));
    }
  };

  const handleWaypointDelete = index =>
    dispatch(deleteWaypoint(index));

  const handleWaypointSelect = index =>
    dispatch(selectWaypoint(index));

  const searchPlace = async text => {
    try {
      const [result] = await searchGooglePlace(
        placesService,
        text,
        ["place_id"]
      );

      return result;
    } catch (error) {
      if (error === "ZERO_RESULTS") {
        createPlaceInput({ input: text, isFound: false });
        return undefined;
      }

      throw error;
    }
  };

  const searchPlaceByCoordinates = async (latitude, longitude) => {
    try {
      var latlng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
      const [result] = await searchGooglePlaceByCoordinates(
        geocoderService,
        latlng
      );

      return result;
    } catch (error) {
      if (error === "ZERO_RESULTS") {
        return undefined;
      }

      throw error;
    }
  }

  const suggestPlace = async text => {
    try {
      return await suggestGooglePlace(placesAutocompleteService, text);
    } catch (error) {
      if (error === "ZERO_RESULTS") {
        return [];
      }

      throw error;
    }
  };

  const handleToggleCargoEditor = () =>
    isCargoEditorVisible
      ? setIsCargoEditorVisible(false)
      : setIsCargoEditorVisible(true);

  const handlePickFromMap = (bool) => {
    setIsCargoEditorVisible(bool);
  };

  const recalculateRoute = async () => {
    if (!directionsService) {
      return;
    }

    const placeIds = waypoints
      .map(({ place: { googleId } }) => googleId)
      .filter((id) => id);

    if (compare(placeIds, renderedPlaceIds)) {
      return;
    }

    setRenderedPlaceIds(placeIds);

    if (placeIds.length < 2) {
      directionsRenderer.setMap(null);
      dispatch(setDistanceAndDuration());

      return;
    }

    const { distance, duration, directions } = await calculateRoute(
      directionsService,
      placeIds,
      googleMapsApi.TravelMode.DRIVING
    );

    if (!directions) {
      directionsRenderer.setMap(null);
    } else {
      directionsRenderer.setMap(map);
      directionsRenderer.setDirections(directions);
    }

    dispatch(setDistanceAndDuration(distance, duration));
  };

  useEffect(() => {

    const newMap = new googleMapsApi.Map(
      mapRef.current,
      {
        center: CZECH_REPUBLIC_GEOLOCATION,
        zoom: 8,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapTheme
      }
    );

    setMap(newMap);
    setPlacesService(new googleMapsApi.places.PlacesService(newMap));
    setGeocoderService(new googleMapsApi.Geocoder());
    setDirectionsService(new googleMapsApi.DirectionsService());
    setDirectionsRenderer(
      new googleMapsApi.DirectionsRenderer({
        polylineOptions: {
          strokeColor: palette.primary.main,
          strokeOpacity: 0.7,
          strokeWeight: 10
        }
      })
    );

    googleMapsApi.event.addListenerOnce(
      newMap,
      "idle",
      () => {
        setIsMapLoaded(true);
        googleMapsApi.event.addListener(newMap, 'click', (mapsMouseEvent) => {
          setLastLatLng(mapsMouseEvent.latLng.toJSON());
        });
      }
    );
  }, [mapRef]);

  useEffect(() => {
    recalculateRoute();
  }, [waypoints]);

  useEffect(() => {
    if (!state.distance || !state.duration || (state.distance === 1 && state.duration === 1)) {
      recalculateRoute();
    }
  }, [placesService]);


  return (
    <JourneyPlanner
      className={className}
      waypoints={waypoints}
      errors={errors}
      cargo={cargo}
      lastLatLng={lastLatLng}
      setLastLatLng={setLastLatLng}
      selectedCargoItemId={selectedCargoItemId}
      selectedWaypointIndex={selectedWaypointIndex}
      mapRef={mapRef}
      isCargoEditorVisible={isCargoEditorVisible}
      isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
      isCargoItemUnloadingDisabled={isCargoItemUnloadingDisabled}
      isPlaceSearchFailAlertOpen={isPlaceSearchFailAlertOpen}
      isJourneyComplete={isJourneyComplete}
      isMapLoaded={isMapLoaded}
      searchPlace={searchPlace}
      searchPlaceByCoordinates={searchPlaceByCoordinates}
      suggestPlace={suggestPlace}
      unloadableCargoItems={unloadableCargoItems}
      onCargoItemChange={handleCargoItemChange}
      onCargoItemCopy={handleCargoItemCopy}
      onCargoItemDelete={handleCargoItemDelete}
      onCargoItemLoad={handleCargoItemLoad}
      onCargoItemReload={handleCargoItemReload}
      onCargoItemSelect={handleCargoItemSelect}
      onCargoItemUnload={handleCargoItemUnload}
      handlePickFromMap={handlePickFromMap}
      onCargoEditorToggle={handleToggleCargoEditor}
      onComplete={handleComplete}
      onPlaceSearchFail={handlePlaceSearchFail}
      onPlaceSearchFailAlertClose={handlePlaceSearchFailAlertClose}
      onWaypointAdd={handleWaypointAdd}
      onWaypointChange={handleWaypointChange}
      onWaypointError={handleWaypointError}
      onWaypointDelete={handleWaypointDelete}
      onWaypointSelect={handleWaypointSelect}
      onWaypointsReorder={handleWaypointsReorder}
    />
  );
};

export default JourneyPlannerContainer;
