import { useFormik } from "formik2";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ContactType } from "@cargoticcom/model";
import {
  Button,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepButton,
  StepContent,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  FlashOn as FlashOnIcon,
} from "@material-ui/icons";

import { ToggleButton } from "@material-ui/lab";

import {
  DrawerDialog,
  FormikAddressAutosuggestTextField,
  FormikCheckbox,
  FormikContactInsuranceExpirationDatePicker,
  FormikDatePicker,
  FormikRadioGroup,
  FormikTextField,
  FormikSelect,
  FormikToggleButtonGroup,
  useApiClient,
} from "../../../cargotic-webapp-component";

import ContactDuplicateAlert from "../ContactDuplicateAlert";

import { getNextContactNumber } from "../../../cargotic-webapp/resource";
import BillingPeriod from "../../../cargotic-common/billingPeriod";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1),
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,
    },
  },
  step: {
    marginTop: spacing(1),

    "& > :not(:first-child)": {
      marginTop: spacing(0.5),
    },
  },
  prefill: {
    marginBottom: spacing(6),
  },
  radioGroupLabel: {
    marginTop: spacing(1),

    "&:first-child": {
      marginTop: spacing(2),
    },

    "& > span:last-child > div": {
      display: "flex",
      alignItems: "center",

      "& > :last-child": {
        marginLeft: spacing(1),
      },
    },
  },
  icoPrefillLoading: {
    color: "white",
  },
}));

const ContactEditor = ({
  initialValue = {},
  type: inputType,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const now = new Date();
  const [initNum, setInitNum] = useState(null);
  const { id } = initialValue;
  const initialType = initialValue.type || ContactType.CUSTOMER;
  const initialIco = initialValue.ico || initialValue.ic || "";
  const initialDic = initialValue.dic || "";
  const initialIsPrivate = (initialValue.isPrivate ?? true).toString();
  const initialSubcontractor = !!initialValue.isSubcontractor;
  const isUpdating = id !== undefined;

  const initialName = initialValue.name || "";
  const initialEmail = initialValue.email || "";
  const initialPhoneNumber = initialValue.phoneNumber || "";
  const initialWebsite = initialValue.website || "";

  const initialInsuranceExpiresAt = initialValue.insuranceExpiresAt || null;
  const initialPaymentDueDays = initialValue.paymentDueDays || "";
  const initialBillingContact = initialValue.billingContact || "";

  const initialMailingAddress = initialValue.mailingAddress || "";
  const initialBillingAddress = initialValue.billingAddress || "";
  const initialBillingPeriod = initialValue.billingPeriod || "";

  const initialNote = initialValue.note || "";
  const initialIsBilledOnline = initialValue.isBilledOnline || false;
  const initialHasRoger = initialValue.hasRoger || false;
  
  const initialNumber = initialValue.contactNumber || initNum;

  const initialActiveStep = id === undefined ? 0 : 1;

  const client = useApiClient();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [duplicate, setDuplicate] = useState();
  const [isDuplicateAlertOpen, setIsDuplicateAlertOpen] = useState(false);
  const [isIcoLoading, setIsIcoLoading] = useState(false);

  const GeneralFormSchema = Yup.object({
    ico: Yup.string().max(16, t("webapp:common.validate.ico")),
    dic: Yup.string().max(32, t("webapp:common.validate.dic")).required(t("webapp:common.validate.dic")),
    contactNumber: Yup.number().required(),
  });

  const ExtendedFormSchema = Yup.object({
    name: Yup.string()
      .max(128, t("webapp:contact.validate.name"))
      .required(t("webapp:contact.validate.name")),
    email: Yup.string()
      .max(128, t("webapp:common.validate.email"))
      .email(t("webapp:common.validate.email")),
    phoneNumber: Yup.string().max(
      128,
      t("webapp:common.validate.phone-number")
    ),
    website: Yup.string().max(256, t("webapp:common.validate.website")),
    paymentDueDays: Yup.number().typeError(
      t("webapp:common.validate.payment-due-days")
    ),
    mailingAddress: Yup.string().max(
      512,
      t("webapp:common.validate.mailing-address")
    ),
    billingAddress: Yup.string()
      .max(512, t("webapp:common.validate.billingAddress"))
      .required(t("webapp:common.validate.billingAddress")),
    billingPeriod: Yup.string().nullable(),
    note: Yup.string().max(255, t("webapp:contact.validate.note")),
    insuranceExpiresAt: Yup.date()
      .nullable()
      .typeError(t("webapp:common.validate.date")),
  });

  let generalForm;
  const extendedForm = useFormik({
    initialValues: {
      name: initialName,
      email: initialEmail,
      phoneNumber: initialPhoneNumber,
      website: initialWebsite,
      insuranceExpiresAt: initialInsuranceExpiresAt,
      paymentDueDays: initialPaymentDueDays,
      billingContact: initialBillingContact,
      mailingAddress: initialMailingAddress,
      billingAddress: initialBillingAddress,
      billingPeriod: initialBillingPeriod,
      note: initialNote,
      isBilledOnline: initialIsBilledOnline,
      hasRoger: initialHasRoger,
    },
    validationSchema: ExtendedFormSchema,
    onSubmit: (values) => {
      const { type, contactNumber } = generalForm.values;
      const { name, billingAddress } = values;

      const isPrivate = generalForm.values.isPrivate === "true";
      const ico = generalForm.values.ico || undefined;
      const dic = generalForm.values.dic || undefined;
      const email = values.email || undefined;
      const phoneNumber = values.phoneNumber || undefined;
      const website = values.website || undefined;
      const insuranceExpiresAt = values.insuranceExpiresAt || undefined;
      const paymentDueDays =
        values.paymentDueDays !== ""
          ? parseInt(values.paymentDueDays, 10)
          : undefined;
      const { isSubcontractor } = generalForm.values;

      const billingContact = values.billingContact || undefined;
      const billingPeriod = values.billingPeriod || undefined;
      const mailingAddress = values.mailingAddress || undefined;
      const note = values.note || undefined;
      const isBilledOnline = values.isBilledOnline || false;
      const hasRoger = values.hasRoger || false;

      const contact = {
        id,
        type,
        ico,
        dic,
        name,
        email,
        phoneNumber,
        website,
        insuranceExpiresAt,
        paymentDueDays,
        billingContact,
        billingPeriod,
        mailingAddress,
        billingAddress,
        note,
        isPrivate,
        isBilledOnline,
        isSubcontractor,
        contactNumber,
        hasRoger
      };

      if (onSubmit) {
        onSubmit(contact);
      }
    },
  });

  generalForm = useFormik({
    initialValues: {
      type: initialType,
      ico: initialIco,
      dic: initialDic,
      isPrivate: initialIsPrivate,
      isSubcontractor: initialSubcontractor,
      contactNumber: initialNumber,
    },
    validationSchema: GeneralFormSchema,
    onSubmit: async (result) => {
      const { ico, dic } = result;

      if (!ico && !dic) {
        setActiveStep(1);

        return;
      }

      const newDuplicate = await client.contact.getContactDuplicate({
        ico,
        dic,
      });

      if (!newDuplicate || newDuplicate.id === id) {
        setActiveStep(1);

        return;
      }

      setDuplicate(newDuplicate);
      setIsDuplicateAlertOpen(true);
    },
  });

  const handlePrefillButtonClick = async () => {
    setIsIcoLoading(true);

    try {
      const { ico, dic } = generalForm.values;
      const prefill = await client.contact.getContactPrefill({ ico, dic });

      generalForm.setValues({
        ...generalForm.values,
        ico: prefill.ico,
        dic: prefill.dic,
      });

      extendedForm.setValues({
        ...extendedForm.values,
        name: prefill.name,
        billingAddress: prefill.businessAddress,
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:contact.error.prefill"), {
        variant: "error",
      });
    }

    setIsIcoLoading(false);
  };

  const handleBackButtonClick = () => {
    if (activeStep === 0) {
      if (onClose) {
        onClose();
      }

      return;
    }

    setActiveStep(0);
  };

  const handleDuplicateAlertClose = () => setIsDuplicateAlertOpen(false);

  const handleNextButtonClick = () => {
    if (activeStep === 0) {
      try {
        generalForm.submitForm();
      } catch (error) {
        console.log(error);
      }

      return;
    }

    if (activeStep === 1) {
      try {
        extendedForm.submitForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleStepChange = (step) => {
    if (activeStep === 0 && step === 1) {
      generalForm.submitForm();
    } else {
      setActiveStep(step);
    }
  };

  const steps = [
    [
      t("webapp:common.title.general-information"),
      <div key={0} className={classes.step}>
        <FormikToggleButtonGroup form={generalForm} name="type" exclusive>
          {[ContactType.CUSTOMER, ContactType.CARRIER, ContactType.BOTH].map(
            (type) => (
              <ToggleButton
                key={type}
                value={type}
                disabled={
                  (inputType === ContactType.CUSTOMER &&
                    type === ContactType.CARRIER) ||
                  (inputType === ContactType.CARRIER &&
                    type === ContactType.CUSTOMER)
                }
              >
                {t(`webapp:contact.type.${type}`)}
              </ToggleButton>
            )
          )}
        </FormikToggleButtonGroup>
        {ContactType.CUSTOMER !== generalForm.values.type && (
          <div>
            <FormikCheckbox
              form={generalForm}
              name="isSubcontractor"
              label={t("webapp:contact.title.subContractor")}
              color="primary"
            />
          </div>
        )}
        <div>
          <FormikTextField
            form={generalForm}
            name="ico"
            label={t("webapp:common.title.ico")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={generalForm}
            name="dic"
            required
            label={t("webapp:common.title.dic")}
            fullWidth
          />
        </div>
        <div className={classes.prefill}>
          <Button
            startIcon={<FlashOnIcon />}
            endIcon={
              isIcoLoading ? (
                <CircularProgress
                  className={classes.icoPrefillLoading}
                  size={14}
                />
              ) : null
            }
            color="primary"
            variant="contained"
            onClick={handlePrefillButtonClick}
            disabled={generalForm.values.ico === ""}
            fullWidth
          >
            {t("webapp:contact.title.prefill")}
          </Button>
        </div>
        <div>
          <FormikTextField
            type="number"
            form={generalForm}
            name="contactNumber"
            label={t("webapp:contact.title.number")}
            required
            fullWidth
          />
        </div>
        <div>
          <FormikRadioGroup
            form={generalForm}
            name="isPrivate"
            label={t("webapp:common.title.visibility")}
            color=""
          >
            <FormControlLabel
              value="false"
              className={classes.radioGroupLabel}
              control={<Radio color="primary" />}
              label={
                <div>
                  <div>
                    <LockOpenIcon fontSize="large" color="action" />
                  </div>
                  <div>
                    <Typography>{t("webapp:contact.title.public")}</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      {t("webapp:contact.title.public-description")}
                    </Typography>
                  </div>
                </div>
              }
            />
            <FormControlLabel
              className={classes.radioGroupLabel}
              value="true"
              control={<Radio color="primary" />}
              label={
                <div>
                  <div>
                    <LockIcon fontSize="large" color="action" />
                  </div>
                  <div>
                    <Typography>{t("webapp:contact.title.private")}</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      {t("webapp:contact.title.private-description")}
                    </Typography>
                  </div>
                </div>
              }
            />
          </FormikRadioGroup>
        </div>
      </div>,
    ],
    [
      t("webapp:common.title.additional-information"),
      <div key={1} className={classes.step}>
        <div>
          <FormikTextField
            form={extendedForm}
            name="name"
            label={t("webapp:contact.title.name")}
            required
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={extendedForm}
            name="email"
            label={t("webapp:common.title.email")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={extendedForm}
            name="phoneNumber"
            label={t("webapp:common.title.phone-number")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={extendedForm}
            name="website"
            label={t("webapp:common.title.website")}
            fullWidth
          />
        </div>
        <div>
          <FormikAddressAutosuggestTextField
            form={extendedForm}
            name="mailingAddress"
            label={t("webapp:common.title.mailing-address")}
            fullWidth
          />
        </div>
        <div>
          <FormikAddressAutosuggestTextField
            form={extendedForm}
            name="billingAddress"
            label={t("webapp:common.title.billing-address")}
            required
            fullWidth
          />
        </div>
        <div>
          <FormikSelect
            form={extendedForm}
            name="billingPeriod"
            label={t("webapp:common.title.billing-period")}
            fullWidth
          >
            <MenuItem id={undefined} key={undefined} value={undefined}>-</MenuItem>
            <MenuItem id={BillingPeriod.Immediately} key={BillingPeriod.Immediately} value={BillingPeriod.Immediately}>{t("webapp:common.title.immediately")}</MenuItem>
            <MenuItem id={BillingPeriod.HalfMonth} value={BillingPeriod.HalfMonth}>{t("webapp:common.title.half-month")}</MenuItem>
            <MenuItem id={BillingPeriod.Month} key={BillingPeriod.Month} value={BillingPeriod.Month}>{t("webapp:common.title.month")}</MenuItem>
            <MenuItem id={BillingPeriod.Week} key={BillingPeriod.Week} value={BillingPeriod.Week}>{t("webapp:common.title.week")}</MenuItem>
          </FormikSelect>
        </div>
        {generalForm.values.type !== ContactType.CUSTOMER ? (
          <div>
            <FormikContactInsuranceExpirationDatePicker
              form={extendedForm}
              name="insuranceExpiresAt"
              label={t("webapp:common.title.insurance-expires-at")}
              fullWidth
            />
          </div>
        ) : null}
        <div>
          <FormikTextField
            form={extendedForm}
            name="billingContact"
            label={t("webapp:contact.title.billingContact")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={extendedForm}
            name="paymentDueDays"
            label={t("webapp:common.title.payment-due-days")}
            fullWidth
          />
        </div>
        <div>
          <FormikTextField
            form={extendedForm}
            name="note"
            label={t("webapp:common.title.note")}
            fullWidth
          />
        </div>
        <div>
          <FormikCheckbox
            form={extendedForm}
            name="isBilledOnline"
            label={t("webapp:contact.title.isBilledOnline")}
            color="primary"
          />
        </div>
        <div>
          <FormikCheckbox
            form={extendedForm}
            name="hasRoger"
            label={t("webapp:contact.title.hasRoger")}
            color="primary"
          />
        </div>
      </div>,
    ],
  ];

  useEffect(() => {
    generalForm.resetForm({
      values: {
        type: initialType,
        ico: initialIco,
        dic: initialDic,
        isPrivate: initialIsPrivate,
        isSubcontractor: initialSubcontractor,
        contactNumber: initialNumber,
      },
      errors: {},
    });

    extendedForm.resetForm({
      values: {
        name: initialName,
        email: initialEmail,
        phoneNumber: initialPhoneNumber,
        website: initialWebsite,
        insuranceExpiresAt: initialInsuranceExpiresAt,
        paymentDueDays: initialPaymentDueDays,
        billingContact: initialBillingContact,
        mailingAddress: initialMailingAddress,
        billingAddress: initialBillingAddress,
        billingPeriod: initialBillingPeriod,
        note: initialNote,
        isBilledOnline: initialIsBilledOnline,
        hasRoger: initialHasRoger
      },
      errors: {},
    });

    setActiveStep(initialActiveStep);
  }, [isOpen]);

  useEffect(() => {
    const { type } = generalForm.values;

    const insuranceExpiresAt =
      type === ContactType.CUSTOMER
        ? null
        : extendedForm.values.insuranceExpiresAt;

    extendedForm.setValues({
      ...extendedForm.values,
      insuranceExpiresAt,
    });
  }, [generalForm.values.type]);

  useEffect(() => {
    const { type } = generalForm.values;

    if (isUpdating) {
      return;
    }

    if (type === ContactType.CUSTOMER) {
      generalForm.setValues({ ...generalForm.values, isPrivate: "true" });
    } else {
      generalForm.setValues({ ...generalForm.values, isPrivate: "false" });
    }
  }, [generalForm.values.type]);

  useEffect(() => {
    const setup = async () => {
      const { nextContactNumber } = await getNextContactNumber();
      setInitNum(nextContactNumber);
    };
    setup();
  }, []);

  return (
    <>
      <DrawerDialog
        title={t("webapp:common.title.contact")}
        actions={
          <div className={classes.actions}>
            <Button variant="contained" onClick={handleBackButtonClick}>
              {activeStep === 0
                ? t("webapp:common.title.cancel")
                : t("webapp:common.title.back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextButtonClick}
            >
              {activeStep === 0
                ? t("webapp:common.title.continue")
                : t("webapp:common.title.complete")}
            </Button>
          </div>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {initNum &&
            steps.map(([label, content], index) => (
              <Step key={label}>
                <StepButton onClick={() => handleStepChange(index)}>
                  {label}
                </StepButton>
                <StepContent>{content}</StepContent>
              </Step>
            ))}
        </Stepper>
      </DrawerDialog>
      {duplicate !== undefined ? (
        <ContactDuplicateAlert
          duplicate={duplicate}
          isOpen={isDuplicateAlertOpen}
          onClose={handleDuplicateAlertClose}
        />
      ) : null}
    </>
  );
};

export default ContactEditor;
