import React, { useState } from "react";
import {
  Paper,
  makeStyles,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  ListItemText,
  InputAdornment,
  Tooltip,
  IconButton, TextField, Grid
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Autosuggest } from "@cargoticcom/common";
import { Error, Warning } from "@material-ui/icons";
import { ContactType } from "@cargoticcom/model";
import { FormAutosuggestTextField } from "../../../form";

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    marginTop: spacing(2),
    marginBottom: spacing(1.5)
  },
  paper: {
    paddingTop: spacing(1),
    paddingBottom: spacing(2)
  },
  carrierSelect: {
    paddingLeft: spacing(3)
  },
  warning: {
    color: palette.warning.light
  },
  danger: {
    color: palette.status.danger
  }
}));

const SelectCarrier = ({
  suggest,
  handleChange,
  ...other
}) => {
  const { t } = useTranslation();
  const [carrier, setCarrier] = useState({});
  const [value, setValue] = useState("");

  const classes = useStyles();

  const handleCarrierChange = (event) => {
    const newValue = event.target.value;

    setValue(newValue);
    handleChange(newValue);
  };

  function formatSuggestion({
    name,
    ic,
    dic,
    phoneNumber,
    email
  }) {
    const buffer = [name];

    if (ic) {
      buffer.push(`IČ: ${ic}`);
    }

    if (dic) {
      buffer.push(`DIČ ${dic}`);
    }

    if (email) {
      buffer.push(`Email: ${email}`);
    }

    if (phoneNumber) {
      buffer.push(`Mobil: ${phoneNumber}`);
    }

    return buffer.join(", ");
  }

  const insuranceAdornment = (tooltip, isExpired) => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={tooltip}>
          <IconButton className={isExpired ? classes.danger : classes.warning} size="small">
            <Warning />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  });

  const deletedAdornment = () => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={t("webapp:outcomingOrder.form.error.deletedContact")}>
          <IconButton className={classes.danger} size="small">
            <Error />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  });

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={2}>
        <Grid container>
          <Grid item xs={4}>
            <Autosuggest
              {...other}
              className={classes.carrierSelect}
              suggest={suggest}
              getSuggestionText={formatSuggestion}
              suggestionComponent={({ suggestion }) => (
                <ListItemText primary={formatSuggestion(suggestion)} />
              )}
              inputComponent={TextField}
              InputProps={() => ({})}
              value={value}
              onChange={handleCarrierChange}
              label={t("tariff.carrier")}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SelectCarrier;
