import React, { useState, useEffect } from "react";

import { Grid, Typography, makeStyles, useTheme, useMediaQuery, Link } from "@material-ui/core";
import { GoogleMap, withGoogleMap, DirectionsRenderer } from "react-google-maps";
import { useTranslation } from "react-i18next";
import { formatDistance } from "date-fns";
import { cs, enUS } from "date-fns/locale";
import { Link as RouterLink } from "react-router-dom";

import MapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import useAuth from "../../hook/useAuth";

const CZECH_REPUBLIC_POSITION = { lat: 49.743979, lng: 15.338667 };

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    flexGrow: 1
  },
  centerText: {
    textAlign: "center"
  },
  itemsGrid: {
    [breakpoints.down("xs")]: {
      backgroundColor: "#fafafa",
      borderRadius: 15,
      margin: spacing(2),
      padding: spacing(1)
    },
    [breakpoints.up("sm")]: {
      marginRight: spacing(3),
      marginBottom: spacing(2)
    }
  }
}));

const DirectionsMap = withGoogleMap(({ directions }) => (
  <GoogleMap
    options={{
      streetViewControl: false,
      mapTypeControl: false,
      styles: MapTheme
    }}
    defaultZoom={8}
    defaultCenter={CZECH_REPUBLIC_POSITION}
  >
    <DirectionsRenderer
      directions={directions}
    />
  </GoogleMap>
));

const DetailedIncomingOrdersTableRow = ({ shipment }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const directionsService = new google.maps.DirectionsService();
  const { user } = useAuth();
  const theme = useTheme();
  const tableMode = useMediaQuery(theme.breakpoints.up("sm"));

  const timeFormat = {
    year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"
  };

  const [directions, setDirections] = useState([]);

  const DetailCard = ({ forceShowTitle, title, values, xs, sm }) => (
    <Grid item xs={xs} sm={sm}>
      {values
        && (
          <>
            {!tableMode || forceShowTitle ? (
              <Typography variant="subtitle2" className={classes.centerText}>
                {title}
              </Typography>
            ) : null}
            {values.map((value) => (
              <Typography variant="subtitle1" className={classes.centerText}>
                {value}
              </Typography>
            ))}
          </>
        )
      }
    </Grid>
  );

  const ShipmentItemRow = ({ item }) => (
    <Grid container item spacing={1} direction="row" className={classes.itemsGrid}>
      <DetailCard xs={6} sm={2} title={t("incomingOrders.description")} values={[item.description]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.amount")} values={[item.quantity]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.unit")} values={[t(`core:cargo.item.template.${item.template}`)]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.length")} values={[item.length ? `${item.length} ${item.lengthUnit.toLowerCase()}` : null]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.width")} values={[item.width ? `${item.width} ${item.lengthUnit.toLowerCase()}` : null]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.height")} values={[item.height ? `${item.height} ${item.lengthUnit.toLowerCase()}` : null]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.weight")} values={[item.weight]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.volume")} values={[item.volume]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.totalWeight")} values={[item.totalWeight]} />
      <DetailCard xs={6} sm={1} title={t("incomingOrders.totalVolume")} values={[item.totalVolume]} />
      {item.isStackable ? (
        <DetailCard xs={6} sm={1} title={t("incomingOrders.stackability")} values={[" " + t("incomingOrders.stackable") + " "]} />
      ) : null}
    </Grid>
  );

  useEffect(() => {
    directionsService.route(
      {
        waypoints: shipment.journey.waypoints.slice(1, -1).map((val) => ({ location: new google.maps.LatLng(val.place.latitude, val.place.longitude) })),
        origin: { placeId: shipment.journey.waypoints[0].place.googleId },
        destination: { placeId: shipment.journey.waypoints[shipment.journey.waypoints.length - 1].place.googleId },
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.log(status);
        }
      }
    );
  }, []);

  const waypoints = shipment.journey.waypoints
    .map(({ cargo }) => Object.values(cargo || ({})).filter(({ action }) => action === "LOAD"))
    .flat();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container item direction="column" justify="center">
            <Grid item style={{ marginTop: 20 }}>
              <Grid container item spacing={1} direction="row">
                {shipment.journey.distance ? (
                  <DetailCard
                    title={t("incomingOrders.distance")}
                    values={[(shipment.journey.distance / 1000).toFixed() + " km",]}
                    xs={6}
                    forceShowTitle={true}
                  />
                ) : null}
                {shipment.journey.duration ? (
                  <DetailCard
                    title={t("incomingOrders.duration")}
                    values={[formatDistance(0, shipment.journey.duration * 1000, {
                      includeSeconds: true,
                      locale: t("locale") === "cs" ? cs : enUS
                    })]}
                    xs={6}
                    forceShowTitle={true}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
              <Grid container item direction="row">
                <Grid item xs={6} direction="column">
                  {shipment.customerContact ? (
                    <DetailCard
                      title={t("incomingOrders.customer")}
                      values={[(<Link component={RouterLink} to={`/contacts/${shipment.customerContact.id}`}>{shipment.customerContact.name}</Link>), shipment.customerContact.email, shipment.customerContact.phoneNumber]}
                      xs={12}
                      forceShowTitle={true}
                    />
                  ) : null}
                  {shipment.customerEmployee ? (
                    <DetailCard
                      title={t("incomingOrders.customerPerson")}
                      values={[shipment.customerEmployee.name, shipment.customerEmployee.email, shipment.customerEmployee.phoneNumber]}
                      xs={12}
                      forceShowTitle={true}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={6} direction="column">
                  {shipment.carrierContact ? (
                    <DetailCard
                      title={t("incomingOrders.carrier")}
                      values={[<Link component={RouterLink} to={`/contacts/${shipment.carrierContact.id}`}>{shipment.carrierContact.name}</Link>, shipment.carrierContact.email, shipment.carrierContact.phoneNumber]}
                      xs={12}
                      forceShowTitle={true}
                    />
                  ) : null}
                  {shipment.carrierEmployee ? (
                    <DetailCard
                      title={t("incomingOrders.carrierPerson")}
                      values={[shipment.carrierEmployee.name, shipment.carrierEmployee.email, shipment.carrierEmployee.phoneNumber]}
                      xs={12}
                      forceShowTitle={true}
                    />
                  ) : null}
                  {shipment.driver ? (
                    <DetailCard
                      title={t("incomingOrders.driver")}
                      values={[<Link component={RouterLink} to={`/users/${shipment.driver.id}`}>{shipment.driver.name}</Link>]}
                      xs={12}
                      forceShowTitle={true}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            {shipment.driverContact ? (
              <Grid item style={{ marginTop: 20 }}>
                <Grid container item spacing={1} direction="row">
                  <DetailCard
                    title=" "
                    values={[]}
                    xs={6}
                    forceShowTitle={true}
                  />
                  <DetailCard
                    title={t("incomingOrders.driverContact")}
                    values={[shipment.driverContact]}
                    xs={6}
                    forceShowTitle={true}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item style={{ marginTop: 20 }}>
              <Grid container item spacing={1} direction="row">
                {shipment.customerPrice ? (
                  <DetailCard
                    title={t("incomingOrders.priceCustomer")}
                    values={[`${shipment.customerPrice} ${shipment.customerPriceCurrency}`]}
                    xs={6}
                    forceShowTitle={true}
                  />
                ) : null}
                {shipment.carrierPrice !== undefined && shipment.carrierPrice !== null ? (
                  <DetailCard
                    title={t("incomingOrders.priceCarrier")}
                    values={[`${shipment.carrierPrice} ${shipment.carrierPriceCurrency}`]}
                    xs={6}
                    forceShowTitle={true}
                  />
                ) : null}
                {shipment.vehicle ? (
                  <DetailCard
                    title={t("incomingOrders.vehicle")}
                    values={[
                      <Link component={RouterLink} to={`/vehicles/${shipment.vehicle.id}`}>{shipment.vehicle.manufacturer} {shipment.vehicle.model}, SPZ: {shipment.vehicle.licensePlate}</Link>
                    ]}
                    xs={6}
                    forceShowTitle={true}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item style={tableMode ? { marginTop: 20 } : { marginBottom: 10 }}>
              <Grid container item spacing={1} direction="row">
                <DetailCard
                  title={t("incomingOrders.creationDate")}
                  values={[new Date(shipment.createdAt).toLocaleDateString(t("locale"), timeFormat)]}
                  xs={12}
                  sm={6}
                  forceShowTitle={true}
                />
                <DetailCard
                  title={t("incomingOrders.lastUpdateDate")}
                  values={shipment.updatedAt
                    ? [new Date(shipment.updatedAt).toLocaleDateString(t("locale"), timeFormat)]
                    : [new Date(shipment.createdAt).toLocaleDateString(t("locale"), timeFormat)]
                  }
                  xs={12}
                  sm={6}
                  forceShowTitle={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DirectionsMap
            directions={directions}
            containerElement={tableMode ?
              <div style={{ height: "100%", width: "100%" }} /> :
              <div style={{ height: "35vh", width: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </Grid>
      </Grid>

      {waypoints.length > 0 ? (
        <div>
          <Typography variant="h6" style={{ marginTop: 10 }} className={classes.centerText}>
            {" "}
            {t("incomingOrders.items")}
            {" "}
          </Typography>
          <Grid container style={{ textAlign: "center" }}>
            {tableMode ? (
              <React.Fragment>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">{t("incomingOrders.description")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.amount")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.unit")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.length")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.width")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.height")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.weight")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.volume")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.totalWeight")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.totalVolume")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">{t("incomingOrders.stackability")}</Typography>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid container item xs={12}>
              {waypoints.map((cargo) => (
                <ShipmentItemRow key={cargo} item={cargo} style={{ marginBottom: 10 }} />
              ))}
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default DetailedIncomingOrdersTableRow;
