import React from "react";

import { VehicleExpirationType } from "@cargoticcom/model";
import { makeStyles } from "@material-ui/core";

import VehicleProfileDetail from "./VehicleProfileDetail";
import VehicleProfileStatusBox from "./VehicleProfileStatusBox";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex"
  },
  detail: {
    flex: 1
  },
  status: {
    marginLeft: spacing(2),
    width: 300
  }
}));

const VehicleProfileHeaderContent = ({
  latestExpirations,
  vehicle,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  isExpirationCreateButtonDisabled,
  isShipmentCreateButtonDisabled,
  onDeleteButtonClick,
  onEditButtonClick,
  onExpirationAdd,
  onShipmentCreateButtonClick,
  onVehicleTrailerEditClick,
  onVehicleDriverEditClick
}) => {
  const classes = useStyles();
  const status = (() => {
    const now = new Date();

    const firstAidKitExpiresAt = (
      latestExpirations[VehicleExpirationType.FIRST_AID_KIT]?.expiresAt
    );

    const vehicleInspectionExpiresAt = (
      latestExpirations[VehicleExpirationType.VEHICLE_INSPECTION]?.expiresAt
    );

    const vehicleInsuranceExpiresAt = (
      latestExpirations[VehicleExpirationType.VEHICLE_INSURANCE]?.expiresAt
    );

    const vehicleRegistrationCertificateExpiresAt = (
      latestExpirations[VehicleExpirationType.VEHICLE_REGISTRATION_CERTIFICATE]
        ?.expiresAt
    );

    if (firstAidKitExpiresAt === undefined) {
      return "first-aid-kit-undefined";
    }

    if (firstAidKitExpiresAt <= now) {
      return "first-aid-kit-expired";
    }

    if (vehicleInspectionExpiresAt === undefined) {
      return "vehicle-inspection-undefined";
    }

    if (vehicleInspectionExpiresAt <= now) {
      return "vehicle-inspection-expired";
    }

    if (vehicleInsuranceExpiresAt === undefined) {
      return "vehicle-insurance-undefined";
    }

    if (vehicleInsuranceExpiresAt <= now) {
      return "vehicle-insurance-expired";
    }

    if (vehicleRegistrationCertificateExpiresAt === undefined) {
      return "vehicle-registration-certificate-undefined";
    }

    if (vehicleRegistrationCertificateExpiresAt <= now) {
      return "vehicle-registration-certificate-expired";
    }

    return "ready";
  })();

  return (
    <div className={classes.root}>
      <VehicleProfileDetail
        className={classes.detail}
        vehicle={vehicle}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        isEditButtonDisabled={isEditButtonDisabled}
        isShipmentCreateButtonDisabled={isShipmentCreateButtonDisabled}
        onDeleteButtonClick={onDeleteButtonClick}
        onEditButtonClick={onEditButtonClick}
        onShipmentCreateButtonClick={onShipmentCreateButtonClick}
        onVehicleTrailerEditClick={onVehicleTrailerEditClick}
        onVehicleDriverEditClick={onVehicleDriverEditClick}
      />
      <VehicleProfileStatusBox
        className={classes.status}
        status={status}
        isExpirationCreateButtonDisabled={isExpirationCreateButtonDisabled}
        isShipmentCreateButtonDisabled={isShipmentCreateButtonDisabled}
        onExpirationAdd={onExpirationAdd}
        onShipmentCreateButtonClick={onShipmentCreateButtonClick}
      />
    </div>
  );
};

export default VehicleProfileHeaderContent;
