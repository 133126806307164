import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik2";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { formatDate } from "@cargoticcom/common";
import { FormikDatePicker } from "../../../../cargotic-webapp-component";

const OUTPUT_DATE_FORMAT = "yyyy-MM-dd";

const EditArriveDateTimeRange = ({ isOpen, onSave, onClose, waypoint }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      arriveAtFrom: waypoint.arriveAtFrom,
      arriveAtTo: waypoint.arriveAtTo
    },
    validationSchema: Yup.object().shape({
      arriveAtFrom: Yup.date(t("webapp:common.validate.date"))
        .required(t("webapp:common.validate.date")),
      arriveAtTo: Yup.date(t("webapp:common.validate.date")).nullable()
    }),
    onSubmit: async (values) => {
      form.resetForm();
      console.log(values);
      onSave(values);
    }
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t("incomingOrders.editArriveAtDateTimeRange")}</DialogTitle>
      <DialogContent>
        <FormikDatePicker
          form={form}
          name="arriveAtFrom"
          label={t("webapp:journey.planner.arriveAtFrom")}
          format="dd.MM.yyyy HH:mm"
          fullWidth
        />
        <FormikDatePicker
          form={form}
          name="arriveAtTo"
          label={t("webapp:journey.planner.arriveAtTo")}
          format="dd.MM.yyyy HH:mm"
          fullWidth
          // onChange={}
          value={form.values["arriveAtTo"] == undefined ? form.values["arriveAtFrom"] : form.values["arriveAtTo"]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button
          onClick={() => form.submitForm()}
          color="primary"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditArriveDateTimeRange;
