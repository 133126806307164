import React, { useEffect, useState } from "react";

import { deserializeContact } from "@cargoticcom/api-client";

import OutcomingOrderConceptContext from "../../context/OutcomingOrderConceptContext";

const key = "outcomingOrderConcept";

const OutcomingOrderConceptProvider = ({ children }) => {
  const [outcomingOrder, setOutcomingOrder] = useState(() => {
    const value = localStorage.getItem(key);
    if (value) {
      const parsedValue = JSON.parse(value);

      if (!parsedValue) {
        return null;
      }

      parsedValue.waypoints = parsedValue.waypoints?.map(({ arriveAtFrom, arriveAtTo, ...rest }) => ({
        arriveAtFrom: arriveAtFrom ? new Date(arriveAtFrom) : undefined,
        arriveAtTo: arriveAtTo ? new Date(arriveAtTo) : undefined,
        ...rest
      }));

      if (!parsedValue.waypoints) {
        return null;
      }

      if (parsedValue.carrierContact) {
        parsedValue.carrierContact = deserializeContact(parsedValue.carrierContact);
      }

      return parsedValue;
    }
    return null;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(outcomingOrder));
  }, [outcomingOrder]);

  return (
    <OutcomingOrderConceptContext.Provider value={{
      outcomingOrder,
      setOutcomingOrder
    }}
    >
      {children}
    </OutcomingOrderConceptContext.Provider>
  );
};

export default OutcomingOrderConceptProvider;
