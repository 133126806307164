import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Checkbox, FormControl, FormControlLabel, FormHelperText,
  Grid, InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { FormCheckbox, FormSelect, FormTextField } from "../../../form";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Currency } from "../../../currency";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing }) => ({
  serviceSummary: {
    background: "#ededed",
  },
  selectRoot: {
    //...other styles
    '&:focus': {
      background: "#ededed",
    }
  }
}));

const IncomingOrderServicesInput = (
  { form, name, availableServices, isReadOnly }
) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const services = form.values[name];
  const setServices = newServices => form.setValue(name, newServices);

  const removeService = (key) => {
    setServices(services.filter((_, index) => index !== key));
  };

  const addService = () => {
    setServices([
      ...services,
      {
        name: availableServices[0].name,
        price: "",
        type: availableServices[0].type,
        value: undefined,
        priceCurrency: Currency.CZK,
        isPriceWithDph: false,
      },
    ]);
  };

  const handleServiceChange = (key, field, event, eventKey = "value") => {
    event.stopPropagation();
    event.preventDefault();
    let newServices = [...services];
    newServices[key][field] = event.target[eventKey];
    if (field === "name") {
      const service = availableServices.find(item => item.name === event.target[eventKey]);
      newServices[key]['type'] = service.type;
      if (service.type !== 'CASH_ON_DELIVERY') {
        newServices[key]['value'] = undefined;
      }
    }
    setServices(newServices);
  };
  return (
    <Grid container spacing={3}>
      {services.map(({ name, price, priceCurrency, isPriceWithDph, type, value }, key) => {
        return (
          <>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Accordion
                style={{ border: "none", boxShadow: "none" }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandLessIcon />}
                  className={classes.serviceSummary}
                >
                  <Select
                    classes={{ root: classes.selectRoot }}
                    id="services-list"
                    disabled={isReadOnly}
                    defaultValue={name}
                    onChange={(event) => {
                      handleServiceChange(key, "name", event);
                    }}
                    onClose={(event) => {
                      event.stopPropagation();
                    }}
                    disableUnderline
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {availableServices.map(({ name: serviceName }) => (
                      <MenuItem key={serviceName} value={serviceName}>
                        {serviceName}
                      </MenuItem>
                    ))}
                  </Select>
                </AccordionSummary>

                <AccordionDetails style={{ paddingTop: 16 }}>
                  <Grid container>
                    <Grid item xs={4} style={{ marginRight: 20 }}>
                      <TextField
                        label={t("incomingOrders.price")}
                        value={price}
                        fullWidth
                        disabled={isReadOnly}
                        required
                        type="number"
                        onChange={(event) =>
                          handleServiceChange(key, "price", event)
                        }
                      />
                    </Grid>
                    <Grid item xs={3} style={{ marginRight: 20 }}>
                      <FormControl fullWidth>
                        <InputLabel>
                          {t("webapp:incomingOrder.form.label.currency")}
                        </InputLabel>
                        <Select
                          defaultValue={priceCurrency}
                          disabled={isReadOnly}
                          onChange={(event) =>
                            handleServiceChange(key, "priceCurrency", event)
                          }
                        >
                          {
                            Object.values(Currency).map((currency) => (
                              <MenuItem key={currency} value={currency}>
                                {currency}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControl>
                        <FormControlLabel
                          label={t("webapp:incomingOrder.form.label.isWithDph")}
                          disabled={isReadOnly}
                          control={(
                            <Checkbox
                              color="primary"
                              checked={isPriceWithDph}
                              onChange={(event) =>
                                handleServiceChange(key, "isPriceWithDph", event, "checked")
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {
                      type === 'CASH_ON_DELIVERY' ? (
                        <Grid item xs={4} style={{ marginRight: 20 }}>
                          <TextField
                            label={t("incomingOrders.cashOnDelivery")}
                            value={value}
                            fullWidth
                            disabled={isReadOnly}
                            required
                            type="number"
                            onChange={(event) =>
                              handleServiceChange(key, "value", event)
                            }
                          />
                        </Grid>
                      ) : undefined
                    }
                    {
                      type === 'POOLING' ? (
                        <Grid item xs={4} style={{ marginRight: 20 }}>
                          <TextField
                            label={t("incomingOrders.pooling")}
                            value={value}
                            fullWidth
                            disabled={isReadOnly}
                            required
                            type="number"
                            onChange={(event) =>
                              handleServiceChange(key, "value", event)
                            }
                          />
                        </Grid>
                      ) : undefined
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid
              item
              xs={1}
              style={{ display: "flex", alignItems: "start" }}
            >{!isReadOnly ?
              <RemoveCircleIcon
                style={{ fill: "#757575", marginTop: "24px", cursor: "pointer" }}
                onClick={() => removeService(key)}
              /> : undefined}
            </Grid>
          </>
        );
      })}

      <Grid item xs={11} />
      <Grid item xs={1}>
        {!isReadOnly ?
          <AddCircleIcon onClick={addService} style={{ cursor: "pointer" }} />
          : undefined}
      </Grid>
    </Grid>
  );
};

export default IncomingOrderServicesInput;
