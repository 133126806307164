import React from "react";

import { ShipmentActivityType, JourneyWaypointType } from "@cargoticcom/model";
import { Typography } from "@material-ui/core";

import { useTranslation, Trans } from "react-i18next";

import {
  ActivityTimeline,
  ActivityType,
} from "../../../../cargotic-webapp-component";

const ShipmentActivityTimeline = ({ activity, ...props }) => {
  const { t } = useTranslation();

  const getStatusUpdateTitleText = (statusName) => {
    return <Trans
      i18nKey="webapp:shipment.form.activity.statusUpdate"
      values={{ statusName }}
      components={{
        b: <b />,
      }}
    />;

    // return t("webapp:shipment.form.activity.statusUpdate",{
    //   statusName
    // });
  }

  const getWaypointDriveThroughTitleText = (type, waypointType, address) => {
    if (type === ShipmentActivityType.WAYPOINT_DRIVE_THROUGH_CREATE) {
      if (waypointType === JourneyWaypointType.LOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadCreate"
          values={{address}}
          components={{
            b: <b/>,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.UNLOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointUnloadCreate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.BOTH) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadUnloadCreate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointDriveThroughCreate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      }
    } else if (type === ShipmentActivityType.WAYPOINT_DRIVE_THROUGH_UPDATE) {
      if (waypointType === JourneyWaypointType.LOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadUpdate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.UNLOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointUnloadUpdate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.BOTH) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadUnloadUpdate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointDriveThroughUpdate"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      }
    } else {
      if (waypointType === JourneyWaypointType.LOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadDelete"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.UNLOAD) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointUnloadDelete"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else if (waypointType === JourneyWaypointType.BOTH) {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointLoadUnloadDelete"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      } else {
        return <Trans
          i18nKey="webapp:shipment.form.activity.waypointDriveThroughDelete"
          values={{ address }}
          components={{
            b: <b />,
          }}
        />;
      }
    }
  };

  return (
    <ActivityTimeline
      activity={activity.map((item) => {
        const { type } = item;

        if (type === ShipmentActivityType.SHIPMENT_COMMENTARY) {
          const { commentary } = item;

          return {
            type: ActivityType.COMMENTARY,
            commentary,
          };
        }

        if (type === ShipmentActivityType.SHIPMENT_CREATE) {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:shipment.form.activity.create")}
              </Typography>
            ),
            createdAt,
          };
        }

        if (type === ShipmentActivityType.SHIPMENT_UPDATE) {
          const { user, isAutoUpdated, year, month, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user: isAutoUpdated
              ? {
                  id: -1,
                  name: "Cargobot",
                }
              : user,
            title: (
              <Typography>
                {t("webapp:shipment.form.activity.update")}
                {month}
                {year}
              </Typography>
            ),
            createdAt,
          };
        }

        if (type === "STATUS_UPDATE") {
          const { createdAt, statusName, user, isAutoUpdated } = item;
          return {
            type: ActivityType.PROGRESS,
            user: isAutoUpdated
            ? {
                id: -1,
                name: "Cargobot",
              }
            : user,
            createdAt,
            title: (
              <Typography>
                {getStatusUpdateTitleText(statusName)}
              </Typography>
            )
          };
        }

        if (type === ShipmentActivityType.WAYPOINT_DRIVE_THROUGH_CREATE) {
          const { user, address, createdAt, waypointType } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {getWaypointDriveThroughTitleText(type, waypointType, address)}
              </Typography>
            ),
            createdAt,
          };
        }

        if (type === ShipmentActivityType.WAYPOINT_DRIVE_THROUGH_UPDATE) {
          const { user, address, createdAt, waypointType } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {getWaypointDriveThroughTitleText(type, waypointType, address)}
              </Typography>
            ),
            createdAt,
          };
        }

        if (type === ShipmentActivityType.WAYPOINT_DRIVE_THROUGH_DELETE) {
          const { user, address, createdAt, waypointType } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {getWaypointDriveThroughTitleText(type, waypointType, address)}
              </Typography>
            ),
            createdAt,
          };
        }

        if (type === "INCOMING_ORDER_CREATE") {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:incomingOrder.form.activity.create")}
              </Typography>
            ),
            createdAt
          };
        }

        if (type === "INCOMING_ORDER_UPDATE") {
          const { user, isAutoUpdated, year, month, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user: isAutoUpdated
              ? {
                  id: -1,
                  name: "Cargobot",
                }
              : user,
            title: (
              <Typography>
                {t("webapp:incomingOrder.form.activity.update")}
                {month}
                {year}
              </Typography>
            ),
            createdAt
          };
        }

        if (type === "INCOMING_ORDER_COMMENTARY") {
          const { commentary } = item;

          commentary.createdAt = Date.parse(commentary.createdAt);
          return {
            type: ActivityType.COMMENTARY,
            commentary,
          };
        }
        if (type === "OUTCOMING_ORDER_CREATE") {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:outcomingOrder.form.activity.create")}
              </Typography>
            ),
            createdAt
          };
        }

        if (type === "OUTCOMING_ORDER_UPDATE") {
          const { user, isAutoUpdated, year, month, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user: isAutoUpdated
              ? {
                  id: -1,
                  name: "Cargobot",
                }
              : user,
            title: (
              <Typography>
                {t("webapp:outcomingOrder.form.activity.update")}
                {month}
                {year}
              </Typography>
            ),
            createdAt
          };
        }

        if (type === "OUTCOMING_ORDER_COMMENTARY") {
          const { commentary } = item;

          commentary.createdAt = Date.parse(commentary.createdAt);
          return {
            type: ActivityType.COMMENTARY,
            commentary,
          };
        }

        if (type === "WAREHOUSE_ORDER_CREATE") {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
                <Typography>
                  {t("webapp:warehouseOrder.activity.create")}
                </Typography>
            ),
            createdAt
          };
        }

        if (type === "WAREHOUSE_ORDER_MOVEMENT") {
          const { user, createdAt, data: { action, cargoItemPackageSequence, incomingOrderIndexNumber }} = item;
          const packageIndexNumber = `${incomingOrderIndexNumber}${cargoItemPackageSequence.toString()
            .padStart(2, "0")}`;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
                <Typography>
                  {t(`webapp:warehouseOrder.activity.movement.${action === "IN" ? `receive` : `dispatch`}`, { packageIndexNumber })}
                </Typography>
            ),
            createdAt
          };
        }

        throw new Error(`Unknown shipment activity type '${type}'!`);
      })}
      {...props}
    />
  );
};

export default ShipmentActivityTimeline;
