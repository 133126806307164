import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  makeStyles,
  CardActionArea
} from "@material-ui/core";

import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Add as AddIcon,
  AvTimer as AvTimerIcon,
  LocalShipping as LocalShippingIcon,
  ShowChart as ShowChartIcon,
  ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";

import { TimePeriod } from "@cargoticcom/model";
import { Currency } from "@cargoticcom/common";

import useRouter from "../../../cargotic-webapp/component/hook/useRouter";
import {
  OutlinedIconButton,
  StatisticCard
} from "../../../cargotic-webapp-component";

import VehicleActivityTimeline from "../VehicleActivityTimeline";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > section:not(:first-child)": {
      marginTop: spacing(6)
    },

    "& > section > h5": {
      marginBottom: spacing(2)
    }
  }
}));

const VehicleOverview = ({
  className,
  user,
  travelsHighlights,
  mileageHighlights,
  profitHighlights,
  expensesHighlights,
  revenueHighlights,
  activity,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onTravelsHighlightsChange,
  onMileageHighlightsChange,
  onProfitHighlightsChange,
  onExpensesHighlightsChange,
  onRevenueHighlightsChange,
  onTravelsHeaderIconClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { history, location: { pathname } } = useRouter();

  const handleTravelsHighlightsPeriodChange = ({ target: { value } }) =>
    onTravelsHighlightsChange({
      ...travelsHighlights,
      period: value
    })

  const handleMileageHighlightsPeriodChange = ({ target: { value } }) =>
    onMileageHighlightsChange({
      ...mileageHighlights,
      period: value
    })

  const handleExpensesHighlightsPeriodChange = ({ target: { value } }) =>
    onExpensesHighlightsChange({
      ...expensesHighlights,
      period: value
    })

  const handleExpensesHighlightsCurrencyChange = ({ target: { value } }) =>
    onExpensesHighlightsChange({
      ...expensesHighlights,
      currency: value
    })

  const handleProfitHighlightsPeriodChange = ({ target: { value } }) =>
    onProfitHighlightsChange({
      ...profitHighlights,
      period: value
    })

  const handleProfitHighlightsCurrencyChange = ({ target: { value } }) =>
    onProfitHighlightsChange({
      ...profitHighlights,
      currency: value
    })

  const handleRevenueHighlightsPeriodChange = ({ target: { value } }) =>
    onRevenueHighlightsChange({
      ...revenueHighlights,
      period: value
    })

  const handleRevenueHighlightsCurrencyChange = ({ target: { value } }) =>
    onRevenueHighlightsChange({
      ...revenueHighlights,
      currency: value
    })

  useEffect(() => {
    onRevenueHighlightsChange({
      ...revenueHighlights,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    })
    onExpensesHighlightsChange({
      ...expensesHighlights,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    })
    onProfitHighlightsChange({
      ...profitHighlights,
      currency: Currency.CZK,
      period: TimePeriod.CURRENT_MONTH
    })
    onTravelsHighlightsChange({
      ...travelsHighlights,
      period: TimePeriod.CURRENT_MONTH
    })
    onMileageHighlightsChange({
      ...mileageHighlights,
      period: TimePeriod.CURRENT_MONTH
    })
  }, [])

  return (
    <div className={clsx(className, classes.root)}>
      <section>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <StatisticCard
              title={t("webapp:vehicle.title.travels")}
              period={travelsHighlights.period}
              periods={
                Object.values(TimePeriod)
                  .filter((value) => value !== "CUSTOM")
                  .map(value => ({
                    value,
                    label: t(`webapp:common.time-period.${value}`)
                  }))
              }
              units={[{ label: t("webapp:common.unit.dimensionless.total") }]}
              value={travelsHighlights.value}
              icon={LocalShippingIcon}
              rateOfChange={travelsHighlights.rateOfChange}
              trendDescription={t("webapp:common.trend.previous-month")}
              isLoading={travelsHighlights.isLoading}
              onPeriodChange={handleTravelsHighlightsPeriodChange}
              headerAdornment={(
                <OutlinedIconButton
                  size="small"
                  onClick={onTravelsHeaderIconClick}
                >
                  <ArrowForwardIcon />
                </OutlinedIconButton>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <StatisticCard
              title={t("webapp:vehicle.title.mileage")}
              period={mileageHighlights.period}
              periods={
                Object.values(TimePeriod)
                  .filter((value) => value !== "CUSTOM")
                  .map(value => ({
                    value,
                    label: t(`webapp:common.time-period.${value}`)
                  }))
              }
              units={[{ label: "Km" }]}
              value={mileageHighlights.value}
              icon={AvTimerIcon}
              rateOfChange={mileageHighlights.rateOfChange}
              trendDescription={t("webapp:common.trend.previous-month")}
              isLoading={mileageHighlights.isLoading}
              onPeriodChange={handleMileageHighlightsPeriodChange}
            />
          </Grid>
          <Grid item xs={4}>
            <StatisticCard
              title={t("webapp:vehicle.title.expenses")}
              period={expensesHighlights.period}
              periods={
                [
                  TimePeriod.CURRENT_MONTH,
                  TimePeriod.LAST_MONTH,
                  TimePeriod.ALL_TIME
                ].map(value => ({
                  value,
                  label: t(`webapp:common.time-period.${value}`)
                }))
              }
              units={
                Object.values(Currency).map(value => ({ label: value, value }))
              }
              unit={expensesHighlights.currency}
              value={Math.round(expensesHighlights.value)}
              icon={AccountBalanceWalletIcon}
              rateOfChange={expensesHighlights.rateOfChange}
              trendDescription={t("webapp:common.trend.previous-month")}
              onPeriodChange={handleExpensesHighlightsPeriodChange}
              onUnitChange={handleExpensesHighlightsCurrencyChange}
              isLoading={expensesHighlights.isLoading}
              isTrendInverted
            />
          </Grid>
          <Grid item xs={4}>
            <StatisticCard
              title={t("webapp:vehicle.title.revenue")}
              period={revenueHighlights.period}
              periods={
                Object.values(TimePeriod)
                  .filter((value) => value !== "CUSTOM")
                  .map(value => ({
                    value,
                    label: t(`webapp:common.time-period.${value}`)
                  }))
              }
              units={
                Object.values(Currency).map(value => ({ label: value, value }))
              }
              unit={revenueHighlights.currency}
              value={Math.round(revenueHighlights.value)}
              icon={ShowChartIcon}
              rateOfChange={revenueHighlights.rateOfChange}
              trendDescription={t("webapp:common.trend.previous-month")}
              onPeriodChange={handleRevenueHighlightsPeriodChange}
              onUnitChange={handleRevenueHighlightsCurrencyChange}
              headerAdornment={(
                <OutlinedIconButton
                  size="small"
                  onClick={() => history.push(`${pathname}/incomes?isEditorOpen`)}
                >
                  <AddIcon />
                </OutlinedIconButton>
              )}
              isLoading={revenueHighlights.isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <StatisticCard
              title={t("webapp:vehicle.title.profit")}
              period={profitHighlights.period}
              periods={
                [
                  TimePeriod.CURRENT_MONTH,
                  TimePeriod.LAST_MONTH,
                  TimePeriod.ALL_TIME
                ].map(value => ({
                  value,
                  label: t(`webapp:common.time-period.${value}`)
                }))
              }
              units={
                Object.values(Currency).map(value => ({ label: value, value }))
              }
              unit={profitHighlights.currency}
              value={Math.round(profitHighlights.value)}
              icon={ShowChartIcon}
              rateOfChange={profitHighlights.rateOfChange}
              trendDescription={t("webapp:common.trend.previous-month")}
              onPeriodChange={handleProfitHighlightsPeriodChange}
              onUnitChange={handleProfitHighlightsCurrencyChange}
              isLoading={profitHighlights.isLoading}
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h5">
          <b>
            {t("webapp:common.title.activity")}
          </b>
        </Typography>
        <VehicleActivityTimeline
          activity={activity}
          user={user}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
        />
      </section>
    </div>
  );
};

export default VehicleOverview;
