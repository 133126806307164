import React from "react";
import firebase from "firebase/compat/app";

import { useSnackbar } from "notistack";
import {
  Button, Typography, makeStyles, TextField
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import useAuth from "../../hook/useAuth";
import useRouter from "../../hook/useRouter";
import { deserializeQuery } from "../../../utility/common";
import { ChangePasswordSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(1)
  },
  caption: {
    marginBottom: spacing(2)
  },
  bottomMargin: {
    marginBottom: spacing(1)
  }
}));

const AuthActionForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authenticated, reload } = useAuth();
  const { history, location: { search } } = useRouter();
  const { oobCode: code } = deserializeQuery(search.substring(1));
  const { mode } = deserializeQuery(search);

  const { enqueueSnackbar } = useSnackbar();

  const setNewPassword = (password) => firebase.auth().verifyPasswordResetCode(code)
    .then((email) => firebase.auth().confirmPasswordReset(code, password)
      .then((resp) => {
        enqueueSnackbar(t("auth.resetPassword.success"), { variant: "success" });
      }))
    .then(() => {
      history.push("/");
    })
    .catch((error) => {
      console.error(error);

      enqueueSnackbar(t("auth.resetPassword.error.invalidActionCode"), { variant: "error" });
      history.push("/");
    });

  const verifyEmail = () => {
    firebase.auth().applyActionCode(code)
      .then(() => {
        if (authenticated) {
          reload();
        }

        enqueueSnackbar(t("auth.emailVerification.success"), { variant: "success" });
        history.push("/");
      })
      .catch((error) => {
        console.log(error);

        let message = null;

        switch (error.code) {
        case "auth/expired-action-code": {
          message = "auth.emailVerification.error.expiredActionCode";
          break;
        }
        case "auth/invalid-action-code": {
          message = "auth.emailVerification.error.invalidActionCode";
          break;
        }
        case "auth/user-disabled": {
          message = "auth.emailVerification.error.userDisabled";
          break;
        }
        case "auth/user-not-found": {
          message = "auth.emailVerification.error.userNotFound";
          break;
        }
        default: {
          message = "auth.emailVerification.error.other";
          break;
        }
        }

        enqueueSnackbar(t(message), { variant: "error" });
      });
  };

  const verifyEmailPage = () => (
    <>
      <Typography className={classes.title} variant="h5" gutterBottom>
        {t("auth.emailVerification.title")}
      </Typography>
      <Typography className={classes.caption} variant="caption" gutterBottom>
        {t("auth.emailVerification.caption")}
      </Typography>
      <Button
        type="button"
        color="primary"
        variant="contained"
        className={classes.bottomMargin}
        onClick={verifyEmail}
        disabled={code === undefined}
      >
        {t("auth.emailVerification.action")}
      </Button>
    </>
  );

  const resetPassword = () => (
    <Formik
      initialValues={{
        newPassword: ""
      }}
      onSubmit={({ newPassword }, { setSubmitting }) => setNewPassword(newPassword).then(() => setSubmitting(false))}
      validationSchema={ChangePasswordSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        handleBlur,
        handleChange,
        values,
        errors,
        touched
      }) => (
        <form onSubmit={handleSubmit}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            {t("auth.resetPassword.title")}
          </Typography>
          <Typography className={classes.caption} variant="caption" gutterBottom>
            {t("auth.resetPassword.caption")}
          </Typography>
          <div>
            <TextField
              name="newPassword"
              className={classes.bottomMargin}
              fullWidth
              label={t("password")}
              required
              type="password"
              value={values.newPassword}
              error={errors.newPassword && touched.newPassword}
              helperText={touched.newPassword && t(errors.newPassword) || " "}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={code === undefined || isSubmitting}
          >
            {t("auth.resetPassword.action")}
          </Button>
        </form>
      )}
    </Formik>
  );

  const section = () => {
    switch (mode) {
    case "verifyEmail":
      return verifyEmailPage();
    case "resetPassword":
      return resetPassword();
    default:
      history.push("/");
      return null;
    }
  };

  return (
    <>
      {section()}
      <div>
        <Typography variant="caption">
          {t("newToCargotic")}
          &nbsp;
          <Link to="/sign-up">
            {t("signUp")}
            !
          </Link>
        </Typography>
        <Typography variant="caption" paragraph>
          {t("goBackTo")}
          &nbsp;
          <Link to="/sign-in">{t("signIn")}</Link>
        </Typography>
      </div>
    </>
  );
};

export default AuthActionForm;
