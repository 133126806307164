import { differenceInDays, endOfMonth, endOfToday, isBefore, parseISO, startOfMonth, } from "date-fns";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { Grid, IconButton, Link, Chip, makeStyles, Tooltip, Typography, Button } from "@material-ui/core";

import { CallMade, Cancel, CheckCircle, Error, } from "@material-ui/icons";

import { ContactType } from "@cargoticcom/model";
import { formatDate } from "@cargoticcom/common";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import IncomingOrdersDateTime from "../IncomingOrders/IncomingOrdersDateTime";
import IncomingOrdersRoute from "../IncomingOrders/IncomingOrdersRoute";
import IncomingOrdersRouteContact from "../IncomingOrders/IncomingOrdersRouteContact";

import useAuth from "../../hook/useAuth";
import { addUrlParam, getTableUrlParams } from "../../../utility/window";
import { loadFilters, storeFilters } from "../../../storage";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import { useHistory } from "react-router-dom";

import {
  createMatchQueryWarehouseOrders,
  createPlacesQuery,
  createStateMatchQuery,
  createStateSuggestQuery,
  createGetBoxes
} from "../../../resource";
import IncomingOrdersDeleteDialog from "../IncomingOrders/IncomingOrdersDeleteDialog";

import { useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";
import IncomingOrdersOutcomingOrderReference from "../IncomingOrders/IncomingOrdersOutcomingOrderReference";
import LinehallWarehouseOrders from "./LinehallWarehouseOrders";
import WarehouseOrderTableRow from "../WarehouseOrders/WarehouseOrderTableRow";
import { flatten } from "lodash";
import WarehouseOrdersDateTime from "../WarehouseOrders/components/WarehouseOrdersDateTime";
import WarehouseOrdersRoute from "../WarehouseOrders/components/WarehouseOrdersRoute";
import WarehouseOrdersPackagesFulfilled from "../WarehouseOrders/components/WarehouseOrdersPackagesFulfilled";
import { uniq } from "lodash";
import { formatDateTime } from "../../../utility/common";

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20,
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20,
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)",
  },
  inlineContent: {
    display: "inline-flex",
  },
  warning: {
    fill: "silver",
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main,
  },
  success: {
    fill: "#009688",
  },
}));

const LinehallWarehouseOrdersContainer = ({
  warehouse,
  setWarehouseOrders,
  warehouseOrders,
  setSelectedWarehouseOrders
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    location: { search: routerSearch },
  } = useRouter();

  const { searchText: initSearchText, filter: initFilter } =
    getTableUrlParams(routerSearch);

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }
  const { setShipment } = useShipmentConcept();

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);
  const [
    isIncomingOrdersExportDialogOpen,
    setIsIncomingOrdersExportDialogOpen,
  ] = useState(false);
  const [
    isIncomingOrdersExportProcessing,
    setIsIncomingOrdersExportProcessing,
  ] = useState(false);
  const [isIncomingOrdersExportDone, setIsIncomingOrdersExportDone] =
    useState(false);
  const [incomingOrdersExportLink, setIncomingOrdersExportLink] =
    useState(null);
  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const paymentAvailable = useRef(true);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("lh-incoming-orders", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };


  const getReceiversNames = (cargoItems) => {
    let receiverNames = [];
    cargoItems.map(cargoItem => {
      if (!receiverNames.some(receiverName => receiverName === cargoItem.receivedBy.name)) {
        receiverNames.push(cargoItem.receivedBy.name)
      }
    })
    return receiverNames;
  }

  const getDispatchersNames = (cargoItems) => {
    let dispatchersName = [];
    cargoItems.map(cargoItem => {
      if (!dispatchersName.some(dispatcherName => dispatcherName === cargoItem.dispatchedBy.name)) {
        dispatchersName.push(cargoItem.dispatchedBy.name)
      }
    })
    return dispatchersName;
  }

  const defaultFilterValues = [
    "customers",
    "loadingDate",
    "unloadingDate",
    "creators",
    "customerPrice",
    "cargo",
    "createdAt",
  ];
  const availableFilters = [
    {
      label: t("contacts.customer"),
      value: "customers",
    },
    {
      label: t("incomingOrders.loadingsDateRange"),
      value: "loadingDate",
    },
    {
      label: t("incomingOrders.unloadingsDateRange"),
      value: "unloadingDate",
    },
    {
      label: t("incomingOrders.creationDate"),
      value: "createdAt",
    },
    {
      label: t("incomingOrders.customerPrice"),
      value: "customerPrice",
    },
    {
      label: t("incomingOrders.creator"),
      value: "creators",
    },
    {
      label: t("incomingOrders.cargo"),
      value: "cargo",
    },
    {
      label: t("incomingOrders.state"),
      value: "incomingOrderState",
    },
    {
      label: t("incomingOrders.places"),
      value: "places",
    },
    {
      label: t("incomingOrders.box"),
      value: "boxes",
    },
  ];

  const classes = useStyles();

  const apiClient = useApiClient();

  let reloadDelay;
  let storeSearchDelay;

  const transformFilter = (filter) => ({
    ...filter,
    isDraft: false,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers ? filter.customers.map(({ id }) => id) : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined,
    places: filter.places ? filter.places.map(({ id }) => id) : undefined,
    state: filter.state ? filter.state.map(({ id }) => id) : undefined,
    boxes: filter.boxes ? filter.boxes.map(({ box }) => box) : undefined
  });

  const isDue = (dateString) =>
    dateString && isBefore(parseISO(dateString), new Date());
  const getOverdueDays = (dateString) =>
    dateString ? differenceInDays(new Date(), parseISO(dateString)) : undefined;

  const getUniqueReceivedDates = (item) => {
    return uniq(item.warehouseOrder.cargoItemPackages.map(cip => cip.receivedDate).filter(rd => !!rd)).map(rd => formatDateTime(new Date(rd)))
  }

  const getUniqueDispatchedDates = (item) => {
    return uniq(item.warehouseOrder.cargoItemPackages.map(cip => cip.dispatchedDate).filter(rd => !!rd)).map(rd => formatDateTime(new Date(rd)))
  }

  const reloadWarehouseOrders = useCallback(
    async (offset, limit, ordering) => {
      const filter = transformFilter(additionalFilter);
      try {
        const warehouseStates = (await createStateMatchQuery({
          resources: ["warehouse_order"],
          offset: 0,
          limit: 25,
          orderBy: []
        })).matches;

        const dispatchedStateId = warehouseStates.find(state => state.name === "Vydáno").stateId
        const receivedStateIds = warehouseStates.filter(state => state.name !== "Vydáno").map(state => state.stateId);
        const warehouseOrdersResponse = await createMatchQueryWarehouseOrders({
          match: {
            search,
            warehouseId: warehouse.id,
            warehouseState: receivedStateIds,
            ...filter,
          },
          orderBy: ordering,
          offset,
          limit
        })
        setWarehouseOrders(warehouseOrdersResponse.matches)

        if (warehouseOrdersResponse.total === 0 && offset !== 0) {
          handleChangePage(undefined, 0);
        }

        const updatedWarehouseOrders = warehouseOrdersResponse.matches.map(
          (item) => {
            const isDisabled = item.isDisabled === 1;
            const selected = false;
            const id = item.id;

            const row = [
              {
                render: !item.isDraft ? (
                  <Typography variant="body2"> #
                    {item.indexNumber}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    <i>{t("incomingOrders.draft")}</i>
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    <Link
                      component={RouterLink}
                      to={`/contacts/${item.customerContact.id}`}
                    >
                      {item.customerContact.name ||
                        item.customerContact.email ||
                        item.customerContact.phoneNumber}
                    </Link>
                  </Typography>
                ),
              },
              {
                render: <WarehouseOrdersDateTime order={item} />,
              },
              {
                render: <WarehouseOrdersRoute order={item} />,
              },
              {
                render: (
                  <Typography variant="body2">
                    {item.customerPrice != null
                      ? `${item.customerPrice.toFixed(0)} ${item.customerPriceCurrency
                      }`
                      : null}
                  </Typography>
                ),
              },
              {
                render: (
                  <WarehouseOrdersPackagesFulfilled order={item} />
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {getUniqueReceivedDates(item)}
                  </Typography>
                ),
              },
              {
                render: (
                  <Typography variant="body2">
                    {getReceiversNames(item.warehouseOrder.cargoItemPackages)
                      .map((receiverName, index) => (
                        <>
                          {receiverName}
                          {(index + 1) < getReceiversNames(item.warehouseOrder.cargoItemPackages).length && <>,&nbsp;</>}
                        </>
                      ))
                    }
                  </Typography>
                ),
              },
              {
                render: <Typography variant="body2">{item.externalReference}</Typography>
              },
              {
                render: <Typography variant="body2">{item.box}</Typography>,
              },
            ];
            return { ...item, id, row, selected, isDisabled };
          });
        // item.tableCells.splice(
        //   4,
        //   0,
        //   {
        //     render: <WarehouseOrderState warehouseOrder={item} state={warehouseStates} reload={reloadData} />
        //   }
        // );

        // const warehouseOrderData = updatedWarehouseOrders.reduce(
        //   (acc, curr) => [
        //     ...acc,
        //     {
        //       type: curr.type,
        //       id: curr.id,
        //       row: curr.tableCells,
        //       selected: false,
        //       detail: ({ setDetailed }) => (
        //         <ShipmentOverview
        //           incomingOrder={curr}
        //           warehouseOrderId={curr.warehouseOrder.id}
        //           type="WAREHOUSE_ORDER"
        //           shipments={updatedWarehouseOrders}
        //           setShipments={() => {
        //           }}
        //           onSave={() => {
        //           }}
        //           open
        //           setOpen={() => setDetailed(null)}
        //         />
        //       ),
        //     },
        //   ],
        //   []
        // );

        setWarehouseOrders(updatedWarehouseOrders);
        return {
          data: updatedWarehouseOrders,
          totalCnt: warehouseOrdersResponse.total,
        };
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("incomingOrders.error.get"), {
          variant: "error",
        });
      }
    },
    [search, additionalFilter, warehouse]
  );

  const handleSelectLoadingsDateRange = (loadingDate) =>
    setAdditionalFilter({ ...additionalFilter, loadingDate });
  // const handleSelectIncomingOrderOrderState = (state) => setAdditionalFilter({...additionalFilter, incomingOrderState: state });

  const handleSelectUnloadingsDateRange = (unloadingDate) =>
    setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) =>
    setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectIncomingOrderState = (state) =>
    setAdditionalFilter({ ...additionalFilter, state });
  const handleSelectCustomer = (customers) =>
    setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarrier = (carriers) =>
    setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectVehicles = (vehicles) =>
    setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCustomerPrice = (customerPrice) =>
    setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) =>
    setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) =>
    setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) =>
    setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) =>
    setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) =>
    setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) =>
    setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) =>
    setAdditionalFilter({ ...additionalFilter, receivedPaymentState });
  const handleSelectPlaces = (places) => setAdditionalFilter({ ...additionalFilter, places });
  const handleSelectBoxes = (boxes) => setAdditionalFilter({ ...additionalFilter, boxes });

  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now),
    });
  };

  const onSubmit = () => {
    if (!selectedIncomingOrders.length) {
      enqueueSnackbar(t("incomingOrders.noSelection", { variant: "error" }));
    } else {
      setShipment({
        incomingOrderIds: selectedIncomingOrders.map(({ incomingOrderId }) => incomingOrderId),
        incomingOrders: selectedIncomingOrders,
        warehouseOrderIds: selectedIncomingOrders.map(({ id }) => id),
        mode: "LH",
        warehousePlace: warehouse.place,
        fresh: true
      });
      history.push("/shipment");
    }
  }

  const loadAvailableUsers = async (search, roles = undefined) => {
    try {
      const { matches } = await apiClient.user.postUserMatchQuery({
        query: {
          match: { search, roles },
          limit: 15,
        },
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableIncomingOrderState = async (search) => {
    try {
      const states = await createStateSuggestQuery({
        resources: ["incoming_order"]
      });
      return states;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search,
          types: ["BOTH", type],
        },
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search,
        },
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId,
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle],
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitContact = async (contactId) => {
    try {
      const contact = await apiClient.contact.getContact({
        contactId,
      });

      if (contact.type === ContactType.CUSTOMER) {
        setAdditionalFilter({
          ...additionalFilter,
          customers: [contact],
        });
      } else {
        setAdditionalFilter({
          ...additionalFilter,
          carriers: [contact],
        });
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  useEffect(() => {
    const { filterVehicleId, filterContactId } =
      queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
    if (filterContactId) {
      loadInitContact(filterContactId);
    }
  }, []);


  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData,
  } = useTable(reloadWarehouseOrders, "warehouse-shipments-orders");

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) =>
    values.map((item) => fullValues.find((i) => i.value === item));

  useEffect(() => {
    const loadedFilters = loadFilters("lh-incoming-orders");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  const selectedIncomingOrders = data.filter(({ selected }) => selected);

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedIncomingOrders.map(({ id }) => id);
    const requests = ids.map((incomingOrderId) =>
      apiClient.incomingOrder.deleteIncomingOrderById({ incomingOrderId })
    );

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("orders.error.delete"), {
          variant: "error",
        });
      });
  };


  const handleLoadAvailablePlaces = async (search) => {
    try {
      const { places } = await createPlacesQuery({
        filter: { search, id: null }
      });
      return places;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }


  const handleLoadAvailableBoxes = async (search) => {
    try {
      const boxes = await createGetBoxes({
        filter: { search }
      });
      return boxes;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  const { hasPermission, user } = useAuth();
  const canUpdateIncomingOrder =
    hasPermission("resource.incomingOrder.company.update") ||
    hasPermission("resource.incomingOrder.user.update");
  const canCreateInvoice = hasPermission(
    "resource.incomingOrder.invoice.create"
  );
  const canCreateIncomingOrder = hasPermission(
    "resource.incomingOrder.user.create"
  );
  const canCreateOrderPdf = hasPermission(
    "resource.incomingOrder.summary.read"
  );
  const canDeleteIncomingOrder =
    hasPermission("resource.incomingOrder.user.delete") ||
    hasPermission("resource.incomingOrder.company.delete");
  const canReadInvoice = hasPermission("resource.incomingOrder.invoice.read");
  const canReadCompanyIncomingOrder = hasPermission(
    "resource.incomingOrder.company.read"
  );

  return (
    <>
      <LinehallWarehouseOrders
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        incomingOrders={warehouseOrders}
        setIncomingOrders={setWarehouseOrders}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        canCreateOrderPdf={canCreateOrderPdf}
        canUpdateIncomingOrder={canUpdateIncomingOrder}
        canCreateInvoice={canCreateInvoice}
        canCreateIncomingOrder={canCreateIncomingOrder}
        canDeleteIncomingOrder={canDeleteIncomingOrder}
        canReadInvoice={canReadInvoice}
        canReadCompanyIncomingOrder={canReadCompanyIncomingOrder}
        isIncomingOrdersExportDialogOpen={isIncomingOrdersExportDialogOpen}
        isIncomingOrdersExportProcessing={isIncomingOrdersExportProcessing}
        isIncomingOrdersExportDone={isIncomingOrdersExportDone}
        incomingOrdersExportLink={incomingOrdersExportLink}
        additionalFilter={additionalFilter}
        handleSort={handleSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
        handleDeleteRequest={() => setDeleteDialogOpen(true)}
        handleSelectLastMonth={handleSelectLastMonth}
        filter={additionalFilter}
        handleDeselect={handleDeselect}
        loadAvailableUsers={loadAvailableUsers}
        defaultFilters={defaultFilters}
        handleSelectIncomingOrderState={handleSelectIncomingOrderState}
        handleSelectCustomer={handleSelectCustomer}
        handleSelectCustomerPrice={handleSelectCustomerPrice}

        loadAvailableContacts={loadAvailableContacts}
        handleLoadAvailablePlaces={handleLoadAvailablePlaces}
        handleLoadAvailableBoxes={handleLoadAvailableBoxes}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        loadAvailableIncomingOrderState={loadAvailableIncomingOrderState}
        // handleSelectIncomingOrderOrderState={handleSelectIncomingOrderOrderState}

        handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
        handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
        handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
        handleSelectCreators={handleSelectCreators}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
        handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
        handleSelectPlaces={handleSelectPlaces}
        handleSelectBoxes={handleSelectBoxes}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
      />
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary">
        <>{t("next")}</>
      </Button>
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default LinehallWarehouseOrdersContainer;
