import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { VehicleExpirationType } from "@cargoticcom/model";

import {
  Button,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import { yellow } from "@material-ui/core/colors";
import {
  Check as CheckIcon,
  Warning as WarningIcon
} from "@material-ui/icons";

import { hexToRgba } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",

    color: palette.common.white,

    padding: spacing(2),

    textAlign: "center"
  },
  content: {
    "& > span": {
      fontSize: 90
    },
    "& > svg": {
      fontSize: 120
    }
  },
  actions: {
    "& button": {
      width: "100%",

      borderColor: hexToRgba(palette.common.white, 0.5),
      color: palette.common.white,

      "&:hover": {
        backgroundColor: hexToRgba(palette.common.white, 0.04),
        borderColor: palette.common.white
      }
    }
  },
  ready: {
    background: palette.primary.main
  },
  warning: {
    background: yellow[700]
  }
}));

const VehicleProfileStatusBox = ({
  className,
  status,
  isExpirationCreateButtonDisabled,
  isShipmentCreateButtonDisabled,
  onExpirationAdd,
  onShipmentCreateButtonClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [contentText, buttonText, handleClick] = (() => {
    if (status === "first-aid-kit-undefined") {
      return [
        t("webapp:vehicle.status.first-aid-kit-undefined"),
        t("webapp:vehicle.title.add-first-aid-kit"),
        () => onExpirationAdd(VehicleExpirationType.FIRST_AID_KIT)
      ];
    }

    if (status === "first-aid-kit-expired") {
      return [
        t("webapp:vehicle.status.first-aid-kit-expired"),
        t("webapp:vehicle.title.add-first-aid-kit"),
        () => onExpirationAdd(VehicleExpirationType.FIRST_AID_KIT)
      ];
    }

    if (status === "vehicle-inspection-undefined") {
      return [
        t("webapp:vehicle.status.vehicle-inspection-undefined"),
        t("webapp:vehicle.title.add-vehicle-inspection"),
        () => onExpirationAdd(VehicleExpirationType.VEHICLE_INSPECTION)
      ];
    }

    if (status === "vehicle-inspection-expired") {
      return [
        t("webapp:vehicle.status.vehicle-inspection-expired"),
        t("webapp:vehicle.title.add-vehicle-inspection"),
        () => onExpirationAdd(VehicleExpirationType.VEHICLE_INSPECTION)
      ];
    }

    if (status === "vehicle-insurance-undefined") {
      return [
        t("webapp:vehicle.status.vehicle-insurance-undefined"),
        t("webapp:vehicle.title.add-vehicle-insurance"),
        () => onExpirationAdd(VehicleExpirationType.VEHICLE_INSURANCE)
      ];
    }

    if (status === "vehicle-insurance-expired") {
      return [
        t("webapp:vehicle.status.vehicle-insurance-expired"),
        t("webapp:vehicle.title.add-vehicle-insurance"),
        () => onExpirationAdd(VehicleExpirationType.VEHICLE_INSURANCE)
      ];
    }

    if (status === "vehicle-registration-certificate-undefined") {
      return [
        t("webapp:vehicle.status.vehicle-registration-certificate-undefined"),
        t("webapp:vehicle.title.add-vehicle-registration-certificate"),
        () => onExpirationAdd(
          VehicleExpirationType.VEHICLE_REGISTRATION_CERTIFICATE
        )
      ];
    }

    if (status === "vehicle-registration-certificate-expired") {
      return [
        t("webapp:vehicle.status.vehicle-registration-certificate-expired"),
        t("webapp:vehicle.title.add-vehicle-registration-certificate"),
        () => onExpirationAdd(
          VehicleExpirationType.VEHICLE_REGISTRATION_CERTIFICATE
        )
      ];
    }

    if (status === "ready") {
      return [
        t("webapp:vehicle.status.ready"),
        t("webapp:vehicle.title.createShipment"),
        onShipmentCreateButtonClick
      ];
    }

    throw new Error(`Invalid status value '${status}'!`);
  })();

  const isButtonDisabled = (status === "ready" && isShipmentCreateButtonDisabled)
    || (status !== "ready" && isExpirationCreateButtonDisabled);

  return (
    <Paper
      className={
        clsx(
          className,
          classes.root,
          {
            [classes.ready]: status === "ready",
            [classes.warning]: status !== "ready"
          }
        )
      }
    >
      <section className={classes.content}>
        {
          status === "ready"
            ? (
              <CheckIcon className={classes.icon} />
            ) : (
              <WarningIcon className={classes.icon} />
            )
        }
        <Typography>
          {contentText}
        </Typography>
      </section>
      <section className={classes.actions}>
        <Tooltip
          title={t("webapp:common.title.noPermission")}
          disableFocusListener={!isButtonDisabled}
          disableHoverListener={!isButtonDisabled}
          disableTouchListener={!isButtonDisabled}
        >
          <span>
            <Button
              variant="outlined"
              onClick={handleClick}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </Button>
          </span>
        </Tooltip>
      </section>
    </Paper>
  );
};

export default VehicleProfileStatusBox;
