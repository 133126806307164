import React, { useEffect, useState } from "react";

import {
  Fade,
  makeStyles,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField
} from "@material-ui/core";
import queryString from "query-string";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import useRouter from "../../hook/useRouter";
import FileDropzone from "../../../../../multiload/cargotic-webapp/component/FileDropzone";
import { deleteFile } from "../../../resource";
import {
  readOutcomingOrderDataByToken,
  updateOutcomingOrderDocuments,
  postOutcomingOrderFileWithToken,
} from "../../../resource";
import FileUpload from "../../../../../multiload/cargotic-webapp/component/FileUpload";
import { parseDate, formatDate, formatDateTime } from "../../../utility/common";
import { generateUuid } from "../../../../../multiload/cargotic-common";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  mainSign: {
    backgroundColor: "#eeeee",
    minHeight: "100%",
  },
  logo: {
    width: 250,
  },
  side: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(10),
    paddingBottom: spacing(1),
    [breakpoints.up(420 + spacing(4))]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& > div": {
      marginTop: spacing(4),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      "& button": {
        width: 60,
      },
      "& > h1": {
        marginBottom: spacing(2),
      },
      "& > span": {
        textAlign: "center",
      },
      "& > form": {
        width: "100%",
        "& > section": {
          marginBottom: spacing(2),
          "& > div": {
            width: "100%",
            "&:not(last-child)": {
              marginBottom: spacing(1),
            },
          },
        },
      },
    },
  },
  main: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    textAlign: "center",
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(10),
    paddingBottom: spacing(1),
    [breakpoints.up(420 + spacing(4))]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& > div": {
      marginTop: spacing(4),
      display: "flex",
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      "& button": {
        width: "100%",
      },
      "& > h1": {
        marginBottom: spacing(2),
      },
      "& > span": {
        textAlign: "center",
      },
      "& > form": {
        width: "100%",
        "& > section": {
          marginBottom: spacing(2),
          "& > div": {
            width: "100%",
            "&:not(last-child)": {
              marginBottom: spacing(1),
            },
          },
        },
      },
    },
  },
}));

const SubmitTransportationDocuments = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    location: { search },
  } = useRouter();
  const { token } = queryString.parse(search);
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState(null);
  const [emailForConfirmation, setEmailForConfirmation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [invoiceDocuments, setInvoiceDocuments] = useState([]);
  const [transportationDocuments, setTransportationDocuments] = useState([]);
  const [hasTransportationDocumentsError, setHasTransportationDocumentsError] =
    useState(false);
  const [hasInvoiceDocumentsError, setHasInvoiceDocumentsError] =
    useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await readOutcomingOrderDataByToken(token);
        setOrderData(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  const handleDocumentDelete = async (uuid, array, setArray, bucket) => {
    const { name } = array.find(({ uuid: other }) => other === uuid);

    if (name) {
      try {
        await deleteFile(bucket, name);
      } catch (error) {
        console.error(error);

        enqueueSnackbar(t("webapp:shipment.form.document.error.file.delete"), {
          variant: "error",
        });

        return;
      }
    }

    setArray((values) => [
      ...values.filter(({ uuid: other }) => other !== uuid),
    ]);
  };

  const handleSubmit = async () => {
    try {
      await updateOutcomingOrderDocuments(token, {
        termsDocuments: invoiceDocuments.map(({ id }) => id),
        additionalDocuments: transportationDocuments.map(({ id }) => id),
        email: emailForConfirmation
      });
      setIsSubmitted(true);
      enqueueSnackbar(t("webapp:documents.send.success"), { variant: "success" });
      // history.push("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("webapp:shipment.form.document.error.file.delete"), {
        variant: "error",
      });
    }
  };

  const handleDocumentDeleteByKind = async (uuid, kind) => {
    if (kind === "TERMS") {
      handleDocumentDelete(uuid, invoiceDocuments, setInvoiceDocuments, "cgt-staging-outcoming-order-terms-documents");
    } else if (kind === "ADDITIONAL") {
      handleDocumentDelete(
        uuid,
        transportationDocuments,
        setTransportationDocuments,
        "cgt-staging-outcoming-order-additional-documents"
      );
    }
  };

  const handleDocumentFilesSelect = (files, kind) => {
    if (kind === "TERMS") {
      setHasInvoiceDocumentsError(false);
    } else if (kind === "ADDITIONAL") {
      setHasTransportationDocumentsError(false);
    }
    const bucket = "outcoming-order-documents";
    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true,
        bucket,
      };

      const updateDocument = (update) =>
        kind === "TERMS"
          ? setInvoiceDocuments((values) =>
            values.map((other) => {
              if (other.uuid !== uuid) {
                return other;
              }

              return { ...other, ...update };
            })
          )
          : setTransportationDocuments((values) =>
            values.map((other) => {
              if (other.uuid !== uuid) {
                return other;
              }

              return { ...other, ...update };
            })
          );

      if (kind === "TERMS") {
        setInvoiceDocuments((values) => [...values, document]);
      } else if (kind === "ADDITIONAL") {
        setTransportationDocuments((values) => [...values, document]);
      }

      try {
        const { url, createdAt, id } = await postOutcomingOrderFileWithToken(token, {
          bucket: "outcoming-order-documents",
          file,
          kind,
        });
        updateDocument({
          id,
          url,
          createdAt: new Date(createdAt),
          isUploading: false,
          bucket,
        });
      } catch (error) {
        console.error(error);
        updateDocument({
          isUploading: false,
          error: t(
            error?.response?.data?.error === "FileTooLargeError"
              ? "webapp:shipment.form.document.error.upload.size"
              : error?.response?.data?.error === "StorageLimitExceeded"
                ? "webapp:incomingOrder.form.document.error.upload.limit"
                : "webapp:shipment.form.document.error.upload.general"
          ),
        });
      }
    });
  };
  return (
    <div className={classes.mainSign}>
      <main className={classes.main}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : error ? (
          <Fade in timeout={1000}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3">Přeprava nenalezena</Typography>
              </Grid>
            </Grid>
          </Fade>
        ) : orderData.outcomingOrderInfo.has3rdPartyDocument ? (
        <Fade in timeout={1000}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">Přeprava má už nahrané doklady</Typography>
              <Typography>V případě dotazů nás kontaktujte na adrese <a href="mailto:invoices@wereldo.com">invoices@wereldo.com</a>.</Typography>
            </Grid>
          </Grid>
        </Fade>
        ) : (
          <Fade in timeout={1000}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h3">Nahrát doklady k přepravě</Typography>
              </Grid>
              <Grid item lg={6}>
                Číslo objednávky
              </Grid>
              <Grid item lg={6}>
                {orderData.outcomingOrderInfo.indexNumber}
              </Grid>
              <Grid item lg={6}>
                Cena
              </Grid>
              <Grid item lg={6}>
                {orderData.outcomingOrderInfo.carrierPrice}
                {" "}
                {orderData.outcomingOrderInfo.carrierPriceCurrency}
                {' '}
                {orderData.outcomingOrderInfo.isCarrierPriceWithDph
                  ? " (+ DPH)"
                  : ""}
              </Grid>
              <Grid item lg={6}>
                Vytvořeno
              </Grid>
              <Grid item lg={6}>
                {formatDate(parseDate(orderData.outcomingOrderInfo.createdAt))}
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6">Faktura + objednávka</Typography>
                <FileDropzone
                  accept={["application/pdf"]}
                  title={
                    true
                      ? t("webapp:incomingOrder.form.document.error.file.type")
                      : t("webapp:incomingOrder.form.document.title.upload")
                  }
                  description={[
                    t("webapp:incomingOrder.form.document.description.about"),
                    t(
                      "webapp:incomingOrder.form.document.description.supportedPDF"
                    ),
                    t("webapp:incomingOrder.form.document.description.limit"),
                    ,
                  ]}
                  hasError={hasInvoiceDocumentsError}
                  onError={() => setHasInvoiceDocumentsError(true)}
                  onSelect={(elements) =>
                    handleDocumentFilesSelect(elements, "TERMS")
                  }
                />
                {invoiceDocuments.map(
                  (
                    { uuid, name, url, error, isUploading, createdAt },
                    index
                  ) => (
                    <React.Fragment key={uuid}>
                      <Grid item xs={1} />
                      <Grid item xs={11}>
                        <FileUpload
                          className={index === 0 ? classes.upload : undefined}
                          title={name}
                          description={
                            createdAt ? formatDateTime(createdAt) : undefined
                          }
                          url={url}
                          isUploading={isUploading}
                          error={error}
                          onDelete={() => handleDocumentDeleteByKind(uuid, "TERMS")}
                        />
                      </Grid>
                    </React.Fragment>
                  )
                )}
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6">Doklady (DL + CMR)</Typography>
                <FileDropzone
                  accept={["application/pdf"]}
                  title={
                    true
                      ? t("webapp:incomingOrder.form.document.error.file.type")
                      : t("webapp:incomingOrder.form.document.title.upload")
                  }
                  description={[
                    t("webapp:incomingOrder.form.document.description.about"),
                    t(
                      "webapp:incomingOrder.form.document.description.supportedPDF"
                    ),
                    t("webapp:incomingOrder.form.document.description.limit"),
                    ,
                  ]}
                  hasError={hasTransportationDocumentsError}
                  onError={() => setHasTransportationDocumentsError(true)}
                  onSelect={(elements) =>
                    handleDocumentFilesSelect(elements, "ADDITIONAL")
                  }
                />
                {transportationDocuments.map(
                  (
                    { uuid, name, url, error, isUploading, createdAt },
                    index
                  ) => (
                    <React.Fragment key={uuid}>
                      <Grid item xs={1} />
                      <Grid item xs={11}>
                        <FileUpload
                          className={index === 0 ? classes.upload : undefined}
                          title={name}
                          description={
                            createdAt ? formatDateTime(createdAt) : undefined
                          }
                          url={url}
                          isUploading={isUploading}
                          error={error}
                          onDelete={() =>
                            handleDocumentDeleteByKind(uuid, "ADDITIONAL")
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  )
                )}
              </Grid>
              <Grid lg={12}>
                  <TextField
                  style={{
                    margin: 10
                  }}
                    name="email"
                      value={emailForConfirmation}
                    label="Email pro zaslání potvrzení o uložení dokladů do systému"
                    variant="filled"
                    fullWidth
                      onChange={({ target: { value }}) => setEmailForConfirmation(value)}
                  />
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!invoiceDocuments.length || !transportationDocuments.length || isSubmitted || orderData.outcomingOrderInfo.has3rdPartyDocument}>
                  Odeslat
                </Button>
                <Typography>V případě dotazů nás kontaktujte na adrese <a href="mailto:invoices@wereldo.com">invoices@wereldo.com</a>.</Typography>
              </Grid>
            </Grid>
          </Fade>
        )}
      </main>
    </div>
  );
};

export default SubmitTransportationDocuments;
