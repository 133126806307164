import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Card, makeStyles, TextField } from "@material-ui/core";

const { initializeApp } = require("firebase/compat/app");
const {
  getFirestore, doc, getDoc, collection, getDocs, query, where, orderBy
} = require("firebase/firestore");

import config from "../../../config";
import client from "../../../../cargotic-webapp/client";

import TrackingMap from "./TrackingMap";
import TrackingSideMenu from "./TrackingSideMenu";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format, startOfDay, endOfDay, startOfYesterday, endOfYesterday } from 'date-fns';

import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import { formatDateTime } from "../../../utility/common";
import { getTrackingData } from '../../../resource';
import UserAutosuggestTextField from '../../../../cargotic-webapp-component/component/UserAutosuggestTextField';
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import { createMatchQueryUsers, createShipmentsByDriverQuery } from "../../../resource";
import useAuth from "../../hook/useAuth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  field: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  }
}));

const firebaseConfig = {
  apiKey: "AIzaSyCQurc5XFvORegOoiCgKsN0G9AP1kNrTg8",
  authDomain: "cgt-staging.firebaseapp.com",
  projectId: "cgt-staging",
  storageBucket: "cgt-staging.appspot.com",
  messagingSenderId: "707256658429",
  appId: "1:707256658429:web:64d8e1658b55e1fe8fd194",
  measurementId: "G-GW710VKYQ3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const TrackingContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useAuth();

  const { isLoading: isGoogleMapsApiLoading, api: googleMapsApi } = useGoogleMapsApi();

  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [tracking, setTracking] = useState([]);
  const [plannedPath, setPlannedPath] = useState([]);
  const [driverPath, setDriverPath] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [driverIds, setDriverIds] = useState([]);
  const [hoveredTrackId, setHoveredTrackId] = useState(-1);
  const hoveredTrackIdRef = useRef(-1);
  const [expandingCard, setExpandingCard] = useState(-1);
  const [fromDateTime, setFromDateTime] = useState(format(startOfDay(new Date()), "yyyy-MM-dd HH:mm"));
  const [toDateTime, setToDateTime] = useState(format(endOfDay(new Date()), "yyyy-MM-dd HH:mm"));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isFetchingActive, setIsFetchingActive] = useState(false);
  // const suggestedUsers = useRef([]);

  const getTrackingDataFromFirestoreForUser = async (driverId, from, to) => {
    const trackingCol = collection(db, "tracking_data");
    const result = await query(trackingCol, where("userId", "==", driverId), where("insertedAt", ">=", from), where("insertedAt", "<=", to), orderBy("insertedAt"));
    const querySnapshot = await getDocs(result);
    const arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    return arr;
  }

  const getTrackingDataFromFirestore = async (driverIds, from, to) => {
    let arr = [];
    for (const id of driverIds) {
      const data = await getTrackingDataFromFirestoreForUser(id, from, to);
      arr = arr.concat(data);
    }
    return arr;
  };

  const getRoutes = async (driverIds, from, to) => {
    let arr = [];
    for (const id of driverIds) {
      const routes = await createShipmentsByDriverQuery({
        query: {
          driverId: id,
          from,
          to,
        }
      });
      arr = arr.concat(routes.matches);
    }
    return arr;
  }

  const loadAvailableUsers = async (search) => {
    try {
      const { matches } = await createMatchQueryUsers({
        query: {
          match: {
            search,
            warehouseIds: [],
            permissions: ["resource.user.role.permission.driver"]
          },
          "offset": 0,
          "limit": 100,
          "orderBy": []
      }
      });
      // suggestedUsers.current.push(...matches);
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };


  // const fetchActiveTracking = async () => {
  //   const tracking = await client.tracking.getActiveTracking();
  //   setTracking(tracking);

  //   if (hoveredTrackIdRef.current !== -1) {
  //     const hoveredTracking = tracking.find(({ id: trackingId }) => trackingId === hoveredTrackIdRef.current)
  //     const newPlannedPath = hoveredTracking.journey.waypoints.map(({ placeGoogleId }) => placeGoogleId);
  //     setPlannedPath(newPlannedPath);
  //   }

  //   setGeolocationLoading(false);
  // }

  // const fetchData = async () => {
  //   if (hoveredTrackIdRef.current !== -1) {
  //     onTrackingPathChange(hoveredTrackIdRef.current);
  //   }
  //   fetchActiveTracking();
  // }

  // const onTrackingPathChange = async (id) => {
  //   if (id === -1) {
  //     setDriverPath([]);
  //   }
  //   else {
  //     const data = await client.tracking.getTrackingGeopositions({ trackingId: id });
  //     const geolocations = data.map(({ latitude, longitude }) => ({
  //       lat: latitude,
  //       lng: longitude
  //     }));
  //     setDriverPath(geolocations);
  //   }
  // }

  // useEffect(() => {
  //   hoveredTrackIdRef.current = hoveredTrackId;
  // }, [hoveredTrackId]);

  useEffect(() => {
    if (isFetchingActive) {
      fetchTrackingData();
      const interval = setInterval(() => {
        fetchTrackingData();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [isFetchingActive]);

  const fetchTrackingData = async () => {
    const userIds = selectedUsers.map(({ id }) => id);
    const fsData = await getTrackingDataFromFirestore(userIds, new Date(fromDateTime), new Date(toDateTime));
    const drivers = [...new Set(fsData.map((item) => { id: item.userId }))];
    const lines = drivers.map((id) => fsData.filter((data) => data.userId === id));
    const routes = await getRoutes(userIds, fromDateTime, toDateTime);
    console.log(selectedUsers, 'users');
    setDriverIds(selectedUsers);
    setDriverPath(fsData);
    setRoutes(routes);
  }

  return (
    <div className={classes.root}>
      <div>
        <h2>Filtry</h2>
        <TextField
          id="datetime-from"
          label="Od"
          type="datetime-local"
          variant="outlined"
          value={fromDateTime}
          className={classes.textField}
          onChange={(i) => setFromDateTime(i.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="datetime-to"
          label="Do"
          type="datetime-local"
          variant="outlined"
          value={toDateTime}
          onChange={(i) => setToDateTime(i.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          id="today"
          className={classes.field}
          onClick={() => {
            setFromDateTime(format(startOfDay(new Date()), "yyyy-MM-dd HH:mm"));
            setToDateTime(format(endOfDay(new Date()), "yyyy-MM-dd HH:mm"));
          }}>Dnes</Button>
        <Button
          id="today"
          className={classes.field}
          onClick={() => {
            setFromDateTime(format(startOfYesterday(), "yyyy-MM-dd HH:mm"));
            setToDateTime(format(endOfYesterday(), "yyyy-MM-dd HH:mm"));
          }}>Vcera</Button>
        <UserFilterDropdown
          id="drivers"
          className={classes.field}
          onChange={(value) => {
            setSelectedUsers(value.length === 0 ? undefined : value)
          }}
          selectAll={(all) => {
            setSelectedUsers(all);
          }}
          value={selectedUsers ?? []}
          placeholderTitle="Řidič"
          search={(text) => loadAvailableUsers(text)}
          onClear={() => setSelectedUsers(undefined)}
          getTitle={(item) => item.name}
        />
        <Button
          type="button"
          className={classes.field}
          color="primary"
          variant="contained"
          onClick={() => setIsFetchingActive(!isFetchingActive)}
        >
          {isFetchingActive ? 'Zastavit sledovani' : 'Zapnout sledovani'}
        </Button>
      </div>
      {/* <TrackingSideMenu
        geolocationLoading={geolocationLoading}
        expandingCard={expandingCard}
        setExpandingCard={setExpandingCard}
        tracking={tracking}
        hoveredTrackId={hoveredTrackId}
        setHoveredTrackId={setHoveredTrackId}
        onTrackingPathChange={onTrackingPathChange}
      /> */}
      <div style={{
        flex: 1
      }} >
        {!isGoogleMapsApiLoading
          ? (user.companyId === 8 ? (
          <TrackingMap
            googleMapsApi={googleMapsApi}
            driverPath={driverPath}
            driverIds={driverIds}
            routes={routes}
            plannedPath={plannedPath}
            selectedDriverId={hoveredTrackId}
            tracking={tracking} />): <div style={{ display: 'flex' }}>Chybi oprávnění</div>)
          : null}
      </div>
    </div>
  );
}

export default TrackingContainer;