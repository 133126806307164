import React, { forwardRef } from "react";

import { VehicleExpirationType } from "@cargoticcom/model";

import {
  Beenhere as BeenhereIcon,
  Build as BuildIcon,
  DriveEta as DriveEtaIcon,
  LocalHospital as LocalHospitalIcon,
  Money as MoneyIcon,
  Assignment as AssignmentIcon
} from "@material-ui/icons";

const ICONS = {
  [VehicleExpirationType.COMPREHENSIVE_COVER]: BeenhereIcon,
  [VehicleExpirationType.FIRST_AID_KIT]: LocalHospitalIcon,
  [VehicleExpirationType.VEHICLE_INSPECTION]: BuildIcon,
  [VehicleExpirationType.VEHICLE_INSURANCE]: MoneyIcon,
  [VehicleExpirationType.VEHICLE_REGISTRATION_CERTIFICATE]: DriveEtaIcon,
  [VehicleExpirationType.OTHER]: AssignmentIcon
};

const VehicleExpirationIcon = ({ type, ...props }, ref) => {
  const Icon = ICONS[type];

  if (!Icon) {
    throw new Error(`Unknown vehicle expiration type '${type}'.`);
  }

  return (
    <Icon ref={ref} {...props} />
  );
};

export default forwardRef(VehicleExpirationIcon);
