import React, { useCallback } from "react";

import { getIn } from 'formik'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@material-ui/core";

const FormikSelect = ({
  className,
  form,
  name,
  label,
  children,
  handleChange,
  value,
  ...props
}) => {

  const val = value || getIn(form.values, name);
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);

  const onChange = useCallback((...args) => {
    form.handleChange(...args)
    if (typeof handleChange === 'function') {
      handleChange(...args); 
    }
  }, [form.handleChange, handleChange])
  

  return (
    <FormControl error={error} {...props}>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        classes={{
          select: className
        }}
        name={name}
        value={val}
        onChange={onChange}
        onBlur={form.handleBlur}
      >
        {children}
      </Select>
      <FormHelperText>
        {isTouched && error !== undefined ? error : " "}
      </FormHelperText>
    </FormControl>
  );
};

export default FormikSelect;
