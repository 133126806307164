import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ValueError } from "@cargoticcom/common";

import { CircularProgress, makeStyles, Fab, Tooltip, Button } from "@material-ui/core";
import { Delete, Edit, Build, Add, DeleteOutline } from "@material-ui/icons";
import { useFormik } from "formik2";
import * as Yup from "yup";

import DataTable from "../../../datatable";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import { RouterBreadcrumbs } from "../../../../cargotic-webapp-component";
import SettingsRoleEditorNavigation from "./SettingsRoleEditorNavigation";

import SettingsRoleEditorGeneral from "./SettingsRoleEditorGeneral";
import SettingsRoleEditorPermissions from "./SettingsRoleEditorPermissions";

import { readCompanyPermissions, readCompanyRoleById } from "../../../resource";
import RoleEditorView from "./RoleEditorView";
import { Container } from "../../../../cargotic-webapp-component";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    minHeight: "100%",
    flexDirection: "column",

    "& > div": {
      paddingTop: spacing(1),
      paddingBottom: spacing(1)
    }
  },
  content: {
    display: "flex",
    minHeight: "100%",
    flexDirection: "column"
  },
  form: {
    padding: spacing(2)
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },
  },
  progress: {
    alignItems: "center",
    justifyContent: "center"
  }
}));



const SettingsRoleEditor = ({
  isRoleLoading,
  isCompanyPermissionsLoading,
  form,
  breadcrumbs,
  availablePermissions,
  view = RoleEditorView.GENERAL,
  onViewChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const RoleEditorGeneralView = (
    <SettingsRoleEditorGeneral
      classes={classes}
      form={form}
    />
  );

  const RoleEditorPermissionsView = (
    <SettingsRoleEditorPermissions
      form={form}
      permissions={availablePermissions}
    />
  );

  const getContent = (view) => {
    switch (view) {
      case RoleEditorView.GENERAL: {
        return RoleEditorGeneralView;
      }
      case RoleEditorView.PERMISSIONS: {
        return RoleEditorPermissionsView;
      }
      default: {
        throw new ValueError(`Bad role editor view ${view}`);
      }
    }
  }

  if (isRoleLoading || isCompanyPermissionsLoading) {
    return (
      <div className={clsx(classes.root, classes.progress)}>
        <CircularProgress />
      </div>
    );
  }

  const handleSaveForm = () => {
    try {
      form.submitForm();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container size="large" className={classes.root}>
      <div>
        <RouterBreadcrumbs value={breadcrumbs} />
      </div>
      <div>
        <SettingsRoleEditorNavigation
          view={view}
          onViewChange={onViewChange}
        />
      </div>
      <div>
        {getContent(view)}
      </div>
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={handleSaveForm}
          disabled={form.isSubmitting}
        >
          {t("save")}
        </Button>
      </div>
    </Container>
  );
};

export default SettingsRoleEditor;
