import React from "react";

import {
  Typography,
  makeStyles,
  Divider,
  Grid,
} from "@material-ui/core";
import { VehicleType } from "@cargoticcom/model";

import { useTranslation } from "react-i18next";

import {
  useApiClient as useNewApiClient,
  FormikVehicleAutosuggestTextField,
  FormUserAutosuggestTextField,
} from "../../cargotic-webapp-component";

import { formatDateTime } from "../../../multiload/cargotic-common";

import FileDropzone from "../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload from "../../../multiload/cargotic-webapp/component/FileUpload";
import AttachmentPreview from "../../cargotic-webapp-component/component/AttachmentPreview";

const useStyles = makeStyles(({ spacing }) => ({
  tabContentContainer: {
    paddingTop: spacing(1),
  },
  subStatusText: {
    fontSize: 28,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: spacing(3),
    textTransform: "uppercase",
  },
  markPoint: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
  containerMarginBottom: {
    marginBottom: spacing(2),
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  gutterBottom: {
    marginBottom: spacing(2),
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18,
  },
  cardLabel: {
    borderRadius: 10,
    marginRight: 8,
    width: 50,
    height: 8,
  },
  cardLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  infoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contactTable: {
    width: "98%",
  },
  priceRow: {
    "& > :not(:first-child)": {
      paddingLeft: spacing(2),
    },
  },
  waypointAddress: {
    marginLeft: "4px",
  },
  preview: {
    backgroundSize: "contain",
    width: "100%",
    height: "auto"
  }
}));

const CarrierFormComponent = ({
  form,
  hasDocumentFileDropzoneError,
  handleDocumentFileDropzoneError,
  handleDocumentFilesSelect,
  handleDocumentDelete,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <section>
        <Grid container>
          <Grid item xs={12} className={classes.contactTable}>
            <Typography className={classes.subtitle}>
              {t("webapp:shipment.form.subtitle.shipping")}
            </Typography>
            <Grid container className={classes.contactTable}>
              <Grid item xs={12}>
                <FormikVehicleAutosuggestTextField
                  form={form}
                  name="vehicle"
                  type={VehicleType.CAR}
                  label={t("webapp:shipment.form.label.vehicle")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormikVehicleAutosuggestTextField
                  form={form}
                  name="trailer"
                  type={VehicleType.TRAILER}
                  label={t("webapp:shipment.form.label.trailer")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormUserAutosuggestTextField
                  form={form}
                  permissions={["resource.user.role.permission.driver"]}
                  name="driver"
                  label={t("webapp:shipment.form.label.driver")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Divider className={classes.divider} />
      <section>
        <Typography className={classes.subtitle}>
          {t("webapp:shipment.form.subtitle.documents")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FileDropzone
              accept={["application/pdf", "image/jpeg", "image/png"]}
              title={
                hasDocumentFileDropzoneError
                  ? t("webapp:shipment.form.document.error.file.type")
                  : t("webapp:shipment.form.document.title.upload")
              }
              description={[
                t("webapp:shipment.form.document.description.about"),
                t("webapp:shipment.form.document.description.supported"),
                t("webapp:shipment.form.document.description.limit"),
              ]}
              hasError={hasDocumentFileDropzoneError}
              onError={handleDocumentFileDropzoneError}
              onSelect={(files) => handleDocumentFilesSelect(files, form, "cgt-staging-shipment-documents")}
            />
          </Grid>
          {form.values.documents.map(
            ({ uuid, name, url, error, isUploading, createdAt }, index) => (
              <React.Fragment key={uuid}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FileUpload
                    className={index === 0 ? classes.upload : undefined}
                    title={name}
                    description={
                      createdAt ? formatDateTime(createdAt) : undefined
                    }
                    url={url}
                    isUploading={isUploading}
                    error={error}
                    onDelete={() => handleDocumentDelete(uuid, form)}
                  />
                </Grid>
                <AttachmentPreview url={url} name={name}></AttachmentPreview>
              </React.Fragment>
            )
          )}
        </Grid>
      </section>
    </>
  );
};

export default CarrierFormComponent;
