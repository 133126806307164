import DateFnsUtils from "@date-io/date-fns";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  makeStyles
} from "@material-ui/core";

import { teal } from "@material-ui/core/colors";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { I18nextProvider, useTranslation } from "react-i18next";

import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import { cs, enUS } from "date-fns/locale";

import { ExchangeRatesProvider } from "../../cargotic-currency";
import i18n from "../../cargotic-webapp/i18n";
import { ApiClientProvider } from "../../cargotic-webapp-component";
import {
  DummyAuthProvider,
  DummyUserProfileProvider,
  createDummyClient
} from "../../cargotic-webapp-dummy";

import VehicleProfileView from "../VehicleProfileView";
import VehicleExpenseOverviewContainer from "./VehicleExpenseOverviewContainer";

import VehicleOverviewContainer from "./VehicleOverviewContainer";
import VehicleProfileContainer from "./VehicleProfileContainer";
import Button from "@material-ui/core/Button";
import i18next from "i18next";

const client = createDummyClient();
const profile = {
  userProfile: {
    avatarUrl: "https://ei.marketwatch.com/Multimedia/2018/03/20/Photos/ZH/MW-GF839_musk_e_20180320125627_ZH.jpg?uuid=a12ae588-2c5f-11e8-b8ac-ac162d7bc1f7"
  }
};
const auth = {
  user: {
    id: 69.420,
    firstName: "Melon",
    lastName: "Eusk"
  },
  hasPermission: () => false
};

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body, #app": {
      height: "100%"
    }
  }
}));

const App = () => {
  useStyles();

  const [view, setView] = useState(VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW);

  const handleViewChange = setView;

  return (
    <VehicleProfileContainer
      vehicleId={21}
      view={view}
      onViewChange={handleViewChange}
    />
  );
};

const AppContainer = () => {
  const { i18n } = useTranslation();
  const pickerLocale = i18n.language === "cs" || i18n.language === "cs-CZ"
    ? cs
    : enUS;

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <ApiClientProvider client={client}>
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={pickerLocale}>
        <ThemeProvider theme={createTheme({ palette: { primary: teal } })}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <ExchangeRatesProvider baseCurrency="CZK">
                <DummyAuthProvider auth={auth}>
                  <DummyUserProfileProvider profile={profile}>
                    <SnackbarProvider maxSnack={10} ref={notistackRef} action={(key) => (
                      <Button onClick={onClickDismiss(key)}>
                        {i18next.t('snackbar.dismiss')}
                      </Button>
                    )}>
                      <CssBaseline />
                      <App />
                    </SnackbarProvider>
                  </DummyUserProfileProvider>
                </DummyAuthProvider>
              </ExchangeRatesProvider>
            </BrowserRouter>
          </I18nextProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApiClientProvider>
  );
}

export default AppContainer;
