import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { removeIndex } from "@cargoticcom/common";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import {
  useApiClient
} from "../../../cargotic-webapp-component";

import {
  useApiClient as useOldApiClient
} from "@cargoticcom/api-client";

import { generateUuid, formatDateTime }
  from "../../../../multiload/cargotic-common";

import {
  uploadShipmentDocument,
  deleteShipmentDocument
} from "@cargoticcom/api-client";

import FileDropzone
  from "../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload
  from "../../../../multiload/cargotic-webapp/component/FileUpload";

const useStyles = makeStyles(({ palette, spacing }) => ({
  error: {
    color: palette.error.main
  },
  primary: {
    color: palette.primary.dark
  },
  upload: {
    marginTop: spacing(2)
  }
}));

const VehicleExpirationFileDropzoneGroup = ({
  className,
  form,
  name,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const client = useApiClient();
  const oldClient = useOldApiClient();

  const values = form.values[name];
  const error = form.errors[name];
  const hasError = error !== undefined;

  const [
    hasFileDropzoneError,
    setHasFileDropzoneError
  ] = useState(false);


  const handleFileDropzoneError = () =>
    setHasFileDropzoneError(true);


  
    const handleFilesSelect = async files => {
      setHasFileDropzoneError(false);

      const newFiles = [...form.values.files];

      for (const file of files) {
        const uuid = generateUuid();

        try {
          const { url, createdAt, id, name } = await client.vehicle.postVehicleExpirationImage({
            imageFile: file
          });

          newFiles.push({
            uuid,
            id,
            name,
            url
          })
        } catch (error) {
          console.log(error);
  
          let message = t("webapp:vehicle.expiration.file.error.general");
  
          if (error?.response?.data?.error === "FileTooLargeError") {
            message = t("webapp:vehicle.expiration.file.error.size");
          }

          enqueueSnackbar(message, {
            variant: "error"
          });
    
        }
      }

      form.setValues({
        ...form.values,
        files: newFiles
      })
    };


  const handleFileDelete = async (uuid) => {
    form.setValues({
      ...form.values,
      files: form.values.files.filter(({ uuid: other }) => other !== uuid)
    })
  };
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <FileDropzone
          accept={["application/pdf", "image/jpeg", "image/png", "text/csv"]}
          title={
            hasFileDropzoneError
              ? t("webapp:vehicle.expiration.file.error.fileType")
              : t("webapp:vehicle.title.expiration-file-upload")
          }
          description={[
            t("webapp:vehicle.expiration.file.about"),
            t("webapp:vehicle.expiration.file.supported"),
            t("webapp:vehicle.expiration.file.limit")
          ]}
          hasError={hasFileDropzoneError}
          onError={handleFileDropzoneError}
          onSelect={handleFilesSelect}
        />
      </Grid>
      {
        form.values.files.map(({ uuid, name, url, error }, index) => (
          <React.Fragment key={uuid}>
            <Grid item xs={12}>
              <FileUpload
                title={name}
                className={index === 0 ? classes.upload : undefined}
                url={url}
                error={error}
                onDelete={() => handleFileDelete(uuid)}
              />
            </Grid>
          </React.Fragment>
        ))
      }
   </Grid>
  );
};

export default VehicleExpirationFileDropzoneGroup;