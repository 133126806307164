import React, {
  useEffect, useMemo, useRef, useState
} from "react";
import { cs, enUS } from "date-fns/locale";
import { formatDistance } from "date-fns";

import { useTranslation } from "react-i18next";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import {
  deserializeFloat, deserializeInteger, EditableTypography, identity, pipe, ValueError
} from "@cargotic/common-deprecated";

import { ShipmentState } from "@cargoticcom/model";

import { Currency } from "@cargotic/currency";
import {
  allowEmptyString, isObject, required, validateDateString
} from "@cargotic/validate";
import {
  Button, Grid, makeStyles, Paper, Typography
} from "@material-ui/core";
import RelatedInfo from "../RelatedInfo";

import ShipmentSummary from "../ShipmentSummary";
import { createFormValidator, FormValidationError, useForm } from "../../../form";

import { generateUuid } from "../../../../../multiload/cargotic-common";
import IncomingOrderFormContent from "../IncomingOrderFormContent";

const SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH = 15;

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2)
  },
  separator: {
    flexGrow: 1
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& > div > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > div > button": {
      minWidth: spacing(14)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    display: "flex"
  },
  selectState: {
    marginRight: spacing(1),
    minWidth: 100
  },
  button: {
    background: "#29AEA0",
    color: "#FFF",
    borderRadius: 200,
    width: "100%",
    marginBottom: 31,
    fontSize: 16,
    textTransform: "uppercase",
    justifyContent: "flex-start",
    padding: spacing(2),
    paddingLeft: 30
  },
  btnText: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    paddingRight: 20
  },
  createOrder: {
    marginTop: 73
  },
  back: {
    width: 162,
    height: 36,
    marginTop: 13
  },
}));

const IncomingOrderForm = ({
  className,
  apiClient,
  exchangeRates,
  journey,
  incomingOrder: {
    indexNumber,
    isDraft,
    state: initialState,

    customerContact: initialCustomerContact = "",
    customerEmployee: initialCustomerEmployee = "",
    externalReference: initialExternalReference,
    customerPrice: initialCustomerPrice = "",
    customerPriceCurrency: initialCustomerPriceCurrency = Currency.CZK,
    customerPaymentDueDays: initialCustomerPaymentDueDays = "",
    isCustomerPriceWithDph: initialIsCustomerPriceWithDph,
    notes: initialNotes,
    documents: initialDocuments,
    services: initialServices,
    ...incomingOrder
  },
  isUpdating,
  onBack,
  onFormChange,
  onSave
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;
  const isMounted = useRef(false);
  const [state, setState] = useState(initialState || ShipmentState.QUEUE);
  const [stateChange, setStateChange] = useState(false);

  const [shipmentNumber, setShipmentNumber] = useState(indexNumber);

  const scrollOnError = (errors) => {
    if (errors && Object.keys(errors).length > 0) {
      const elements = document.getElementsByName(Object.keys(errors)[0]);
      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const validateDeleted = (x, message = "") => {
    if (typeof x === "object" && x?.isDeleted) {
      throw new ValueError(message);
    }

    return x;
  };

  const validateContact = pipe(
    (contact) => required(contact, t("webapp:incomingOrder.form.error.contact")),
    (contact) => isObject(contact, t("webapp:incomingOrder.form.error.contact")),
    (contact) => validateDeleted(contact, t("webapp:incomingOrder.form.error.deletedContact"))
  );

  const validateDraftContact = pipe(
    allowEmptyString((contact) => isObject(contact, t("webapp:incomingOrder.form.error.contact"))),
    (contact) => validateDeleted(contact, t("webapp:incomingOrder.form.error.deletedContact"))
  );

  const validateEmployee = pipe(
    allowEmptyString((employee) => isObject(employee, t("webapp:incomingOrder.form.error.employee"))),
    (employee) => validateDeleted(employee, t("webapp:incomingOrder.form.error.deletedEmployee"))
  );

  const isString = x => typeof x === "string";
  const validateDraftPaymentDueDays = allowEmptyString(
    (x) => deserializeInteger(x, 10, t("webapp:incomingOrder.form.error.paymentDueDays"))
  );

  const validateParsedDate = allowEmptyString(
    (x) => validateDateString(x, t("webapp:incomingOrder.form.error.date"))
  );

  const validateDraftPrice = allowEmptyString(
    (x) => deserializeFloat(x, t("webapp:incomingOrder.form.error.price"))
  );

  const validateCustomerPaymentDueDays = pipe(
    (x) => required(x, t("webapp:incomingOrder.form.error.paymentDueDays")),
    (x) => deserializeInteger(x, 10, t("webapp:incomingOrder.form.error.paymentDueDays"))
  );

  const validatePrice = pipe(
    (x) => required(x, t("webapp:incomingOrder.form.error.price")),
    (x) => deserializeFloat(x, t("webapp:incomingOrder.form.error.price"))
  );

  const validateOptionalString = allowEmptyString(identity);

  const validateSubmitDraft = ({ values }) => {
    let errors = {};

    // validate shared values for both forms
    try {
      validateDraftContact(values.customerContact);
    } catch (err) {
      errors = { ...errors, customerContact: err };
    }
    try {
      validateEmployee(values.customerEmployee);
    } catch (err) {
      errors = { ...errors, customerEmployee: err };
    }
    try {
      validateDraftPaymentDueDays(values.customerPaymentDueDays);
    } catch (err) {
      errors = { ...errors, customerPaymentDueDays: err };
    }
    try {
      validateDraftPrice(values.customerPrice);
    } catch (err) {
      errors = { ...errors, customerPrice: err };
    }

    scrollOnError(errors);
    return errors;
  };

  const onSubmitDraft = (form) => {
    const { values } = form;
    const errs = validateSubmitDraft(form);

    if (Object.keys(errs).length !== 0) {
      form.setErrors(errs);
      form.touchAll();
      return;
    }
    form.setIsSubmitting(true);

    onSave({
      ...incomingOrder,
      ...values,
      customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
      customerPaymentDueDays: values.customerPaymentDueDays === "" ? undefined : values.customerPaymentDueDays,
      isDraft: true,
      state: ShipmentState.QUEUE,
      indexNumber: undefined,
      documents: values.documents
        .filter(({ id }) => id)
        .map(({ id }) => ({ id }))
    }, form.setIsSubmitting);
  };

  const [redirectType, setRedirectType] = useState(null);

  const form = useForm({
    initialValues: {
      customerContact: initialCustomerContact,
      customerEmployee: initialCustomerEmployee,
      externalReference: initialExternalReference,
      customerPrice: initialCustomerPrice,
      customerPriceCurrency: initialCustomerPriceCurrency,
      customerPaymentDueDays: initialCustomerPaymentDueDays,
      isCustomerPriceWithDph: initialIsCustomerPriceWithDph,
      services: initialServices,
      notes: initialNotes,
      documents: initialDocuments
        .map((document) => ({ ...document, uuid: generateUuid() }))
    },
    validate: createFormValidator({
      customerContact: validateContact,
      customerEmployee: validateEmployee,
      customerPaymentDueDays: validateDraftPaymentDueDays,
      notes: validateOptionalString
    }),
    onChange: (incomingOrder, oldShipment) => {
      const {
        customerPrice,
        customerContact: newCustomerContact,
        currency: newCurrency
      } = incomingOrder;
      const {
        customerContact: oldCustomerContact
      } = oldShipment;

      // if (newCustomerContact !== oldCustomerContact) {
      // form.setValue("customerContact", newCustomerContact);
      // }
    },
    onSubmit: (values) => {
      onSave({
        ...incomingOrder,
        ...values,
        customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
        indexNumber: shipmentNumber,
        isDraft: false,
        state,
        documents: values.documents
          .filter(({ id }) => id)
          .map(({ id }) => ({ id }))
      }, form.setIsSubmitting);
    }
  });

  useEffect(() => {
    const customerPrice = form.values.services.reduce((a, i) => a + (
      !isNaN(parseInt(i.price, 10)) ? parseInt(i.price, 10) : 0
    ), 0);

    form.setValue("customerPrice", customerPrice)
  }, [form.values.services]);

  const price = useMemo(() => {
    const numericPrice = parseInt(form.values.customerPrice, 10);
    return !isNaN(numericPrice)
      ? `${numericPrice.toFixed(2)} ${form.values.customerPriceCurrency}`
      : t("webapp:incomingOrder.summary.metric.unknown");
  }, [form.values.customerPrice, form.values.customerPriceCurrency]);

  const handleShipmentNumberChange = ({ target: { value } }) => setShipmentNumber(value);

  const handleSubmit = (event) => {
    const errors = form.handleSubmit(event);
    scrollOnError(errors);
  };

  const handleState = ({ target: { value } }) => {
    setState(value);
    setStateChange(true);
  };

  const onSaveDraft = (event) => {
    event.preventDefault();
    form.touchAll();

    try {
      form.setIsSubmitting(true);

      form.setErrors({});

      onSubmitDraft(form);
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }
      form.setErrors(error.errors);
      form.setIsSubmitting(false);
    }
  };

  const submitFormRedirect = (event, redirectTo) => {
    event.preventDefault();
    form.touchAll();

    try {
      const values = form.validate(form.values);
      form.setIsSubmitting(true);

      form.setErrors({});

      onSave(
        {
          ...incomingOrder,
          ...values,
          customerPrice: values.customerPrice === "" ? undefined : values.customerPrice,
          indexNumber: shipmentNumber,
          isDraft: false,
          state,
          documents: values.documents
            .filter(({ id }) => id)
            .map(({ id }) => ({ id }))
        },
        form.setIsSubmitting,
        redirectTo
      );
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }
      form.setErrors(error.errors);
      form.setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (stateChange) {
      return;
    }
    const { errors } = form;
    if (Object.keys(errors).length === 0) {
      if (!isUpdating) {
        setState(ShipmentState.READY);
      } else {
        setState(initialState || ShipmentState.READY);
      }
    } else {
      setState(ShipmentState.QUEUE);
    }
  }, [form.errors]);

  useEffect(() => {
    onFormChange(form.values);
  }, [form.values]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.setValues({
      ...form.values,
      orderSerialNumber: shipmentNumber
    });
  }, [shipmentNumber]);

  console.log(incomingOrder, indexNumber, 'INDEX');

  useEffect(() => {
    // console.log(indexNumber, 'INDEX');
    setShipmentNumber(indexNumber);
  }, [indexNumber]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid className={classes.header} item xs={8}>
          <section className={classes.title}>
            <Typography variant="h4">
              {`${t("settings.customSequencing.sectionLabels.INCOMING_ORDER")} #`}
            </Typography>
            <EditableTypography
              variant="h4"
              maxLength={SHIPMENT_INDEX_NUMBER_SHIPMENT_LENGTH}
              value={shipmentNumber}
              isDisabled={isUpdating}
              onChange={handleShipmentNumberChange}
              dataCy="shipment-id"
            />
          </section>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Paper className={classes.content}>
            <IncomingOrderFormContent
              form={form}
              onDocumentsChange={() => onFormChange(form.values)}
              apiClient={apiClient}
              initialCustomerPaymentDueDays={initialCustomerPaymentDueDays}
            />
          </Paper>
          <Grid container>
            <Button variant="contained" color="primary" onClick={onBack} className={classes.back}>
              {t("webapp:incomingOrder.form.button.back")}
            </Button>

            <div className={classes.separator} />

            {isDraft && (
              <Button variant="contained" color="primary" onClick={onSaveDraft} className={classes.back}>
                {t("webapp:incomingOrder.form.button.draft")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ShipmentSummary
            price={price}
            distance={journey && journey.distance ? `${(journey.distance / 1000).toFixed(2)} km` : t("webapp:incomingOrder.summary.metric.unknown")}
            duration={journey && journey.duration ? formatDistance(new Date(journey.duration * 1000), new Date(0), { locale }) : t("webapp:incomingOrder.summary.metric.unknown")}
          />

          <RelatedInfo incomingOrder={incomingOrder} />

          {isUpdating && !isDraft ? (
            <Button
              variant="contained"
              className={`${classes.button}`}
              color="primary"
              type="submit"
              data-cy="complete-shipment"
              disabled={form.isSubmitting}
            >
              <SaveAltIcon />
              <span className={classes.btnText}>{t("save")}</span>
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<LocalShippingIcon />}
                onClick={(event) => submitFormRedirect(event, "shipment")}
                disabled={form.isSubmitting}
              >
                <span className={classes.btnText}>{t("incomingOrders.carriedTo")}</span>
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AssignmentIcon />}
                onClick={(event) => submitFormRedirect(event, "outcoming-order")}
                disabled={form.isSubmitting}
              >
                <span className={classes.btnText}>{t("incomingOrders.forwardedTo")}</span>
              </Button>

              <Button
                variant="contained"
                className={`${classes.button} ${classes.createOrder}`}
                color="primary"
                type="submit"
                data-cy="complete-shipment"
                disabled={form.isSubmitting}
              >
                <SaveAltIcon />
                <span className={classes.btnText}>{t("incomingOrders.createOrder")}</span>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default IncomingOrderForm;
