import { useFormik } from "formik2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Currency } from "@cargoticcom/common";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from "@material-ui/icons";

import { DrawerDialog } from "../../../cargotic-webapp-component";
import { localizeMonth } from "../../../cargotic-webapp-utility";
import MonthlyVehicleExpenseEditorExpenseAddDialog
  from "./MonthlyVehicleExpenseEditorExpenseAddDialog";

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    },

    "& > button:first-child": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    }
  },

  property: {
    display: "flex",

    "& > :not(:first-child)": {
      paddingTop: spacing(2),
      paddingLeft: spacing(2)
    }
  },

  addButton: {
    display: "flex",
    justifyContent: "flex-end"
  }

}));

const MonthlyVehicleExpenseEditor = ({
  month,
  year,
  initialValue,
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t, i18n: { language } } = useTranslation();
  const classes = useStyles();
  const [expenseType, setExpenseType] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [isExpenseAddDialogOpen, setIsExpenseAddDialogOpen] = useState(false);

  const validate = values => Object.fromEntries(
    Object.entries(values)
      .filter(([_, { value }]) => {
        if (value === "") {
          return false;
        }

        const number = parseFloat(value);

        if (Number.isNaN(number)) {
          return true;
        }

        const [a, b] = number.toString().split(".");

        if (a.length > 9) {
          return true;
        }

        if (b) {
          return b.length > 4;
        }

        return false;
      })
      .map(([label, _]) => [label, t("webapp:vehicle.validate.expense")])
  );

  const variableForm = useFormik({
    validate,
    initialValues: Object.fromEntries(
      initialValue.variable.map(({ label, value, currency }) => [
          label,
          {
            value: value ?? "",
            currency
          }
        ]
      )
    ),
    onSubmit: () => setActiveStep(1)
  });

  const fixedForm = useFormik({
    validate,
    initialValues: Object.fromEntries(
      initialValue.fixed.map(({ label, value, currency }) => [
          label,
          {
            value: value ?? "",
            currency
          }
        ]
      )
    ),
    onSubmit: () => {
      const result = {
        fixed: Object.entries(fixedForm.values)
          .map(([label, { value, currency }]) => {
            if (value === "") {
              return { label, value: null, currency };
            }

            return { label, value: parseFloat(value), currency };
          }),
        variable: Object.entries(variableForm.values)
          .map(([label, { value, currency }]) => {
            if (value === "") {
              return { label, value: null, currency };
            }

            return { label, value: parseFloat(value), currency };
          })
      };

      onSubmit(result);
    }
  });

  const handleNextButtonClick = async () => {
    if (activeStep === 0) {
      try {
        await variableForm.submitForm();

        return;
      } catch (error) {
        console.log(error);

        return;
      }
    }

    if (activeStep === 1) {
      try {
        await fixedForm.submitForm();

        return;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePreviousButtonClick = () => {
    if (activeStep === 0) {
      onClose();

      return;
    }

    setActiveStep(activeStep - 1);
  };

  const handleExpenseAddButtonClick = expenseType => {
    setExpenseType(expenseType);
    setIsExpenseAddDialogOpen(true);
  };

  const handleExpenseAddDialogClose = () => setIsExpenseAddDialogOpen(false);

  const handleExpenseAddDialogSubmit = label => {
    if (expenseType === "fixed") {
      fixedForm.setValues({ ...fixedForm.values, [label]: { value: "", currency: Currency.CZK } });
    } else {
      variableForm.setValues({ ...variableForm.values, [label]: { value: "", currency: Currency.CZK } });
    }

    setIsExpenseAddDialogOpen(false);
  };

  const handleRemoveExpenseButtonClick = (form, label) => {
    form.setValues(
      Object.fromEntries(
        Object.entries(form.values)
          .filter(([other]) => other !== label)
      )
    );

    form.setErrors(
      Object.fromEntries(
        Object.entries(form.errors)
          .filter(([other]) => other !== label)
      )
    );

    form.setTouched(
      Object.fromEntries(
        Object.entries(form.touched)
          .filter(([other]) => other !== label)
      )
    );
  };

  const steps = [
    [
      t("webapp:vehicle.title.variableExpenses"),
      <div key={0}>
        {Object.entries(variableForm.values).map(([ label, { value, currency } ]) => (
          <div key={label} className={classes.property}>
            <TextField
              name={`${label}.value`}
              label={label}
              value={value}
              type="number"
              fullWidth
              helperText={
                variableForm.touched[label] && variableForm.errors[label]
                  ? variableForm.errors[label]
                  : " "
              }
              error={
                variableForm.touched[label]
                && variableForm.errors[label] !== undefined
              }
              onBlur={variableForm.handleBlur}
              onChange={variableForm.handleChange}
            />
            <div>
              <Select
                required
                name={`${label}.currency`}
                label="mena"
                value={currency}
                onChange={variableForm.handleChange}
                onBlur={variableForm.handleBlur}
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div>
              <Tooltip title={t("webapp:vehicle.title.removeExpense")}>
                <IconButton
                  size="small"
                  onClick={() => (
                    handleRemoveExpenseButtonClick(variableForm, label)
                  )}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))}
        <div className={classes.addButton}>
          <Tooltip title={t("webapp:vehicle.title.addExpense")}>
            <IconButton
              size="small"
              onClick={() => handleExpenseAddButtonClick("variable")}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    ],
    [
      t("webapp:vehicle.title.fixedExpenses"),
      <div key={1}>
        {Object.entries(fixedForm.values).map(([label, { value, currency }]) => (
          <div key={label} className={classes.property}>
            <TextField
              inputProps={{ maxLength: 14 }}
              name={`${label}.value`}
              label={label}
              value={value}
              type="number"
              fullWidth
              helperText={
                fixedForm.touched[label] && fixedForm.errors[label]
                  ? fixedForm.errors[label]
                  : " "
              }
              error={
                fixedForm.touched[label]
                && fixedForm.errors[label] !== undefined
              }
              onBlur={fixedForm.handleBlur}
              onChange={fixedForm.handleChange}
            />
            <div>
              <Select
                required
                name={`${label}.currency`}
                label="mena"
                value={currency}
                onChange={fixedForm.handleChange}
                onBlur={fixedForm.handleBlur}
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div>
              <Tooltip title={t("webapp:vehicle.title.removeExpense")}>
                <IconButton
                  size="small"
                  onClick={() => (
                    handleRemoveExpenseButtonClick(fixedForm, label)
                  )}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))}
        <div className={classes.addButton}>
          <Tooltip title={t("webapp:vehicle.title.addExpense")}>
            <IconButton
              size="small"
              onClick={() => handleExpenseAddButtonClick("fixed")}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    ]
  ];

  useEffect(() => {
    const { fixed, variable } = initialValue;

    variableForm.resetForm({
      errors: {},
      values: Object.fromEntries(
        initialValue.variable.map(({ label, value, currency }) => [
            label,
            {
              value: value ?? "",
              currency
            }
          ]
        )
      )
    });

    fixedForm.resetForm({
      errors: {},
      values: Object.fromEntries(
        initialValue.fixed.map(({ label, value, currency }) => [
            label,
            {
              value: value ?? "",
              currency
            }
          ]
        )
      )
    });

    setActiveStep(0);
  }, [isOpen]);

  return (
    <>
      <DrawerDialog
        title={`${localizeMonth(month, language)} ${year}`}
        actions={(
          <div className={classes.actions}>
            <Button variant="contained" onClick={handlePreviousButtonClick}>
              {
                activeStep === 0
                  ? t("webapp:common.title.cancel")
                  : t("webapp:common.title.back")
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextButtonClick}
            >
              {
                activeStep === 2
                  ? t("webapp:common.title.complete")
                  : t("webapp:common.title.continue")
              }
            </Button>
          </div>
        )}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(([label, content], step) => (
            <Step key={label}>
              <StepButton onClick={() => setActiveStep(step)}>
                {label}
              </StepButton>
              <StepContent>
                {content}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DrawerDialog>
      <MonthlyVehicleExpenseEditorExpenseAddDialog
        expenses={initialValue}
        expenseType={expenseType}
        isOpen={isExpenseAddDialogOpen}
        onClose={handleExpenseAddDialogClose}
        onSubmit={handleExpenseAddDialogSubmit}
      />
    </>
  );
};

export default MonthlyVehicleExpenseEditor;
