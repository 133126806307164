import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  Typography,
  makeStyles,
  Grid,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import { formatDate } from "@cargotic/common-deprecated/date";
import { ShipmentType, ShipmentState } from "@cargoticcom/model";
import { useApiClient } from "@cargoticcom/api-client";
import {
  mapAndFormatWaypoints,
  getActionTypeOfLoading,
  getDrivenThroughAtText,
  getDrivenThroughText,
} from "../../../cargotic-webapp/utility/waypoint";
import WaypointType from "../../../cargotic-webapp/utility/WaypointType";
import client from "../../../cargotic-webapp/client";
import CargoItemList from "../../../../multiload/cargotic-component/component/CargoItemList";
import { useDialog } from "@cargotic/webapp-component/hook";
import { useApiClient as useNewApiClient } from "../../../cargotic-webapp-component";
import { CargoItemAction } from "../../../../multiload/cargotic-core";
import {
  postIncomingOrderWaypointDriveThrough,
  deleteIncomingOrderWaypointDriveThrough,
  postIncomingOrderNotDelivered,
  putIncomingOrderNotDelivered,
  deleteIncomingOrderNotDelivered,
  postShipmentWaypointDriveThrough
} from "@cargotic/webapp/resource";

import UpdateDriveThroughDialog from "../../../cargotic-webapp/component/core/ShipmentBoard/component/UpdateDriveThroughDialog";

import DriveThroughDialog from "./DriveThroughDialog";
import Waypoint from "./Waypoint";
import NotDeliveredDialog from "../../../cargotic-webapp/component/core/IncomingOrders/NotDeliveredDialog";

const OUTPUT_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

const useStyles = makeStyles(({ spacing }) => ({
  isDrivenThrough: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDrivenThroughIcon: {
    marginLeft: 5,
    marginRight: 15,
    width: 18,
    height: 18,
  },
  waypointAddress: {
    marginLeft: "4px",
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const getWaypointAction = (waypoint) => {
  const { cargo } = waypoint;
  if (cargo.every((c) => c.action === CargoItemAction.LOAD)) {
    return CargoItemAction.LOAD;
  }

  if (cargo.every((c) => c.action === CargoItemAction.UNLOAD)) {
    return CargoItemAction.UNLOAD;
  }

  return CargoItemAction.IDLE;
};

export function WaypointsSection({
  entityType,
  waypoints,
  data,
  fetchActivity,

  isDriveThroughLoading,
  setIsDriveThroughLoading,

  updateShipmentsDriveThrough,
  errorAction,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [
    isSetDrivenThroughDialogOpen,
    handleOpenSetDrivenThroughDialog,
    handleCloseSetDrivenThroughDialog,
    selectedSetWaypoint,
  ] = useDialog();

  const [
    isUpdateDrivenThroughDialogOpen,
    handleOpenUpdateDrivenThroughDialog,
    handleCloseUpdateDrivenThroughDialog,
    selectedUpdateWaypoint,
  ] = useDialog();

  // const [
  //   isUpdateDrivenThroughTimeDialogOpen,
  //   handleOpenUpdateDrivenThroughTimeDialog,
  //   handleCloseUpdateDrivenThroughTimeDialog,
  //   selectedUpdateTimeWaypoint,
  // ] = useDialog();

  const [
    isSetNotDeliveredDialogOpen,
    handleOpenSetNotDeliveredDialog,
    handleCloseSetNotDeliveredDialog,
    selectedSetNotDeliveredWaypoint,
  ] = useDialog();

  const [
    isUpdateNotDeliveredDialogOpen,
    handleOpenUpdateNotDeliveredDialog,
    handleCloseUpdateNotDeliveredDialog,
    selectedUpdateNotDeliveredWaypoint,
  ] = useDialog();

  const handleChangeDriveThrough = async (waypoint, values) => {
    const waypointData =
      waypoints[waypoints.findIndex((wp) => waypoint.id === wp.id)];

    setIsDriveThroughLoading(true);

    if (entityType === 'SHIPMENT') {
      try {
        const packages = values.packagesIds.map((id) => ({ packageId: id, action: getWaypointAction(waypointData) }));
        console.log(values, getWaypointAction(waypointData), packages);

        const result = await postShipmentWaypointDriveThrough(
          data.id,
          waypointData.id,
          packages
        );
        console.log(result, 'result');
        // const result = await client.shipment.postShipmentWaypointDriveThrough({
        //   // new-shipment/shipmentId/waypoints/waypointsId
        //   shipmentId: data.id,
        //   waypointId: waypointData.id,
        // });
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t("shipment.update.error.general"), {
          variant: "error",
        });
      }
    }
    try {
      await postIncomingOrderWaypointDriveThrough(
        waypointData.id,
        getWaypointAction(waypointData),
        values.packagesIds
      );

      updateShipmentsDriveThrough(
        {
          ...waypointData,
          cargo: waypointData.cargo.map((cargo) => ({
            ...cargo,
            packages: cargo.packages.map((cargoPackage) => ({
              ...cargoPackage,
              isDrivenThrough:
                values.packagesIds?.includes(
                  cargoPackage.packageId || cargoPackage.cargoItemPackageId
                ) || cargoPackage.isDrivenThrough,
            })),
          })),
          isDrivenThrough: true,
          drivenThroughAt: new Date(),
        },
        errorAction.SUB
      );
      fetchActivity(data.id);
      handleCloseSetDrivenThroughDialog();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t("shipment.update.error.general"), {
        variant: "error",
      });
    }

    setIsDriveThroughLoading(false);
  };

  const handleUpdateNotDeliveredSubmit = async (reason) => {
    try {
      console.log('call update not delivered', reason);
      const waypointData =
        waypoints[
        waypoints.findIndex(
          (wp) => selectedUpdateNotDeliveredWaypoint.id === wp.id
        )
        ];
      console.log(waypointData);

      if (entityType === 'SHIPMENT') {
        await client.shipment.putShipmentWaypointDriveThrough({
          shipmentId: data.id,
          waypointId: waypointData.id,
          drivenThroughAt: formatDate(
            new Date(),
            OUTPUT_DATE_FORMAT
          ),
        });

        const orderIds = selectedUpdateNotDeliveredWaypoint.cargo.map(
          (item) => item.incomingOrder.incomingOrderId
        );
        await Promise.all(
          orderIds.map((id) => putIncomingOrderNotDelivered(id, data.id, reason, formatDate(
            new Date(),
            OUTPUT_DATE_FORMAT
          ),))
        );
        updateShipmentsDriveThrough(
          {
            ...waypointData,
            cargo: waypointData.cargo.map((cargoItem) => ({
              ...cargoItem,
              incomingOrder: {
                ...cargoItem.incomingOrder,
                reasonNotDelivered: reason,
              },
            })),
            isDrivenThrough: true,
            drivenThroughAt: new Date(),
          },
          errorAction.NOP
        );
      }

      if (entityType === 'INCOMING_ORDER') {
        await putIncomingOrderNotDelivered(data.id, undefined, reason, formatDate(
          new Date(),
          OUTPUT_DATE_FORMAT
        ));
        updateShipmentsDriveThrough(
          {
            ...waypointData,
            isDrivenThrough: true,
            drivenThroughAt: new Date(),
            reasonNotDelivered: reason,
          },
          errorAction.NOP
        );
      }

    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("shipment.update.error.general"), {
        variant: "error",
      });
    } finally {
      handleCloseUpdateNotDeliveredDialog();
    }
  }

  const handleNotDeliveredSubmit = async (reason) => {
    try {
      const waypointData =
        waypoints[
          waypoints.findIndex(
            (wp) => selectedSetNotDeliveredWaypoint.id === wp.id
          )
        ];

      if (entityType === 'SHIPMENT') {
        await client.shipment.postShipmentWaypointDriveThrough({
          shipmentId: data.id,
          waypointId: selectedSetNotDeliveredWaypoint.id,
        });
        const orderIds = selectedSetNotDeliveredWaypoint.cargo.map(
          (item) => item.incomingOrder.incomingOrderId
        );
        await Promise.all(
          orderIds.map((id) => postIncomingOrderNotDelivered(id, data.id, reason))
        );
        updateShipmentsDriveThrough(
          {
            ...waypointData,
            cargo: waypointData.cargo.map((cargoItem) => ({
              ...cargoItem,
              incomingOrder: {
                ...cargoItem.incomingOrder,
                reasonNotDelivered: reason,
              },
            })),
            isDrivenThrough: true,
            drivenThroughAt: new Date(),
          },
          errorAction.ADD
        );
      }

      if (entityType === 'INCOMING_ORDER') {
        await postIncomingOrderNotDelivered(data.id, undefined, reason);
        updateShipmentsDriveThrough(
          {
            ...waypointData,
            isDrivenThrough: true,
            drivenThroughAt: new Date(),
          },
          errorAction.ADD
        );
      }

    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("shipment.update.error.general"), {
        variant: "error",
      });
    } finally {
      handleCloseSetNotDeliveredDialog();
    }
  };

  const handleUpdateWaypointDriveThrough = useCallback(
    async (waypoint, values) => {
      const waypointData =
        waypoints[waypoints.findIndex((wp) => waypoint.id === wp.id)];
      setIsDriveThroughLoading(true);
      console.log(entityType)

      if (entityType === 'SHIPMENT') {
        try {
          await client.shipment.putShipmentWaypointDriveThrough({
            shipmentId: data.id,
            waypointId: waypointData.id,
            drivenThroughAt: formatDate(
              values.drivenThroughAt,
              OUTPUT_DATE_FORMAT
            ),
          });
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t("shipment.update.error.general"), {
            variant: "error",
          });
        }
      }

      try {
        await postIncomingOrderWaypointDriveThrough(
          waypointData.id,
          getWaypointAction(waypointData),
          values.packagesIds,
          formatDate(values.drivenThroughAt, OUTPUT_DATE_FORMAT)
        );

        updateShipmentsDriveThrough(
          {
            ...waypointData,
            cargo: waypointData.cargo.map((cargoItem) => ({
              ...cargoItem,
              packages: cargoItem.packages.map((pckg) => ({
                ...pckg,
                isDrivenThrough: values.packagesIds?.includes(
                  pckg.packageId || pckg.cargoItemPackageId
                ),
              })),
            })),
          },
          errorAction.NOP
        );
        fetchActivity(data?.id);
        handleCloseUpdateDrivenThroughDialog();
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t("shipment.update.error.general"), {
          variant: "error",
        });
      }

      setIsDriveThroughLoading(false);
    },
    [
      data,
      handleCloseUpdateDrivenThroughDialog,
      updateShipmentsDriveThrough,
      setIsDriveThroughLoading,
      fetchActivity,
      postIncomingOrderWaypointDriveThrough,
      client.shipment.putShipmentWaypointDriveThrough,
    ]
  );

  const handleDeleteWaypointDriveThrough = useCallback(
    async (waypoint) => {

      const waypointData =
        waypoints[waypoints.findIndex((wp) => waypoint.id === wp.id)];
      const cargoPackagesIds = waypointData.cargo.reduce(
        (acc, cargoItem) => [
          ...acc,
          ...cargoItem.packages.map((pckg) =>
            pckg.isDrivenThrough
              ? pckg.packageId || pckg.cargoItemPackageId
              : undefined
          ),
        ],
        []
      );

      setIsDriveThroughLoading(true);

      if (entityType === 'SHIPMENT') {
        try {
          const notDeliveredOrderIds = waypointData.cargo
            .filter(
              ({ incomingOrder: { reasonNotDelivered } }) =>
                reasonNotDelivered != null
            )
            .map(({ incomingOrder: { incomingOrderId } }) => incomingOrderId);
          await Promise.all(
            notDeliveredOrderIds.map((id) =>
              deleteIncomingOrderNotDelivered(id)
            )
          );
          await client.shipment.deleteShipmentWaypointDriveThrough({
            shipmentId: data.id,
            waypointId: waypoint.id,
          });
          await deleteIncomingOrderWaypointDriveThrough(waypoint.id);

          updateShipmentsDriveThrough(
            {
              ...waypointData,
              cargo: waypointData.cargo.map((cargoItem) => ({
                ...cargoItem,
                incomingOrder: {
                  ...cargoItem.incomingOrder,
                  reasonNotDelivered: undefined,
                },
                packages: cargoItem.packages.map((pckg) => ({
                  ...pckg,
                  isDrivenThrough: cargoPackagesIds.includes(
                    pckg.packageId || pckg.cargoItemPackageId
                  )
                    ? false
                    : pckg.isDrivenThrough,
                })),
              })),
              isDrivenThrough: false,
              drivenThroughAt: undefined,
            },
            errorAction.NOP
          );
          fetchActivity(data?.id);
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t("shipment.update.error.general"), {
            variant: "error",
          });
        }
      }
      if (entityType === 'INCOMING_ORDER') {
        try {
          await deleteIncomingOrderNotDelivered(data.id);
          await deleteIncomingOrderWaypointDriveThrough(waypoint.id);

          updateShipmentsDriveThrough(
            {
              ...waypointData,
              isDrivenThrough: false,
              drivenThroughAt: undefined,
              cargo: waypointData.cargo.map((cargoItem) => ({
                ...cargoItem,
                packages: cargoItem.packages.map((pckg) => ({
                  ...pckg,
                  isDrivenThrough: false
                })),
              })),
            },
            errorAction.NOP
          );
          fetchActivity(data.id);
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t("shipment.update.error.general"), {
            variant: "error",
          });
        }
      }

      setIsDriveThroughLoading(false);
    },
    [
      waypoints,
      data,
      setIsDriveThroughLoading,
      deleteIncomingOrderWaypointDriveThrough,
      client.shipment.deleteShipmentWaypointDriveThrough,
      updateShipmentsDriveThrough,
      fetchActivity,
    ]
  );

  return (
    <>
      {isSetDrivenThroughDialogOpen && (
        <DriveThroughDialog
          key={`waypoint-${selectedSetWaypoint?.id}-set-drive-through-dialog`}
          title={
            selectedSetWaypoint?.placeAlias || selectedSetWaypoint?.placeName
          }
          waypoint={selectedSetWaypoint}
          incomingOrder={data}
          shipment={data}
          onClose={handleCloseSetDrivenThroughDialog}
          onSubmit={handleChangeDriveThrough}
          initialValues={{
            packagesIds: selectedSetWaypoint.cargo.reduce(
              (acc, cargoItem) => [
                ...acc,
                ...cargoItem.packages.map(
                  (pckg) => pckg.packageId || pckg.cargoItemPackageId
                ),
              ],
              []
            ),
          }}
          submitButtonLabel={getDrivenThroughText(
            t,
            selectedSetWaypoint?.action
          )}
        />
      )}

      {isUpdateDrivenThroughDialogOpen && (
        <DriveThroughDialog
          key={`waypoint-${selectedUpdateWaypoint?.id}-update-drive-through-dialog`}
          title={
            selectedUpdateWaypoint?.placeAlias ||
            selectedUpdateWaypoint?.placeName
          }
          waypoint={selectedUpdateWaypoint}
          incomingOrder={data}
          shipment={data}
          onClose={handleCloseUpdateDrivenThroughDialog}
          onSubmit={handleUpdateWaypointDriveThrough}
          showDateInput
          initialValues={{
            packagesIds: selectedUpdateWaypoint.cargo.reduce(
              (acc, cargoItem) => [
                ...acc,
                ...cargoItem.packages
                  .map((pckg) =>
                    pckg.isDrivenThrough
                      ? pckg.packageId || pckg.cargoItemPackageId
                      : undefined
                  )
                  .filter(Boolean),
              ],
              []
            ),
          }}
          submitButtonLabel={getDrivenThroughText(
            t,
            selectedUpdateWaypoint?.action
          )}
        />
      )}
      {isSetNotDeliveredDialogOpen && (
        <NotDeliveredDialog
          open={isSetNotDeliveredDialogOpen}
          handleClose={() => handleCloseSetNotDeliveredDialog(false)}
          handleSubmit={handleNotDeliveredSubmit}
        />
      )}

      {isUpdateNotDeliveredDialogOpen && (
        <NotDeliveredDialog
          open={isUpdateNotDeliveredDialogOpen}
          handleClose={() => handleCloseUpdateNotDeliveredDialog(false)}
          handleSubmit={handleUpdateNotDeliveredSubmit}
        />
      )}

      {waypoints.map((waypoint) => {
        return (
          <>
            <Waypoint
              key={`waypoint-${waypoint.id}`}
              entityType={entityType}
              waypoint={waypoint}
              data={data}
              handle={handleChangeDriveThrough}
              deleteWaypointDriveThrough={handleDeleteWaypointDriveThrough}
              isDriveThroughLoading={isDriveThroughLoading}
              handleOpenSetDrivenThroughDialog={
                handleOpenSetDrivenThroughDialog
              }
              handleOpenUpdateDrivenThroughDialog={
                handleOpenUpdateDrivenThroughDialog
              }
              handleOpenSetNotDeliveredThroughDialog={
                handleOpenSetNotDeliveredDialog
              }
              handleOpenUpdateNotDeliveredThroughDialog={
                handleOpenUpdateNotDeliveredDialog
              }
            />
            <Divider className={classes.divider} />
          </>
        );
      })}
    </>
  );
}

WaypointsSection.propTypes = {};

export default WaypointsSection;
