import React from "react";
import { useTranslation } from "react-i18next";

import { VehicleActivityType } from "@cargoticcom/model";
import { Typography } from "@material-ui/core";

import {
  ActivityTimeline,
  ActivityType
} from "../../../cargotic-webapp-component";

import { localizeMonth } from "../../../cargotic-webapp-utility";

const VehicleActivityTimeline = ({ activity, ...props }) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <ActivityTimeline
      activity={activity.map((item) => {
        const { type } = item;

        if (type === VehicleActivityType.VEHICLE_COMMENTARY) {
          const { commentary } = item;

          return {
            type: ActivityType.COMMENTARY,
            commentary
          };
        }

        if (type === VehicleActivityType.VEHICLE_CREATE) {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:vehicle.activity.create")}
              </Typography>
            ),
            createdAt
          };
        }

        if (
          type === VehicleActivityType.VEHICLE_EXPENSE_CREATE
          || type === VehicleActivityType.VEHICLE_EXPENSE_DELETE
          || type === VehicleActivityType.VEHICLE_EXPENSE_UPDATE
        ) {
          const {
            user,
            year,
            month,
            createdAt
          } = item;

          const key = type === VehicleActivityType.VEHICLE_EXPENSE_CREATE
            ? "webapp:vehicle.activity.createExpenses"
            : type === VehicleActivityType.VEHICLE_EXPENSE_DELETE
              ? "webapp:vehicle.activity.deleteExpenses"
              : "webapp:vehicle.activity.updateExpenses";

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {
                  t(
                    key,
                    { month: localizeMonth(month - 1, language), year }
                  )
                }
              </Typography>
            ),
            createdAt
          };
        }

        if (
          type === VehicleActivityType.VEHICLE_EXPIRATION_CREATE
          || type === VehicleActivityType.VEHICLE_EXPIRATION_DELETE
          || type === VehicleActivityType.VEHICLE_EXPIRATION_UPDATE
        ) {
          const { createdAt, user, expirationType } = item;

          const localizedExpirationType = t(
            `webapp:vehicle.activity.expiration.${expirationType}`
          );

          const key = type === VehicleActivityType.VEHICLE_EXPIRATION_CREATE
            ? "webapp:vehicle.activity.expiration-create"
            : type === VehicleActivityType.VEHICLE_EXPIRATION_DELETE
              ? "webapp:vehicle.activity.expiration-delete"
              : "webapp:vehicle.activity.expiration-update";

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:vehicle.activity.expiration-create")}
                <b>
                  {localizedExpirationType}
                </b>
              </Typography>
            ),
            createdAt
          };
        }

        if (type === VehicleActivityType.VEHICLE_UPDATE) {
          const { user, createdAt } = item;

          return {
            type: ActivityType.UPDATE,
            user,
            title: (
              <Typography>
                {t("webapp:vehicle.activity.update")}
              </Typography>
            ),
            createdAt
          };
        }

        throw new Error(`Unknown vehicle activity type '${type}'!`);
      })}
      {...props}
    />
  );
};

export default VehicleActivityTimeline;
